import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsInitialized } from './modules/config';

import {
  BecomeCreator,
  Collection,
  CollectionEdit,
  CollectionForm,
  ConfirmEmail,
  Connect,
  CreateCollection,
  CreateFanCollection,
  CreateFundingCollection,
  FundingCollection,
  FundingCollectionEdit,
  FundingCollectionForm,
  FundingCollectionProductionForm,
  Home,
  Loading,
  Main,
  NotFound,
  Profile,
  ProfileEdit,
  Requests,
  Success,
  Token,
} from './pages';
import { Private } from './components';
import { AppRoute } from './routes';
import { Role } from './modules/auth';
import { CollectionType } from './modules/collection';
import { useEffect } from 'react';
import { listenWeb3Events, provider } from './ethers';
import { useAppDispatch } from './hooks';
import { ProfileTab } from './enums';
import { FundingCollectionPreProductionForm } from './pages/funding-collection-pre-production-form/FundingCollectionPreProductionForm';
import {
  FundingCollectionPreToProductionForm
} from "./pages/funding-collection-pre-to-production-form/FundingCollectionPreToProductionForm";

export default function App() {
  const isInitialized = useSelector(selectIsInitialized);
  const dispatch = useAppDispatch();

  (window as any).dataLayer.push({
    event: 'pageview',
  });

  useEffect(() => {
    if (isInitialized && provider) {
      listenWeb3Events(dispatch, provider);
    }
  }, [isInitialized]);

  return isInitialized ? (
    <Routes>
      <Route path={AppRoute.Main} element={<Main />}>
        <Route path={AppRoute.Connect} element={<Connect />} />
        <Route path={AppRoute.Main} element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol`}
          element={<Collection />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol`}
          element={<FundingCollection />}
        />
        <Route
          path={AppRoute.CreateCollection}
          element={<Private Component={CreateCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.CreateCollection}/${CollectionType.Fan}`}
          element={<Private Component={CreateFanCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.CreateCollection}/${CollectionType.Funding}`}
          element={<Private Component={CreateFundingCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol/form`}
          element={<Private Component={CollectionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/form`}
          element={<Private Component={FundingCollectionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/form/pre-production`}
          element={<Private Component={FundingCollectionPreProductionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/form/production`}
          element={<Private Component={FundingCollectionProductionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/form/pre-to-production`}
          element={<Private Component={FundingCollectionPreToProductionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol/edit`}
          element={<Private Component={CollectionEdit} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/edit`}
          element={<Private Component={FundingCollectionEdit} role={Role.Creator} />}
        />
        <Route path={`${AppRoute.Success}/:symbol`} element={<Private Component={Success} />} />
        <Route
          path={`${AppRoute.Success}/:symbol/:optionId`}
          element={<Private Component={Success} />}
        />

        <Route path={`${AppRoute.Token}/:type/:id`} element={<Token />} />

        <Route path={AppRoute.Home} element={<Home />} />
        <Route path={AppRoute.UpdateProfile} element={<Private Component={ProfileEdit} />} />

        <Route path={`${AppRoute.User}/:id`} element={<Profile />} />

        <Route
          path={AppRoute.Requests}
          element={<Private Component={Requests} role={Role.Admin} />}
        />
      </Route>
      <Route
        path={AppRoute.BecomeCreator}
        element={<Private Component={BecomeCreator} restrictedRole={Role.Creator} />}
      />
      <Route
        path={AppRoute.ConfirmEmail}
        element={<Private Component={ConfirmEmail} role={Role.User} />}
      />

      <Route path={AppRoute.NotFound} element={<NotFound />} />
      <Route path={AppRoute.Wildcard} element={<NotFound />} />
    </Routes>
  ) : (
    <Loading />
  );
}
