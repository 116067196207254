import { Loader } from '@mantine/core';

type Props = {
  className?: string;
  color?: 'lime' | 'white';
};

export function AppLoader({ className, color = 'lime' }: Props) {
  return <Loader color={color} className={className} size='xl' />;
}
