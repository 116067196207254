import { Collection } from '../../../../../modules/collection';
import styles from './CollectionStatistics.module.scss';

type Props = {
  collection: Collection;
};

export function CollectionStatistics({ collection }: Props) {
  const { audience, bonuses } = collection;
  const editions = audience.reduce<number>((acc, curr) => acc + curr.tokenCount, 0);

  const stats = [
    { name: 'Editions', value: editions },
    { name: 'Owned By', value: audience.length },
    { name: 'Bonuses', value: bonuses.length },
  ];

  return (
    <div className={styles.collectionStatistics}>
      {stats.map(({ name, value }) => (
        <div key={name} className={styles.item}>
          <div className={styles.name}>{name}</div>
          <div className={styles.value}>{value || '-'}</div>
        </div>
      ))}
    </div>
  );
}
