import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './PdfModal';

export function getPdfModalContext(innerProps: { pdfId: string }): OpenContextModal<{
  pdfId: string;
}> {
  return {
    styles: {
      inner: {
        padding: '0 !important',
        height: '100vh',
        width: '100vw',
      },
      header: { display: 'none' },
      body: { borderRadius: 12, padding: 0 },
      content: { borderRadius: 12, backgroundColor: 'transparent' },
    },
    innerProps,
  };
}
