import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { useAppModals } from '../../modals';
import { selectUser } from '../../modules/auth';
import { useMediaQuery } from '@mantine/hooks';
import { selectAvailability } from '../../modules/config';
import { Link } from '../../routes/link';
import { hasAccess } from '../../utils';
import { links } from '../../routes';

import styles from './Links.module.scss';
import classNames from 'classnames';

type Props = {
  className?: string;
};

export function Links({ className }: Props) {
  const modals = useAppModals();
  const user = useSelector(selectUser);
  const largeScreen = useMediaQuery('(min-width: 900px)');
  const availability = useSelector(selectAvailability);

  function linkAuthFilter({ roles, excludeRoles }: Link) {
    const hasRoleAccess = !roles || (user && hasAccess(user, roles));
    const hasExcluded = excludeRoles && user?.roles.some((role) => excludeRoles?.includes(role));

    return hasRoleAccess && !hasExcluded;
  }

  return (
    <div className={classNames(styles.links, className)}>
      {links.filter(linkAuthFilter).map(({ label, route }) => (
        <NavLink
          key={label}
          to={route}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
}
