import { StageName, useFindFundingCollectionFormQuery } from '../../modules/collection';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../loading/Loading';
import { AppRoute } from '../../routes';

export function FundingCollectionForm() {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const navigate = useNavigate();
  const { data: collectionForm, isError } = useFindFundingCollectionFormQuery({ symbol });

  useEffect(() => {
    if (isError || (collectionForm && collectionForm.isPublished)) {
      navigate(AppRoute.NotFound);
    }

    if (collectionForm?.activeStage?.name === StageName.PRE_PRODUCTION) {
      navigate(`${AppRoute.Collection}/funding/${symbol.toLowerCase()}/form/pre-production`);
    }
    if (collectionForm?.activeStage?.name === StageName.PRODUCTION) {
      const hasPreStage = collectionForm.stages.some(
        (stage) => stage.name === StageName.PRE_PRODUCTION,
      );
      const redirectUrl = hasPreStage
        ? `${AppRoute.Collection}/funding/${symbol.toLowerCase()}/form/pre-to-production`
        : `${AppRoute.Collection}/funding/${symbol.toLowerCase()}/form/production`;

      navigate(redirectUrl);
    }
  }, [isError, collectionForm]);

  return <Loading />;
}
