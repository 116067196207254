import styles from './CollectionTypeCard.module.scss';
import { AppButton } from '../../../components';

type Props = {
  title: string;
  image: string;
  description: string;
  onClick: () => void;
  buttonText: string;
};

export function CollectionTypeCard({ title, image, description, onClick, buttonText }: Props) {
  return (
    <div className={styles.collectionTypeCard}>
      <div className={styles.title}>{title}</div>
      <div className={styles.imageContainer}>
        <img src={image} alt='' />
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>{description}</div>
        <AppButton variant='white' onClick={onClick}>
          {buttonText}
        </AppButton>
      </div>
    </div>
  );
}
