import {
  usePitchdeckForm,
  useUpdateFundingCollectionPitchdeckMutation,
} from '../../modules/collection';
import { Input } from '@mantine/core';
import { AppFileUploader, AppFormContainer } from '../index';
import {MB10, MB50} from '../../constants';
import { S3Folder, FileType } from '../../enums';
import { FileDto } from '../../modules/file';
import { useEffect } from 'react';
import styles from './PitchdeckForm.module.scss';

type Props = {
  id: string;
  symbol: string;
  onSubmit: () => void;
  script?: FileDto | null;
  pitchdeck?: FileDto | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function PitchdeckForm({ id, symbol, onSubmit, script, pitchdeck, onChange, step }: Props) {
  const form = usePitchdeckForm();
  const [updatePitchdeck, { isLoading }] = useUpdateFundingCollectionPitchdeckMutation();

  const handleSubmit = form.onSubmit(async (values) => {
    await updatePitchdeck({ ...values, symbol }).unwrap();
    onSubmit();
  });

  useEffect(() => {
    const hasPitchdeckChanges = pitchdeck?.id !== form.values.pitchdeckId;
    const hasScriptChanges = script?.id !== form.values.scriptId;

    onChange?.(hasPitchdeckChanges || hasScriptChanges);
  }, [form.values]);

  useEffect(() => {
    form.setValues({
      pitchdeckId: pitchdeck?.id || '',
      scriptId: script?.id || '',
    });
  }, [pitchdeck, script]);

  return (
    <div className={styles.pitchdeckForm}>
      <AppFormContainer
        title='Script & Pitchdeck'
        description='For a funding collection, you must upload a script and a pitchdeck.'
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        step={step}
      >
        <Input.Wrapper
          className={styles.withMargin}
          label='Upload Script'
          {...form.getInputProps('scriptId')}
        >
          <AppFileUploader
            maxSize={MB50}
            label='PDF (up to 50 Mb)'
            onChange={(files) => {
              form.setFieldValue('scriptId', files.length ? files[0].id : '');
            }}
            accept='application/pdf'
            folder={S3Folder.Collection}
            type={FileType.File}
            isPublic
            id={symbol}
            initialValue={script ? [script] : undefined}
          />
        </Input.Wrapper>

        <Input.Wrapper
          className={styles.withMargin}
          label='Upload Pitchdeck'
          {...form.getInputProps('pitchdeckId')}
        >
          <div className={styles.fieldDescription}>Presentation of your film idea.</div>

          <AppFileUploader
            maxSize={MB50}
            label='PDF (up to 50 Mb)'
            onChange={(files) => {
              form.setFieldValue('pitchdeckId', files.length ? files[0].id : '');
            }}
            accept='application/pdf'
            folder={S3Folder.Collection}
            type={FileType.File}
            isPublic
            id={symbol}
            initialValue={pitchdeck ? [pitchdeck] : undefined}
          />
        </Input.Wrapper>
      </AppFormContainer>
    </div>
  );
}
