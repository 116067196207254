import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { showError } from '../../notifications';
import { isString } from '../../utils';

export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const invalidTokenRegex = /Invalid .* token/;
    const message: string | string[] | undefined = action.payload?.data?.message;

    if (message) {
      // showError(isString(message) ? message : message.join(', '));
    }
  }

  return next(action);
};
