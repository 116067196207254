import { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { connect, onGoogleSignUp, TokensDto } from '../../modules/auth';
import image from '../../images/connect-logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { IconCoinbase, IconGoogle, IconMetamask, IconWalletConnect } from '../../icons';
import { AppBadge, AppButton, AppIconButton } from '../../components';
import { useGoogleLogin } from '@react-oauth/google';
import {
  useSignInWithGoogleMutation,
  useSignUpWithGoogleMutation,
} from '../../modules/auth/authApi';
import styles from './Connect.module.scss';
import { useAppModals } from '../../modals';
import { provider } from '../../ethers';

export function Connect() {
  const dispatch = useAppDispatch();
  const modals = useAppModals();
  const [signUp, { isLoading: isGoogleSigningUp }] = useSignUpWithGoogleMutation();
  const [signIn, { isLoading: isGoogleSigningIn }] = useSignInWithGoogleMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const [metamaskError, setMetamaskError] = useState('');
  const [createAccountError, setCreateAccountError] = useState('');
  const [signInWithGoogleError, setSignInWithGoogleError] = useState('');
  const signUpWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      signUp({ token: response.access_token })
        .unwrap()
        .then(handleGoogleAuth)
        .catch((err) => {
          setCreateAccountError(err.data?.message);
        });
    },
    onError: (error) => console.log(error),
  });
  const signInWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      signIn({ token: response.access_token })
        .unwrap()
        .then(handleGoogleAuth)
        .catch((err) => {
          setSignInWithGoogleError(err.data?.message);
        });
    },
    onError: (error) => console.log(error),
  });

  const handleGoogleAuth = async (tokens: TokensDto) => {
    await dispatch(onGoogleSignUp(tokens)).unwrap();
    redirect();
  };

  function redirect() {
    const route = (location.state as any)?.redirectRoute || AppRoute.Home;
    navigate(route, { replace: true });
  }

  function connectMetamask() {
    setMetamaskError('');

    if (!provider) {
      setMetamaskError('Metamask extension not found');
      return;
    }

    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });

    dispatch(connect())
      .unwrap()
      .then(redirect)
      .catch(setErrorMessage)
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  function setErrorMessage(e: Error & { code?: string }) {
    let { message = 'Something went wrong' } = e;
    if (e.code === 'ACTION_REJECTED') message = 'User rejected signing';
    setMetamaskError(message);
  }

  return (
    <div className={styles.connect}>
      <img src={image} alt='Logo image' />

      <div className={styles.methods}>
        <div className={styles.emailMethod}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Sign In with Google</div>
          </div>
          <div className={styles.description}>Sign in with your Google account or create one</div>

          <div className={styles.buttons}>
            <AppIconButton
              icon={IconGoogle}
              variant='outline'
              isLoading={isGoogleSigningIn}
              onClick={() => signInWithGoogle()}
              className={styles.wallet}
            >
              Sign In
            </AppIconButton>
            {signInWithGoogleError && <div className={styles.error}>{signInWithGoogleError}</div>}

            <div className={styles.separator}>
              <div className={styles.line} />
              <div className={styles.text}>or</div>
              <div className={styles.line} />
            </div>

            <AppButton
              isLoading={isGoogleSigningUp}
              onClick={() => signUpWithGoogle()}
              variant='outline'
            >
              Create Account
            </AppButton>
            {createAccountError && <div className={styles.error}>{createAccountError}</div>}
          </div>
        </div>

        <div className={styles.wallets}>
          <div className={styles.title}>Connect wallet</div>
          <div className={styles.description}>
            For now we only support Metamask wallet. We’ll add more wallet vendors in future.
          </div>
          <div className={styles.buttons}>
            <div onClick={connectMetamask} className={styles.wallet}>
              <IconMetamask className={styles.icon} />
              <div className={styles.name}>Metamask</div>
            </div>
            {metamaskError && <div className={styles.error}>{metamaskError}</div>}
            <div className={styles.wallet}>
              <IconCoinbase className={styles.icon} />
              <div className={styles.name}>Coinbase Wallet</div>
              <AppBadge type='soon' />
            </div>
            <div className={styles.wallet}>
              <IconWalletConnect className={styles.icon} />
              <div className={styles.name}>Wallet Connect</div>
              <AppBadge type='soon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
