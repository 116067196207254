import { CollectionHero } from './collection-hero/CollectionHero';
import { CollectionMinter } from './collection-minter/CollectionMinter';
import { CollectionTabs } from './collection-tabs/CollectionTabs';
import { Collection } from '../../../modules/collection';
import { AppIconButton } from '../../../components';
import { IconEdit } from '../../../icons';
import styles from './CollectionView.module.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../routes';

type Props = {
  collection: Collection;
};

export function CollectionView({ collection }: Props) {
  const user = useSelector(selectUser);
  const isUserCollection = collection.user.id === user?.id;
  const navigate = useNavigate();

  function editCollection() {
    navigate(`${AppRoute.Collection}/fan/${collection.symbol.toLowerCase()}/edit`);
  }

  return (
    <div className={styles.collection}>
      <CollectionHero collection={collection} />

      <div className={styles.content}>
        <div className={styles.collectionDetails}>
          {isUserCollection && (
            <AppIconButton
              radius='round'
              onClick={editCollection}
              className={styles.buttonEdit}
              icon={IconEdit}
            >
              Edit collection
            </AppIconButton>
          )}

          <CollectionTabs collection={collection} />
        </div>

        <CollectionMinter className={styles.minter} collection={collection} />
      </div>
    </div>
  );
}
