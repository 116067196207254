import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './ConfirmationModal';

export function getConfirmationModalContext(
  onSuccess: () => void,
  onReject: () => void,
  message: string,
  title: string,
): OpenContextModal<{
  onSuccess: () => void;
  onReject: () => void;
  message: string;
}> {
  return {
    title,
    innerProps: {
      onSuccess,
      onReject,
      message,
    },
  };
}
