import { AppMoviePreview, AppNoItems, AppSkeleton } from '../../../../components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../modules/auth';
import { useFindUserShelfQuery } from '../../../../modules/collection';
import styles from './UserShelf.module.scss';

type Props = {
  userId: string;
};

export function UserShelf({ userId }: Props) {
  const user = useSelector(selectUser);
  const isUserToken = user && userId === user.id;
  const { data: movies = [], isLoading } = useFindUserShelfQuery({ userId });
  const skeletons = Array.from({ length: 4 }, (_, index) => index);

  return (
    <div className={styles.userShelf}>
      <div className={styles.items}>
        {isLoading ? (
          skeletons.map((place) => <AppSkeleton className={styles.item} key={place} />)
        ) : movies.length ? (
          movies.map((movie) => (
            <AppMoviePreview className={styles.item} key={movie.collectionId} movie={movie} />
          ))
        ) : (
          <AppNoItems
            className={styles.noItems}
            type='film'
            title='No films here yet'
            description={
              isUserToken
                ? 'Claim a permanent seat in the audience and show your support to the author!'
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
}
