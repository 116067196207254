import { Player } from '../app-player/Player';
import { AppLoader } from '../app-loader/AppLoader';
import { IconPlay3 } from '../../icons';
import { useLazyFindStreamsQuery } from '../../modules/collection';
import isEmpty from 'is-empty-typed';
import classNames from 'classnames';
import { Stream, VideoDto } from '../../types';
import styles from './AppPlayerWithPreview.module.scss';
import { useToggle } from '@mantine/hooks';
import { useEffect } from 'react';

type Props = {
  className?: string;
  video: VideoDto;
  information?: {
    title: string;
    author: string;
    brief: string;
  };
  thumbnail?: string;
};

export function AppPlayerWithPreview({ information, className, video, thumbnail }: Props) {
  const [isPlaying, toggleIsPlaying] = useToggle();
  const { isBeingConverted, streams } = video;

  useEffect(() => {
    toggleIsPlaying(false);
  }, [video]);

  return (
    <div className={classNames(styles.appPlayerWithPreview, className)}>
      {!isBeingConverted ? (
        isPlaying ? (
          <Player className={styles.player} src={streams[0].url} information={information} />
        ) : (
          <>
            <img src={thumbnail || video.thumbnail} alt='preview' />
            <IconPlay3 onClick={() => toggleIsPlaying()} className={styles.buttonPlay} />
          </>
        )
      ) : (
        <div className={styles.convertingLabel}>The video is being converted</div>
      )}
    </div>
  );
}
