import { useEffect, useState } from 'react';
import { ColorScheme, MantineThemeOverride, CSSObject } from '@mantine/core';
import { DefaultMantineColor } from '@mantine/styles/lib/theme/types/MantineColor';
import { MantineTheme } from '@mantine/styles/lib/theme/types';
import { store } from '../store';

const DEFAULT_COLOR: DefaultMantineColor = 'lime';
const DEFAULT_COLOR_SCHEME: ColorScheme = 'dark';

export function useThemeConfig() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(DEFAULT_COLOR_SCHEME);

  const [primaryColor, setPrimaryColor] = useState<DefaultMantineColor>(DEFAULT_COLOR);

  const toggleColorScheme = (scheme?: ColorScheme) => {
    setPrimaryColorVars(primaryColor);
    scheme && setColorScheme(scheme);
    setPrimaryColor(store.getState().config.color);
  };

  useEffect(() => {
    setPrimaryColorVars(primaryColor);
  }, [primaryColor]);

  const globalStyles: (theme: MantineTheme) => CSSObject | CSSObject[] = (theme) => ({
    body: {
      backgroundColor: '#0C0C0E',
    },
  });

  const theme: MantineThemeOverride = {
    primaryColor,
    colorScheme,
    fontFamily: 'sans-serif',
    // fontFamilyMonospace: 'Inter',
    // headings: {
    //   fontWeight: 900,
    //   fontFamily: 'Inter',
    // },
    colors: {
      lime: [
        '#fcfff5',
        '#f2fdce',
        '#edfcbb',
        '#e8fba7',
        '#e2fa93',
        '#DDFB6E',
        '#ddfa80',
        '#d8f96c',
        '#D3F85A',
        '#3f451e',
      ],
      dark: [
        '#6D6D6D',
        '#60656c',
        '#565b61',
        '#4d5156',
        '#43474c',
        '#3a3c41',
        '#292B2E',
        '#26282B',
        '#1D1E20',
        '#131416',
      ],
      orange: ['#E27625'],
    },
    shadows: {
      xs: `rgba(0 0 0 / 10%) 0px 0px 2px`,
      xl: `rgba(0 0 0 / 10%) 0px 0px 8px`,
    },
    components: {
      Modal: {
        styles: () => ({
          content: {
            maxWidth: 'calc(100vw - (5vw * 2))',
            overflow: 'initial',
          },
        }),
      },
      Stepper: {
        styles: () => ({
          stepLabel: {
            color: 'white',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 14,
          },
          stepDescription: {
            color: 'white',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
          },
        }),
      },
      Carousel: {
        styles: () => ({
          viewport: {
            overflow: 'visible',
            overflowX: 'clip',
          },
          control: {
            width: 40,
            height: 64,
            borderRadius: 6,
            background: 'white',
            opacity: 1,
            '&:nth-of-type(1)': {
              transform: 'translateX(-50%)',
            },
            '&:nth-of-type(2)': {
              transform: 'translateX(50%)',
            },
          },
        }),
      },
      RichTextEditor: {
        styles: () => ({
          root: {
            border: 'none',
          },
          toolbar: {
            background: 'transparent',
            padding: 0,
            border: 'none',
            marginBottom: 16,
          },
          content: {
            background: '#1D1E20',
            color: '#CACBCE',
            minHeight: 400,
            border: '1px solid #303136',
            borderRadius: 12,
          },
          control: {
            background: 'transparent',
            color: '#CACBCE',
            borderColor: '#3B3C3F',
            '&[aria-pressed="true"]': {
              background: '#3B3C3F',
              fontWeight: 900,
              color: 'white',
            },
            '&:hover': {
              background: '#3B3C3F !important',
            },
          },
        }),
      },
      Switch: {
        styles: () => ({
          root: {
            width: 36,
          },
        }),
      },
      Popover: {
        styles: () => ({
          root: {
            background: '#1D1E20',
            border: '1px solid #292A2E',
            borderRadius: 28,
          },
          dropdown: {
            background: '#1D1E20',
            border: '1px solid #292A2E',
            borderRadius: 28,
            padding: 32,
          },
        }),
      },
      Button: {
        styles: () => ({
          root: {
            color: 'black',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '100%',
          },
        }),
      },
      Accordion: {
        styles: () => ({
          item: {
            background: '#1D1E20',
          },
          chevron: {
            height: 40,
            width: 40,
          },
        }),
      },
      InputWrapper: {
        styles: () => ({
          root: {},
          label: {
            fontWeight: 700,
            marginBottom: 6,
            fontSize: '16px',
            lineHeight: '24px',
            color: 'white',
          },
          error: {
            color: '#e03131',
          },
        }),
      },
      Input: {
        styles: () => ({
          icon: {
            color: 'white',
            width: '3.25rem',
          },
          input: {
            backgroundColor: '#1D1E20',
            minHeight: 48,
            border: '1px solid #303136',
            borderRadius: 12,
            padding: '8px 16px',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '150%',
            color: '#FFFFFF',
            '&::placeholder': {
              fontWeight: 600,
              fontSize: 14,
              lineHeight: '150%',
              color: '#A7A7A7',
              opacity: 0.5,
            },
            '&[data-with-icon]': {
              paddingLeft: '3.25rem',
            },
          },
          rightSection: {
            width: 'unset',
          },
          error: {
            borderColor: '#F15050',
          },
        }),
      },
      Menu: {
        styles: (theme) => ({
          dropdown: {
            background: '#1D1E20',
            border: '1px solid #292A2E',
            borderRadius: 8,
            // padding: '32px !important',
          },
          item: {
            borderRadius: 8,
            color: 'white',
          },
          arrow: {
            borderColor: '#292A2E',
          },
        }),
      },
      Notification: {
        styles: (theme) => ({
          root: {
            padding: '18px 16px 16px',
            backgroundColor: '#1D1E20',
            border: '1px solid #292A2E',
            borderRadius: 12,
            '&::before': {
              display: 'block !important',
              width: '100%',
              height: 2,
              top: 0,
            },
          },
          title: {
            fontFamily: 'Inter !important',
            fontWeight: 600,
            fontSize: 16,
            color: 'white',
          },
          description: {
            fontFamily: 'Inter !important',
            fontWeight: 400,
            fontSize: 16,
            color: '#CACBCE',
          },
          icon: {
            backgroundColor: 'transparent !important',
            height: 40,
            width: 40,
          },
          closeButton: {
            color: theme.white,
            '&:hover': { backgroundColor: 'transparent' },
            svg: {
              height: 22,
              width: 22,
            },
          },
        }),
      },
    },
  };

  return {
    globalStyles,
    theme,
    colorScheme,
    toggleColorScheme,
  };
}

function setPrimaryColorVars(primaryColor: DefaultMantineColor) {
  const colorTuples = Array.from(Array(10).keys());

  colorTuples.forEach((tuple) => {
    document.documentElement.style.setProperty(
      `--mantine-color-primary-${tuple}`,
      `var(--mantine-color-${primaryColor}-${tuple})`,
    );
  });
}
