import { MantineTheme } from '@mantine/styles/lib/theme/types';
import { ModalSettings } from '@mantine/modals/lib/context';

export function getBaseSettings(theme: MantineTheme): ModalSettings {
  return {
    zIndex: 201,
    centered: true,
    trapFocus: true,
    radius: 40,
    styles: {
      content: {
        background: '#1D1E20',
        border: '1px solid #292A2E',
        borderRadius: 28,
      },
      header: {
        background: 'inherit',
        padding: '32px 32px 20px',
        borderRadius: '28px 28px 0px 0px',
      },
      body: {
        padding: 0,
      },
      close: { borderRadius: theme.radius.xl, border: '1px solid #292A2E' },
      title: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.white,
        lineHeight: '30px',
      },
    },
  };
}
