import { BonusDto, useUploadBonusesMutation } from '../../modules/collection';
import { AppFormContainer } from '../index';
import { BonusList } from './bonus-list/BonusList';
import { useListState } from '@mantine/hooks';
import { v4 as uuid } from 'uuid';
import { BonusForm } from './bonus-form/BonusForm';
import { FormEvent, useEffect, useState } from 'react';
import styles from './BonusesForm.module.scss';

type Props = {
  id: string;
  toggleBonusCreation: () => void;
  isCreation: boolean;
  onSubmit?: () => void;
  symbol: string;
  bonuses: BonusDto[];
  step?: number;
  onChange: (bonuses: BonusDto[]) => void;
};

export function BonusesForm({
  id,
  toggleBonusCreation,
  isCreation,
  onSubmit,
  onChange,
  symbol,
  bonuses,
  step,
}: Props) {
  const [bonusToUpdate, setBonusToUpdate] = useState<BonusDto | undefined>();
  const [createBonuses, { isLoading }] = useUploadBonusesMutation();
  const [updatedBonuses, bonusHandlers] = useListState<BonusDto>([]);

  useEffect(() => {
    bonusHandlers.setState(bonuses);
  }, [bonuses]);

  useEffect(() => {
    onChange(updatedBonuses);
  }, [updatedBonuses]);

  async function handleSubmit(event?: FormEvent) {
    event && event.preventDefault();
    await createBonuses({ bonuses: updatedBonuses, symbol }).unwrap();
    onSubmit?.();
  }

  function handleBonusFormSubmit(bonus: Omit<BonusDto, 'id'> & { id?: string }) {
    if (bonus.id) {
      bonusHandlers.applyWhere(
        (item) => item.id === bonus.id,
        (item) => ({ ...item, ...bonus }),
      );
    } else {
      bonusHandlers.append({ id: uuid(), ...bonus });
    }
    toggleBonusCreation();
  }

  function deleteBonus(id: string) {
    bonusHandlers.filter((bonus) => bonus.id !== id);
  }

  function createBonus() {
    setBonusToUpdate(undefined);
    toggleBonusCreation();
  }

  function editBonus(id: string) {
    const bonus = updatedBonuses.find((bonus) => bonus.id === id);
    if (!bonus) return;
    setBonusToUpdate(bonus);
    toggleBonusCreation();
  }

  function toggleBonus(id: string) {
    bonusHandlers.applyWhere(
      (bonus) => bonus.id === id,
      (bonus) => ({ ...bonus, isDisabled: !bonus.isDisabled }),
    );
  }

  return (
    <div className={styles.bonusesForm}>
      {isCreation ? (
        <BonusForm bonus={bonusToUpdate} onSubmit={handleBonusFormSubmit} />
      ) : (
        <AppFormContainer
          disabled={isLoading}
          step={step}
          description='Bonuses can be anything, such as playlists, access to social networks, unique props! Remember that the owner of the token gets access to all bonuses at once!'
          title='Create Bonuses'
          onSubmit={handleSubmit}
          formId={id}
          className={styles.bonusesForm}
        >
          <BonusList
            onBonusEdit={editBonus}
            onBonusDelete={deleteBonus}
            onBonusToggle={toggleBonus}
            onBonusCreate={createBonus}
            bonuses={updatedBonuses}
          />
        </AppFormContainer>
      )}
    </div>
  );
}
