import { Tabs } from '@mantine/core';
import { useEffect } from 'react';
import classNames from 'classnames';
import { AppTab } from '../../types';
import styles from './AppTabs.module.scss';

type Props = {
  className?: string;
  onTabChange?: (tab: string | null) => void;
  activeTab: string | null;
  tabs: AppTab[];
};

export function AppTabs({ tabs, className, activeTab, onTabChange }: Props) {
  const tabListItems = tabs.map(({ tabLabel, tabKey, isDisabled }) => (
    <Tabs.Tab className={styles.tab} key={tabKey} value={tabKey} disabled={isDisabled}>
      {tabLabel}
    </Tabs.Tab>
  ));

  const tabPanels = tabs.map(({ tabKey, tabContent: Content }) => (
    <Tabs.Panel className={styles.panel} key={tabKey} value={tabKey}>
      {Content instanceof Function ? <Content /> : Content}
    </Tabs.Panel>
  ));

  return (
    <Tabs
      className={classNames(styles.appTabs, className)}
      radius='xs'
      keepMounted={false}
      value={activeTab}
      onTabChange={onTabChange}
    >
      <Tabs.List className={styles.header}>{tabListItems}</Tabs.List>
      {tabPanels}
    </Tabs>
  );
}
