import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { FilmShelfPreviewDto } from '../../modules/collection';
import styles from './AppMoviePreview.module.scss';
import { IconPlay, IconPlay2, IconPlay3 } from '../../icons';

type Props = {
  movie: FilmShelfPreviewDto;
  className?: string;
};

export function AppMoviePreview({ movie, className }: Props) {
  const { collectionId, collectionSymbol, poster } = movie;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`${AppRoute.Collection}/fan/${collectionSymbol.toLowerCase()}`);
  }

  return (
    <div onClick={handleClick} className={classNames(styles.moviePreview, className)}>
      <img src={poster} alt='Movie image' />
      <IconPlay3 className={styles.icon} />
    </div>
  );
}
