import { ContentType, IconComponent } from '../../../../../modules/collection';
import styles from './BonusTypeLabel.module.scss';
import { IconDownload2, IconText2, IconLink2, IconFile2, IconPlay2 } from '../../../../../icons';

const labels: Record<ContentType, { Icon: IconComponent; label: string } | undefined> = {
  [ContentType.Download]: { Icon: IconDownload2, label: 'Movie' },
  [ContentType.Watch]: { Icon: IconPlay2, label: 'Movie' },
  [ContentType.File]: { Icon: IconFile2, label: 'File' },
  [ContentType.Link]: { Icon: IconLink2, label: 'Link' },
  [ContentType.Text]: { Icon: IconText2, label: 'Text' },
  [ContentType.None]: undefined,
};

type Props = {
  type: ContentType;
};

export function BonusTypeLabel({ type }: Props) {
  const label = labels[type];

  return label ? (
    <div className={styles.bonusTypeLabel}>
      <label.Icon />
      <div>{label.label}</div>
    </div>
  ) : (
    <div>-</div>
  );
}
