import styles from './AppBadge.module.scss';
import { CollectionType } from '../../modules/collection';
import classNames from 'classnames';

type Props = {
  type: CollectionType | 'draft' | 'soon' | 'minting' | 'waitlist' | 'live now' | 'ended';
  className?: string;
};

export function AppBadge({ type, className }: Props) {
  const isGray = type === 'ended';
  const isUppercase =
    type === CollectionType.Fan || type === CollectionType.Funding || type === 'draft';

  return (
    <div
      className={classNames(styles.appBadge, styles[type], className, {
        [styles.gray]: isGray,
        [styles.uppercase]: isUppercase,
      })}
    >
      {type === CollectionType.Fan && 'fan collection'}
      {type === CollectionType.Funding && 'funding collection'}
      {type === 'draft' && 'draft'}
      {type === 'soon' && 'soon'}
      {type === 'minting' && 'minting'}
      {type === 'waitlist' && 'waitlist'}
      {type === 'live now' && 'live now'}
      {type === 'ended' && 'ended'}
    </div>
  );
}
