import classnames from 'classnames';
import styles from './AppCollectionStatistics.module.scss';

type Props = {
  items: { name: string; value: string | number }[];
  className?: string;
  withSeparator?: boolean;
};

export function AppCollectionStatistics({ items, className, withSeparator }: Props) {
  return (
    <div
      className={classnames(styles.collectionStatistics, className, {
        [styles.withSeparator]: withSeparator,
      })}
    >
      {items.map(({ name, value }) => (
        <div key={name} className={styles.item}>
          <div className={styles.name}>{name}</div>
          <div className={styles.value}>{value || '-'}</div>
        </div>
      ))}
    </div>
  );
}
