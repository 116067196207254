import { api } from '../../api';
import { ImdbMoviePreviewDto } from './filmTypes';

export const filmApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchTitle: builder.query<ImdbMoviePreviewDto, { url: string }>({
      query: ({ url }) => ({ url: `film/imdb/search-title`, params: { url } }),
    }),
  }),
});

export const { useLazySearchTitleQuery } = filmApi;
