import { AppIconButton } from '../../../components';
import { IconDownload, IconFile } from '../../../icons';
import styles from './FileBonusContent.module.scss';
import { BonusContentDto } from '../../../modules/collection';
import prettyBytes from 'pretty-bytes';

type Props = { content: BonusContentDto };

export function FileBonusContent({ content }: Props) {
  const { name, content: url, size } = content;

  function download() {
    window.open(url, '_blank');
  }

  return (
    <div className={styles.bonusContent}>
      <div className={styles.iconFileContainer}>
        <IconFile className={styles.iconFile} />
      </div>
      <div className={styles.file}>
        {name && <div className={styles.name}>{name}</div>}
        {size && <div className={styles.size}>{prettyBytes(size)}</div>}
      </div>
      <AppIconButton
        radius='round'
        className={styles.button}
        onClick={download}
        variant='outline'
        icon={IconDownload}
      >
        Download File
      </AppIconButton>
    </div>
  );
}
