import { Collection } from '../../../../modules/collection';
import { AppCollectors, AppCreatorLabel, AppIconButton, AppSpoiler } from '../../../../components';
import { CollectionBonuses } from './collection-bonuses/CollectionBonuses';
import { ContractDetails } from '../../../../components';
import { IconExternalLink } from '@tabler/icons-react';
import { NavItems, NavSection } from '../../../../components/navigation';
import styles from './CollectionDetails.module.scss';
import { SEPOLIA, SUPPORTED_NETWORK } from '../../../../ethers/ethersConstants';
import { viewOnPolygon } from '../../../../utils';

type Props = {
  collection: Collection;
};

enum Section {
  About = 'about',
  Bonuses = 'bonuses',
  Audience = 'audience',
  Contract = 'contract',
}

export function CollectionDetails({ collection }: Props) {
  const { name, description, user, bonuses, hasAccess, address } = collection;

  return (
    <div className={styles.collectionDetails}>
      <NavItems
        className={styles.navigation}
        items={[
          { id: Section.About, label: 'About the Collection' },
          { id: Section.Bonuses, label: 'Bonuses' },
          { id: Section.Audience, label: 'Audience' },
          { id: Section.Contract, label: 'Contract Details' },
        ]}
      />

      <div className={styles.sections}>
        <NavSection id={Section.About} name='About the Collection'>
          <AppSpoiler className={styles.collectionBrief}>
            <div className={styles.spoilerContent}>
              <AppCreatorLabel {...user} className={styles.creator} />
              <div className={styles.description}>{description}</div>
            </div>
          </AppSpoiler>
        </NavSection>

        <NavSection
          id={Section.Bonuses}
          name='Bonuses'
          description='View all rewards and perks for collecting this release.'
        >
          <CollectionBonuses collection={collection} />
        </NavSection>

        <NavSection
          id={Section.Audience}
          name='Audience'
          description='Claim a permanent seat in the audience by purchasing a limited edition NFT of the movie.
            Seating is determined by time of support.'
        >
          <AppCollectors collectors={collection.audience} isLoading={false} />
        </NavSection>

        <NavSection
          id={Section.Contract}
          name='Contract Details'
          buttons={[
            <AppIconButton
              radius='round'
              icon={IconExternalLink}
              onClick={() => viewOnPolygon(address)}
              variant='outline'
              reversedOrder
              withBorder
            >
              View on Polygon
            </AppIconButton>,
          ]}
        >
          <ContractDetails collection={collection} />
        </NavSection>
      </div>
    </div>
  );
}
