import { FundingCollectionDto, StageName } from '../../../../modules/collection';
import {
  AppCollectors,
  AppCreatorLabel,
  AppIconButton,
  AppRteViewer,
  ContractDetails,
} from '../../../../components';
import { NavItems, NavSection } from '../../../../components/navigation';
import { IconFile2 } from '../../../../icons';
import { IconExternalLink } from '@tabler/icons-react';
import { useAppModals } from '../../../../modals';
import styles from './FundingCollectionRoadmap.module.scss';
import { CollectionOptions } from '../funding-collection-options/CollectionOptions';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../modules/auth';
import { viewOnPolygon } from '../../../../utils';
import { AppRoadmap } from '../../../../components/app-roadmap/AppRoadmap';

type Props = {
  collection: FundingCollectionDto;
};

enum Section {
  PostProduction = 'Post-Production',
  PreProduction = 'Pre-Production',
  Production = 'Production',
}

const sectionMap = {
  [StageName.PRODUCTION]: Section.Production,
  [StageName.PRE_PRODUCTION]: Section.PreProduction,
  [StageName.POST_PRODUCTION]: Section.PostProduction,
};

export function FundingCollectionRoadmap({ collection }: Props) {
  const modals = useAppModals();
  const { name, address, story, user, pitchdeckId, scriptId, symbol, isWaitlist } = collection;
  const currentUser = useSelector(selectUser);

  function openPdf(id: string) {
    modals.openPdfModal(id);
  }

  return (
    <div className={styles.roadmap}>
      {collection.activeStage && (
        <>
          <NavItems
            className={styles.navs}
            items={[
              {
                id: sectionMap[collection.activeStage.name],
                label: sectionMap[collection.activeStage.name],
              },
            ]}
          />

          <div className={styles.sections}>
            <NavSection
              id={sectionMap[collection.activeStage.name]}
              name='Roadmap'
              description='This list outlines the tasks the filmmaker will undertake during this production phase, utilizing the gathered funds. You have the unique opportunity to both oversee and actively engage in the entire process.'
            >
              <div className={styles.storyContainer}>
                <AppRoadmap className={styles.appRoadmap} tasks={collection.activeStage!.tasks} />
              </div>
            </NavSection>
          </div>
        </>
      )}
    </div>
  );
}
