import { Icon, IconBackground, Task } from './collectionTypes';
import { FunctionComponent, SVGProps } from 'react';
import {
  IconBag,
  IconCalendar,
  IconCamera,
  IconCategory,
  IconCommunity,
  IconDiscord,
  IconDiscount,
  IconDownload,
  IconEdit,
  IconEnvelop,
  IconFacebook,
  IconHome,
  IconImage,
  IconMicrophone,
  IconPlay,
  IconShow,
  IconSpeaker,
  IconTicket,
  IconVideo,
  IconYoutube,
} from '../../icons';
import { v4 as uuid } from 'uuid';
import { FileType } from '../../enums';

export const MIN_DESCRIPTION = 100;
export const MAX_DESCRIPTION = 2500;
export const MAX_BONUSES = 15;
export const MAX_OPTIONS = 1;
export const MAX_CONTENT_FILES = 3;
export const MAX_CONTENT_LINKS = 4;
export const MIN_CONTENT_TEXT = 10;
export const MAX_CONTENT_TEXT = 500;
export const MAX_NAME = 100;
export const MIN_NAME = 3;

export const GRADIENTS: Record<IconBackground, string> = {
  [IconBackground.Lime]: 'linear-gradient(314.99deg, #A0D52F 1.99%, #DDFB6E 98%), #CCCCCC',
  [IconBackground.LimeMint]: 'linear-gradient(314.99deg, #FBFF25 10.29%, #6EFBAF 83.61%), #2C2C2C',
  [IconBackground.Pink]: 'linear-gradient(314.99deg, #D52F9C 1.99%, #FB6E6E 98%), #2C2C2C',
  [IconBackground.Red]:
    'linear-gradient(132.33deg, #FF6A16 24.37%, #FC0088 68.6%, #FFF616 102.54%), linear-gradient(135deg, #FC5858 0%, #FAEC69 100%), linear-gradient(135deg, #7784FC 0%, #87FAD9 100%), #2C2C2C',
  [IconBackground.Orange]:
    'linear-gradient(135deg, #FC5858 0%, #FAEC69 100%), linear-gradient(135deg, #7784FC 0%, #87FAD9 100%), #2C2C2C',
  [IconBackground.Blue]: 'linear-gradient(314.99deg, #3C2FD5 1.99%, #6E95FB 98%), #2C2C2C',
  [IconBackground.LightBlue]:
    'linear-gradient(314.99deg, #2FD5D5 12.38%, #5749F9 80.13%), linear-gradient(132.33deg, #FF6A16 24.37%, #FC0088 68.6%, #FFF616 102.54%), linear-gradient(135deg, #FC5858 0%, #FAEC69 100%), linear-gradient(135deg, #7784FC 0%, #87FAD9 100%), #2C2C2C',
  [IconBackground.Mint]: 'linear-gradient(314.99deg, #2FD5CB 1.99%, #6EFBAF 98%), #2C2C2C',
};

export type IconComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

export const ICONS: Record<Icon, IconComponent> = {
  [Icon.Bag]: IconBag,
  [Icon.Image]: IconImage,
  [Icon.Calendar]: IconCalendar,
  [Icon.Camera]: IconCamera,
  [Icon.Category]: IconCategory,
  [Icon.Community]: IconCommunity,
  [Icon.Discord]: IconDiscord,
  [Icon.Discount]: IconDiscount,
  [Icon.Download]: IconDownload,
  [Icon.Edit]: IconEdit,
  [Icon.Envelop]: IconEnvelop,
  [Icon.Facebook]: IconFacebook,
  [Icon.Home]: IconHome,
  [Icon.Microphone]: IconMicrophone,
  [Icon.Play]: IconPlay,
  [Icon.Show]: IconShow,
  [Icon.Speaker]: IconSpeaker,
  [Icon.Ticket]: IconTicket,
  [Icon.Video]: IconVideo,
  [Icon.Youtube]: IconYoutube,
};

export const initialFormFile = {
  id: '',
  name: '',
  size: 0,
  mimeType: '',
  type: FileType.File,
  isPublic: false,
  description: '',
};

export const baseRoadmap: Task[] = [
  {
    date: new Date().toISOString(),
    id: uuid(),
    title: 'Concept Development',
    subtasks: [
      {
        id: uuid(),
        title: 'Logline',
        description: "A succinct statement capturing the essence of a film's plot or concept.",
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Synopsis',
        description:
          "A longer overview of the movie's main storyline, touching upon key events and characters.",
        isDone: false,
      },
    ],
  },
  {
    date: new Date().toISOString(),
    id: uuid(),
    title: 'Scriptwriting',
    subtasks: [
      {
        id: uuid(),
        title: 'First draft of the script',
        description:
          'The initial written version of the movie, detailing scenes, dialogues, and character interactions.',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Script revision',
        description:
          'Refining and making changes to the script based on feedback or further reflection.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Planning and Budgeting',
    subtasks: [
      {
        id: uuid(),
        title: 'Budget',
        description:
          'An itemized estimate of all expenses expected during the production of the movie.',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Shooting schedule',
        description: 'A timetable that outlines when and where specific scenes will be filmed.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Team Selection and Casting',
    subtasks: [
      {
        id: uuid(),
        title: 'Casting list',
        description: 'Identifying and listing actors for each role in the movie.',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Contracts',
        description:
          'Formal agreements detailing terms of engagement for actors, crew, and other collaborators.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Location Scouting and Selection',
    subtasks: [
      {
        id: uuid(),
        title: 'Location list',
        description: 'Identifying suitable places for filming specific scenes.',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Filming permits',
        description: 'Permissions needed to legally film at chosen locations.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Shooting Preparation',
    subtasks: [
      {
        id: uuid(),
        title: 'Script breakdown',
        description:
          'Segmenting the script to outline resources (actors, props, equipment) needed for each scene.',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Props and costumes list',
        description: 'Enumerating items and clothing required for the characters during filming.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Technical Preparation',
    subtasks: [
      {
        id: uuid(),
        title: 'Equipment selection',
        description:
          'Deciding on the technical gear, like cameras and microphones, necessary for filming',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'Test shoots',
        description:
          'Preliminary filming to check equipment functionality and determine the desired visual look.',
        isDone: false,
      },
    ],
  },
  {
    id: uuid(),
    date: new Date().toISOString(),
    title: 'Rights Documentation Preparation',
    subtasks: [
      {
        id: uuid(),
        title: 'Music and other material permissions',
        description: 'Securing legal rights to use copyrighted music or materials in the film',
        isDone: false,
      },
      {
        id: uuid(),
        title: 'NFT',
        description: 'Creating terms, description and image for production stage NFT',
        isDone: false,
      },
    ],
  },
];
