import styles from './TopCreators.module.scss';
import classNames from 'classnames';
import { AppButton } from '../app-button/AppButton';
import { AppIconButton } from '../app-icon-button/AppIconButton';
import { IconPlus } from '@tabler/icons-react';
import { CreatorPreviewDto } from '../../modules/user';
import { AppCreatorLabel } from '../app-creator-label/AppCreatorLabel';
import { AppSkeleton } from '../app-skeleton/AppSkeleton';

type Props = {
  className?: string;
  creators: CreatorPreviewDto[];
  isLoading: boolean;
};

export function TopCreators({ className, creators, isLoading }: Props) {
  const placements = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className={classNames(styles.topCreators, className)}>
      {[1, 2].map((place) => (
        <header key={place} className={styles.column}>
          <div />
          <div>Filmmaker</div>
          <div>Followers</div>
          <div />
        </header>
      ))}
      {placements.map((place, index) => {
        const creator = creators[index];

        return (
          <div key={place} className={classNames(styles.column, styles.creator)}>
            {isLoading ? (
              <AppSkeleton />
            ) : (
              <>
                <div className={styles.place}>#{place}</div>
                <div>
                  {creator && (
                    <AppCreatorLabel
                      id={creator.id}
                      username={creator.username}
                      address={creator.address}
                      avatar={creator.avatar}
                    />
                  )}
                </div>
                <div className={styles.followers}>0</div>
                <div>
                  {creator && (
                    <AppIconButton className={styles.button} icon={IconPlus} variant='white'>
                      Follow
                    </AppIconButton>
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
