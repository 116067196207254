import { Tabs } from '@mantine/core';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { IconCheck } from './icons';
import styles from './AppTabsVertical.module.scss';
import { AppTab } from '../../types';

type Props = {
  tabs: AppTab[];
  onTabChange?: (tab: string | null) => void;
  activeTab: string | null;
  variant?: 'stepper' | 'tab';
};

export function AppTabsVertical({ tabs, onTabChange, activeTab, variant = 'tab' }: Props) {
  const isStepper = variant === 'stepper';

  const tabListItems = tabs.map(({ tabLabel, tabKey, isDisabled, isDone = false }, index) => (
    <Tabs.Tab
      className={classNames(styles.tab, { [styles.done]: isDone })}
      key={tabKey}
      value={tabKey}
      disabled={isDisabled || isDone}
    >
      <div className={styles.tabLabel}>
        {isStepper && <div className={styles.step}>{isDone ? <IconCheck /> : index + 1}</div>}
        {tabLabel}
      </div>
    </Tabs.Tab>
  ));

  const tabPanels = tabs.map(({ tabKey, tabContent: Content }) => (
    <Tabs.Panel key={tabKey} value={tabKey}>
      {Content instanceof Function ? <Content /> : Content}
    </Tabs.Panel>
  ));

  return (
    <Tabs
      onTabChange={onTabChange}
      className={classNames(styles.appTabsVertical, { [styles.stepper]: isStepper })}
      radius='xs'
      orientation='vertical'
      value={activeTab}
    >
      <Tabs.List className={styles.header}>{tabListItems}</Tabs.List>
      {tabPanels}
    </Tabs>
  );
}
