import { z } from 'zod';
import { collectionApi } from './collectionApi';
import { store } from '../../store';
import {
  MAX_CONTENT_FILES,
  MAX_CONTENT_LINKS,
  MAX_CONTENT_TEXT,
  MAX_DESCRIPTION,
  MAX_NAME,
  MIN_CONTENT_TEXT,
  MIN_DESCRIPTION,
  MIN_NAME,
} from './collectionConstants';
import { ContentType, Icon, IconBackground, StageName } from './collectionTypes';
import { FileType } from '../../enums';

type AnyObj = Record<PropertyKey, unknown>;

type ZodObj<T extends AnyObj> = {
  [key in keyof T]: z.ZodType<T[key]>;
};

const zObject = <T extends AnyObj>(arg: ZodObj<T>) => z.object(arg);

export const fileIdString = z.string().length(24, { message: 'You must upload file' });
const symbolString = z
  .string()
  .regex(/^[a-zA-Z0-9]*$/, 'Only letters and digits allowed')
  .min(3)
  .max(10);

const file = zObject({
  id: z.string().length(24),
  name: z.string(),
  size: z.number(),
  mimeType: z.string(),
  type: z.nativeEnum(FileType),
  isPublic: z.boolean(),
  description: z.string().nullable(),
}).refine((val) => val.id.length === 24, {
  message: 'You must upload file',
});

export const fanCollectionSchema = z.object({
  name: z.string().min(3).max(70),
  symbol: symbolString,
  maxNft: z.number().min(1).max(1_000_000),
  price: z.number().int().gt(1).max(10_000),
  priceWeb3: z.number().gt(1),
  nftImageId: fileIdString,
});

export const fundingCollectionSchema = z.object({
  name: z.string().min(3).max(70),
  symbol: symbolString,
  stageName: z.nativeEnum(StageName),
});

export const collectionAsyncSchema = z.object({
  symbol: z.string().refine(
    async (symbol) => {
      const { dispatch } = store;

      return await dispatch(collectionApi.endpoints.checkSymbol.initiate({ symbol })).unwrap();
    },
    {
      message: 'Symbol already exists',
    },
  ),
});

export const importImdbSchema = z.object({
  url: z
    .string()
    .url()
    .regex(/tt[0-9]{7}/, {
      message:
        "Invalid url. IMDB url usually includes ID that starts with 'tt' and has at least 7 digits",
    }),
});

export const addDescriptionSchema = z.object({
  description: z.string().min(MIN_DESCRIPTION).max(MAX_DESCRIPTION),
});

export const storySchema = z.object({
  story: z.string().min(100).max(10000),
});

export const goalSchema = z.object({
  goal: z.number().min(1),
  percentage: z.number().min(1).max(100),
});

export const dropConfigSchema = z.object({
  waitlistSize: z.number().min(10),
  waitlistDiscount: z.number().min(3).max(50),
});

export const uploadTrailersSchema = z.object({
  trailerIds: z.array(fileIdString).min(1, { message: 'You must upload at least one file' }).max(2),
});

const baseBonusSchema = z.object({
  icon: z.nativeEnum(Icon),
  background: z.nativeEnum(IconBackground),
  name: z.string().min(MIN_NAME).max(MAX_NAME),
  description: z.string().min(MIN_CONTENT_TEXT).max(MAX_CONTENT_TEXT),
});

const baseOptionSchema = z.object({
  maxNft: z.number().min(1).max(50_000),
  price: z.number().int().gt(1).max(10_000),
  priceWeb3: z.number().gt(1),
  nftImage: file,
  name: z.string().min(MIN_NAME).max(MAX_NAME),
  description: z.string().min(MIN_CONTENT_TEXT).max(MAX_CONTENT_TEXT),
  isDisabled: z.boolean(),
});

const fileContentSchema = {
  type: z.enum([ContentType.File]),
  files: z.array(file).min(1).max(MAX_CONTENT_FILES),
  content: z.array(z.string()),
};

const linkContentSchema = {
  type: z.enum([ContentType.Link]),
  files: z.array(file),
  content: z.array(z.string().url()).min(1).max(MAX_CONTENT_LINKS),
};

const textContentSchema = {
  type: z.enum([ContentType.Text]),
  files: z.array(file),
  content: z.array(z.string().min(MIN_CONTENT_TEXT).max(MAX_CONTENT_TEXT)).min(1).max(1),
};

const noneContentSchema = {
  type: z.enum([ContentType.None, ContentType.Download, ContentType.Watch]),
  files: z.array(file),
  content: z.array(z.string()).min(1).max(1),
};

export const bonusSchema = z.union([
  baseBonusSchema.extend(fileContentSchema),
  baseBonusSchema.extend(linkContentSchema),
  baseBonusSchema.extend(textContentSchema),
  baseBonusSchema.extend(noneContentSchema),
]);

export const optionSchema = z.union([
  baseOptionSchema.extend(fileContentSchema),
  baseOptionSchema.extend(linkContentSchema),
  baseOptionSchema.extend(textContentSchema),
  baseOptionSchema.extend(noneContentSchema),
]);

export const uploadVideosSchema = uploadTrailersSchema.extend({
  movieId: fileIdString,
});

export const videosAndPhotosSchema = z.object({
  pitchVideoId: fileIdString,
  coverImageId: fileIdString.or(z.literal('')),
  videoIds: z.array(fileIdString).max(5),
  imageIds: z.array(fileIdString).max(10),
});

export const pitchdeckSchema = z.object({
  scriptId: fileIdString,
  pitchdeckId: fileIdString,
});
