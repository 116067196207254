import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppRoute } from '../../routes';
import { useFindTokenQuery } from '../../modules/token';
import { Loading } from '../loading/Loading';
import {
  AppBadge,
  AppButton,
  AppCreatorLabel,
  AppIconButton,
  AppTabs,
  ContractDetails,
} from '../../components';
import { CollectionType } from '../../modules/collection';
import { OptimizedImage } from '../../components/optimized-image/OptimizedImage';
import { IconExternalLink } from '@tabler/icons-react';
import { viewOnPolygon } from '../../utils';
import styles from './Token.module.scss';

export function Token() {
  const { id = '', type = CollectionType.Fan } = useParams<{ type: CollectionType; id: string }>();
  const [currentTab, setCurrentTab] = useState<string | null>(() => 'Information');

  const navigate = useNavigate();
  const { data: token, isError, refetch } = useFindTokenQuery({ id, type });

  useEffect(() => {
    if (isError) {
      navigate(AppRoute.NotFound);
    }
  }, [isError]);

  function toCollection() {
    navigate(`${AppRoute.Collection}/${type}/${token!.collectionSymbol.toLowerCase()}`);
  }

  return token ? (
    <div className={styles.token}>
      <div className={styles.backContainer}>
        <AppButton onClick={() => navigate(-1)} variant='outline'>{`← Back`}</AppButton>
      </div>

      <div className={styles.upperContainer}>
        <OptimizedImage
          className={styles.image}
          src={token.image}
          alt='Token Image'
          dimensions={[450, 450]}
        />

        <div className={styles.tokenInfo}>
          <div>
            <AppBadge type={token.collectionType} />
            <div className={styles.nameContainer}>
              <div>{token.optionName || token.collectionName}</div>
              {token.externalId ? `#${token.externalId}` : <AppBadge type='minting' />}
            </div>

            <div className={styles.collection}>
              <div className={styles.label}>Collection:</div>
              <div onClick={toCollection} className={styles.collectionName}>
                {token.collectionName}
              </div>
            </div>

            <div className={styles.users}>
              <div>
                <div className={styles.label}>Creator:</div>
                <AppCreatorLabel
                  id={token.creator.id}
                  username={token.creator.username}
                  address={token.creator.address}
                  avatar={token.creator.avatar}
                />
              </div>

              <div>
                <div className={styles.label}>Current owner:</div>
                <AppCreatorLabel
                  id={token.owner.id}
                  username={token.owner.username}
                  address={token.owner.address}
                  avatar={token.owner.avatar}
                />
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.priceContainer}>
              <div className={styles.label}>Last price:</div>
              <div className={styles.price}>${token.price}</div>
            </div>
          </div>
        </div>
      </div>

      <AppTabs
        className={styles.tabs}
        tabs={[
          {
            tabKey: 'Information',
            tabLabel: 'Information',
            tabContent: (
              <div className={styles.information}>
                <div className={styles.header}>
                  <div>Contracts Details</div>
                  <AppIconButton
                    radius='round'
                    icon={IconExternalLink}
                    onClick={() => viewOnPolygon(token.contractAddress)}
                    variant='outline'
                    reversedOrder
                    withBorder
                  >
                    View on Polygon
                  </AppIconButton>
                </div>

                <ContractDetails
                  collection={{ createdAt: token.createdAt, address: token.contractAddress }}
                />
              </div>
            ),
          },
        ]}
        activeTab={currentTab}
        onTabChange={setCurrentTab}
      />
    </div>
  ) : (
    <Loading />
  );
}
