import { MouseEvent } from 'react';
import { getPercentage } from '../../../utils';

export function getBarPercentage(e: MouseEvent<HTMLDivElement>, vertical: boolean = false) {
  const target = e.target as HTMLDivElement;
  const bounds = target.getBoundingClientRect();
  const x = e.clientX - bounds.left;
  const y = e.clientY - bounds.top;
  const percentage = getPercentage(
    vertical ? y : x,
    vertical ? target.clientHeight : target.clientWidth,
  );

  return Math.floor(vertical ? 100 - percentage : percentage);
}
