import { FilmGallery } from '../film-gallery/FilmGallery';
import { FilmOverview } from '../film-overview/FilmOverview';
import { Film } from '../../../../modules/collection';
import styles from './FilmDetails.module.scss';
import { AppIconButton } from '../../../../components';
import { IconExternalLink } from '@tabler/icons-react';
import { MediaGallery } from '../../../../components/media-gallery/MediaGallery';
import { VideoDto } from '../../../../types';
import { NavSection, NavItems } from '../../../../components/navigation';

type Props = {
  film: Film;
  trailers: VideoDto[];
};

enum Section {
  Overview = 'overview',
  Videos = 'videos',
  Photos = 'photos',
}

export function FilmDetails({ film, trailers }: Props) {
  const { images, imdbId } = film;

  function viewOnImdb() {
    const link = `https://www.imdb.com/title/${imdbId}`;
    window.open(link, '_blank');
  }

  return (
    <div className={styles.filmDetails}>
      <NavItems
        className={styles.navigation}
        items={[
          { id: Section.Overview, label: 'Overview' },
          { id: Section.Videos, label: 'Trailers & Videos' },
          { id: Section.Photos, label: 'Photos & Screenshots' },
        ]}
      />

      <div className={styles.sections}>
        <NavSection
          id={Section.Overview}
          name='Overview'
          buttons={[
            <AppIconButton
              radius='round'
              icon={IconExternalLink}
              onClick={viewOnImdb}
              reversedOrder
              variant='outline'
              withBorder
            >
              View on IMDB
            </AppIconButton>,
          ]}
        >
          <FilmOverview film={film} />
        </NavSection>

        <NavSection id={Section.Videos} name='Trailers & Videos'>
          <MediaGallery className={styles.mediaGallery} videos={trailers} images={[]} />
        </NavSection>

        <NavSection id={Section.Photos} name='Photos & Screenshots'>
          <FilmGallery images={images} />
        </NavSection>
      </div>
    </div>
  );
}
