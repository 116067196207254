import styles from './TextBonusContent.module.scss';
import { AppClipboardButton } from '../../../components';
import { BonusContentDto } from '../../../modules/collection';

type Props = { content: BonusContentDto };

export function TextBonusContent({ content }: Props) {
  return (
    <div className={styles.bonusContent}>
      <div className={styles.value}>{content.content}</div>
      <AppClipboardButton className={styles.button} value={content.content} />
    </div>
  );
}
