import styles from './BonusPreview.module.scss';
import { BonusDto } from '../../../../modules/collection';
import { IconPreview } from '../icon-preview/IconPreview';
import { BonusTypeLabel } from './bonus-type-label/BonusTypeLabel';
import { Switch } from '@mantine/core';
import { IconEdit, IconTrash } from '../../../../icons';

type Props = {
  bonus: BonusDto;
  onBonusEdit: (id: string) => void;
  onBonusDelete: (id: string) => void;
  onBonusToggle: (id: string) => void;
};

export function BonusPreview({ bonus, onBonusDelete, onBonusEdit, onBonusToggle }: Props) {
  const { icon, background, type, name, isDefault, isDisabled, isOptional, id } = bonus;

  return (
    <div className={styles.bonusPreview}>
      <div>
        <IconPreview icon={icon} background={background} />
        <div className={styles.nameBlock}>
          <div className={styles.name}>{name}</div>
          {isDisabled && <div className={styles.status}>Disabled bonus</div>}
        </div>
      </div>
      <div>
        <BonusTypeLabel type={type} />
      </div>
      <div>
        {isDefault ? (
          <>{isOptional && <Switch checked={!isDisabled} onChange={() => onBonusToggle(id)} />}</>
        ) : (
          <div className={styles.buttons}>
            <IconTrash className={styles.icon} onClick={() => onBonusDelete(id)} />
            <IconEdit className={styles.icon} onClick={() => onBonusEdit(id)} />
          </div>
        )}
      </div>
    </div>
  );
}
