import styles from "./Requests.module.scss";
import { Autocomplete, Table } from "@mantine/core";
import classnames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  FilmmakerRequestDto,
  useFindRequestsQuery,
  useProcessFilmmakerRequestMutation,
} from "../../modules/user";
import { getTruncatedHash } from "../../utils";
import { useDebouncedValue } from "@mantine/hooks";
import { RequestCard } from "../../components";

export function Requests() {
  const [value, setValue] = useState("");
  const [selectedRequest, setSelectedRequest] =
    useState<FilmmakerRequestDto | null>(null);
  const [processFilmmakerRequest] = useProcessFilmmakerRequestMutation();
  const [debouncedValue] = useDebouncedValue(value, 2000);
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useFindRequestsQuery(debouncedValue);

  function processRequest(userId: string, isApproved: boolean) {
    processFilmmakerRequest({ userId, isApproved })
      .unwrap()
      .then(() => {
        refetch();
        setSelectedRequest(null);
      });
  }

  useEffect(() => {
    refetch();
  }, [debouncedValue, refetch]);

  const rows = data.map((dto) => (
    <tr
      onClick={() => setSelectedRequest(dto)}
      className={classnames(styles.row, {
        [styles.selected]: selectedRequest?.id === dto.id,
      })}
      key={dto.id}
    >
      <td>{getTruncatedHash(dto.user.address)}</td>
      <td>{dto.user.username}</td>
      <td>{dto.user.fullName}</td>
      <td>{dto.user.email}</td>
      <td>{dayjs(dto.createdAt).format("MM/DD/YYYY")}</td>
    </tr>
  ));

  return (
    <div className={styles.requests}>
      <div className={styles.title}>Filmmaker Requests</div>
      <div className={styles.subtitle}>Users</div>

      <div className={styles.separator} />

      <div className={styles.content}>
        <div className={styles.leftBlock}>
          <Autocomplete
            type="outline"
            placeholder="Nickname, full name, e-mail or wallet"
            label="Search users"
            value={value}
            onChange={setValue}
            data={[]}
            className={styles.search}
          />

          <Table className={styles.table}>
            <thead>
              <tr>
                <th>Address</th>
                <th>Nickname</th>
                <th>Full name</th>
                <th>E-mail</th>
                <th>Request Data</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
        {selectedRequest ? (
          <RequestCard
            request={selectedRequest}
            processRequestCallback={processRequest}
          />
        ) : (
          <div className={styles.skeleton}>Select a user to view details</div>
        )}
      </div>
    </div>
  );
}
