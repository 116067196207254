import { OpenContextModal } from '@mantine/modals/lib/context';
import { CollectionType, ContentType } from '../../modules/collection';

export * from './ContentModal';

export function getContentModalContext(innerProps: {
  collectionType: CollectionType;
  contentDescription: string;
  contentId: string;
  contentType: ContentType;
  name: string;
}): OpenContextModal<{
  collectionType: CollectionType;
  contentDescription: string;
  contentId: string;
  contentType: ContentType;
  name: string;
}> {
  return {
    title: innerProps.name,
    innerProps,
  };
}
