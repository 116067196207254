import {
  CollectionType,
  Stage,
  useGoalForm,
  useGoNextStageMutation,
  useStartPublicSaleMutation,
  useUpdateFundingCollectionGoalMutation,
} from '../../modules/collection';

import { useEffect, useState } from 'react';
import { AppButton, AppFormContainer } from '../index';
import { NumberInput, Progress } from '@mantine/core';
import styles from './TurnOffWaitlistForm.module.scss';
import { formatCurrencyInput, getPercentage, parseCurrencyInput } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  isWaitlist: boolean;
  activeStage: Stage | null | undefined;
};

const MIN_WAITLIST = 100;

export function TurnOffWaitlistForm({ id, symbol, onSubmit, activeStage, isWaitlist }: Props) {
  const [startPublicSale, { isLoading: isPublicSaleLoading }] = useStartPublicSaleMutation();
  const [goNextStage, { isLoading: isNextStageLoading }] = useGoNextStageMutation();
  const navigate = useNavigate();
  const waitlistCount = activeStage?.waitlistUserIds.length || 0;
  const waitlistSize = activeStage?.waitlistSize || 0;
  const canGoText = activeStage?.canGoNext || false;

  function handlePublicSale() {
    startPublicSale({ symbol })
      .unwrap()
      .then(() => {
        navigate(`${AppRoute.Collection}/${CollectionType.Funding}/${symbol}`);
      });
  }

  function handleNextStage() {
    goNextStage({ symbol })
      .unwrap()
      .then(() => {
        navigate(`${AppRoute.Collection}/funding/${symbol.toLowerCase()}/form/pre-to-production`);
      });
  }

  return (
    <div className={styles.turnOffWaitlist}>
      <AppFormContainer
        description='You can manually start public sale once you reach 100 people in the waitlist'
        title='Drop Configuration'
        onSubmit={() => {}}
        formId={id}
        className={styles.form}
        disabled={isPublicSaleLoading || isNextStageLoading}
      >
        <div className={styles.row}>
          {isWaitlist && (
            <div className={styles.valueBlock}>
              <Progress
                radius='xl'
                size='md'
                value={getPercentage(waitlistCount, waitlistSize)}
                styles={{
                  root: {
                    background: '#292A2E',
                  },
                }}
              />

              <div
                className={styles.goal}
              >{`${waitlistCount} of ${waitlistSize} people in waitlist`}</div>
            </div>
          )}

          {isWaitlist ? (
            <AppButton onClick={handlePublicSale} >
              Start public sale
            </AppButton>
          ) : (
            <AppButton onClick={handleNextStage} disabled={!canGoText}>
              Go next stage
            </AppButton>
          )}
        </div>
      </AppFormContainer>
    </div>
  );
}
