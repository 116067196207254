import { TextInput } from '@mantine/core';
import { AppIconButton } from '../../../index';
import { IconPlus } from '@tabler/icons-react';
import { IconTrash } from '../../../../icons';
import classNames from 'classnames';
import { UseFormReturnType } from '@mantine/form/lib/types';
import { OptionForm } from '../../../../modules/collection';
import styles from './OptionLinks.module.scss';

const MAX_LINKS = 4;

type Props = {
  form: UseFormReturnType<OptionForm>;
};

export function OptionLinks({ form }: Props) {
  return (
    <div className={styles.bonusLinks}>
      {form.values.content.map((link, index) => (
        <div key={index} className={classNames(styles.linkContainer, styles.withMargin)}>
          <TextInput
            className={styles.input}
            placeholder='Paste link'
            {...form.getInputProps(`content.${index}`)}
          />
          {form.values.content.length > 1 && (
            <div
              onClick={() => form.removeListItem('content', index)}
              className={styles.removeButton}
            >
              <IconTrash />
            </div>
          )}
        </div>
      ))}
      {form.values.content.length < MAX_LINKS && (
        <AppIconButton
          radius='round'
          onClick={() => form.insertListItem('content', '')}
          className={styles.button}
          variant='outline'
          icon={IconPlus}
        >
          Add another
        </AppIconButton>
      )}
    </div>
  );
}
