import { BonusDto, ContentType, Collection } from '../../../../../modules/collection';
import styles from './CollectionBonuses.module.scss';
import classNames from 'classnames';
import { BonusPreview } from './bonus-preview/BonusPreview';
import { WatchBonusPreview } from './watch-bonus-preview/WatchBonusPreview';
import { WatchBonus } from './watch-bonus/WatchBonus';
import { getTruncatedHash } from '../../../../../utils';
import { Carousel } from '@mantine/carousel';
import { OptionPreview } from '../../../../funding-collection/funding-collection-view/funding-collection-options/option-preview/OptionPreview';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../modules/auth';

type Props = {
  collection: Collection;
};

export function CollectionBonuses({ collection }: Props) {
  const {
    bonuses,
    user: { fullName, username, address },
    film: { poster, title, year, genres, countries },
    video,
  } = collection;
  const user = useSelector(selectUser);
  const watchBonus = bonuses.find(isWatchBonus);
  const watchBonusInformation = {
    title,
    author: fullName || username || getTruncatedHash(address, 4),
    brief: `${countries}, ${year} ${genres}`,
  };
  const bonusList = bonuses.filter((b) => !isWatchBonus(b));
  const hasAccess = !!(collection.hasAccess && video && user);

  function isWatchBonus(bonus: BonusDto) {
    return bonus.type === ContentType.Watch;
  }

  return (
    <div className={styles.collectionBonuses}>
      {hasAccess ? (
        <WatchBonus className={styles.video} video={video} information={watchBonusInformation} />
      ) : (
        <WatchBonusPreview className={styles.preview} poster={poster} />
      )}
      <div className={styles.bonuses}>
        {bonusList
          .filter((b) => !isWatchBonus(b))
          .map((bonus) => (
            <BonusPreview key={bonus.id} bonus={bonus} hasAccess={hasAccess} />
          ))}
      </div>

      <Carousel
        styles={{
          viewport: {
            overflowX: 'unset',
          },
        }}
        className={styles.carousel}
        dragFree
        breakpoints={[]}
        slideGap={20}
        withControls={false}
        slideSize={280}
        align='start'
      >
        {!(hasAccess && video) && (
          <Carousel.Slide className={styles.slide}>
            <WatchBonusPreview className={styles.previewMobile} compact />
          </Carousel.Slide>
        )}

        {bonusList.map((bonus) => (
          <Carousel.Slide className={styles.slide} key={bonus.id}>
            <BonusPreview key={bonus.id} bonus={bonus} hasAccess={hasAccess} />
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
}
