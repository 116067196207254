import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './SpinnerModal';

export function getSpinnerModalContext(innerProps: {
  text?: string;
  withLogo?: boolean;
}): OpenContextModal<{
  text?: string;
  withLogo?: boolean;
}> {
  return {
    size: '100vw',
    shadow: 'xl',
    withCloseButton: false,
    closeOnClickOutside: false,
    styles: {
      content: {
        background: 'transparent',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    innerProps,
  };
}
