import { Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { getTruncatedHash } from "../../utils";
import { useSelector } from "react-redux";
import { selectAccount } from "../../modules/auth";
import { ContextModalProps } from "@mantine/modals";

export function AccountModal({ context, id }: ContextModalProps) {
  const account = useSelector(selectAccount);

  return (
    <>
      {account && (
        <>
          <Stack p="xs">
            <Group position="apart">
              <Group position="left">
                <ThemeIcon>asdasd</ThemeIcon>

                <Text size="xs">{getTruncatedHash(account.address)}</Text>
              </Group>
            </Group>
          </Stack>
        </>
      )}
    </>
  );
}
