import { GRADIENTS, Icon, IconBackground, ICONS } from '../../../../modules/collection';
import styles from './IconPreview.module.scss';

type Props = {
  icon: Icon;
  background: IconBackground;
};

export function IconPreview({ icon, background }: Props) {
  const Icon = ICONS[icon];

  return (
    <div className={styles.iconPreview} style={{ background: GRADIENTS[background] }}>
      <Icon />
    </div>
  );
}
