import { useUploadVideosForm, useUploadVideosMutation } from '../../../modules/collection';
import { Input } from '@mantine/core';
import { AppFileUploader, AppFormContainer } from '../../../components';
import { GB5 } from '../../../constants';
import { S3Folder, FileType } from '../../../enums';
import styles from './UploadVideos.module.scss';

type Props = {
  id: string;
  symbol: string;
  onSubmit: () => void;
};

export function UploadVideos({ id, symbol, onSubmit }: Props) {
  const form = useUploadVideosForm();
  const [uploadVideos, { isLoading }] = useUploadVideosMutation();

  const handleSubmit = form.onSubmit(async (values) => {
    await uploadVideos({ ...values, symbol }).unwrap();
    onSubmit();
  });

  return (
    <div className={styles.uploadVideos}>
      <AppFormContainer
        title='Upload Film & Trailer'
        description='To form a fan collection, you must upload the movie and trailer'
        onSubmit={handleSubmit}
        formId={id}
        step={3}
        disabled={isLoading}
        className={styles.form}
      >
        <Input.Wrapper
          className={styles.withMargin}
          label='Upload Film'
          {...form.getInputProps('movieId')}
        >
          <AppFileUploader
            className={styles.uploader}
            maxSize={GB5}
            label='max 5GB, MP4, MOV or AVI'
            onChange={(videos) => {
              form.setFieldValue('movieId', videos.length ? videos[0].id : '');
            }}
            accept='video/mp4, video/mov, video/avi, video/vnd.avi, video/quicktime'
            folder={S3Folder.Collection}
            type={FileType.Video}
            id={symbol}
          />
        </Input.Wrapper>

        <Input.Wrapper label='Upload Trailer' {...form.getInputProps('trailerIds')}>
          <div className={styles.fieldDescription}>You can upload 2 trailers</div>

          <AppFileUploader
            className={styles.uploader}
            maxSize={GB5}
            multiple
            label='max 5GB, MP4, MOV or AVI'
            onChange={(videos) => {
              form.setFieldValue(
                'trailerIds',
                videos.map((video) => video.id),
              );
            }}
            accept='video/mp4, video/mov, video/avi, video/vnd.avi, video/quicktime'
            maxFiles={2}
            folder={S3Folder.Collection}
            type={FileType.Video}
            isPublic
          />
        </Input.Wrapper>
      </AppFormContainer>
    </div>
  );
}
