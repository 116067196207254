import {
  useUpdateFundingCollectionMediaMutation,
  useVideosAndPhotosForm,
} from '../../modules/collection';
import { Input } from '@mantine/core';
import { AppFileUploader, AppFormContainer } from '../index';
import { GB5, MB10, MB5 } from '../../constants';
import { S3Folder, FileType } from '../../enums';
import { FileDto } from '../../modules/file';
import { useEffect } from 'react';
import styles from './VideosAndPhotosForm.module.scss';
import _ from 'lodash';

type Props = {
  id: string;
  symbol: string;
  onSubmit: () => void;
  videos?: FileDto[];
  images?: FileDto[];
  pitchVideo?: FileDto | null;
  coverImage?: FileDto | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function VideosAndPhotosForm({
  id,
  symbol,
  onSubmit,
  videos = [],
  images = [],
  pitchVideo,
  coverImage,
  onChange,
  step,
}: Props) {
  const form = useVideosAndPhotosForm();
  const [updateMedia, { isLoading }] = useUpdateFundingCollectionMediaMutation();

  const handleSubmit = form.onSubmit(async (values) => {
    await updateMedia({ ...values, coverImageId: values.coverImageId || null, symbol }).unwrap();
    onSubmit();
  });

  useEffect(() => {
    const { coverImageId, imageIds, videoIds, pitchVideoId } = form.values;

    const hasCoverImageChanges = coverImageId !== (coverImage?.id || '');
    const hasPitchVideoChanges = pitchVideoId !== (pitchVideo?.id || '');
    const hasVideosChanges = !_.isEqual(
      videoIds,
      videos.map((video) => video.id),
    );
    const hasImagesChanges = !_.isEqual(
      imageIds,
      images.map((image) => image.id),
    );

    const hasChanges =
      hasImagesChanges || hasVideosChanges || hasCoverImageChanges || hasPitchVideoChanges;

    onChange?.(hasChanges);
  }, [form.values]);

  useEffect(() => {
    form.setValues({
      videoIds: videos.map((video) => video.id),
      imageIds: images.map((image) => image.id),
      pitchVideoId: pitchVideo?.id || '',
      coverImageId: coverImage?.id || '',
    });
  }, [videos, images, pitchVideo, coverImage]);

  return (
    <div className={styles.videosAndPhotosForm}>
      <AppFormContainer
        title='Videos & Photos'
        description="Upload photos and videos, to vividly convey your film's essence and engage your audience more deeply."
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        step={step}
      >
        <Input.Wrapper
          className={styles.withMargin}
          label='Pitch Video'
          {...form.getInputProps('pitchVideoId')}
        >
          <div className={styles.fieldDescription}>
            This is the primary video for your funding campaign. In it, you and potentially your
            team should discuss the upcoming film, its concept, and the production journey.
          </div>

          <AppFileUploader
            maxSize={GB5}
            label='max 5GB, MP4, MOV or AVI'
            onChange={(videos) => {
              form.setFieldValue('pitchVideoId', videos.length ? videos[0].id : '');
            }}
            accept='video/mp4, video/mov, video/avi, video/vnd.avi, video/quicktime'
            folder={S3Folder.Collection}
            id={symbol}
            type={FileType.Video}
            isPublic
            initialValue={pitchVideo ? [pitchVideo] : undefined}
          />
        </Input.Wrapper>

        <Input.Wrapper
          className={styles.withMargin}
          label='Other Videos (Optional)'
          {...form.getInputProps('videoIds')}
        >
          <div className={styles.fieldDescription}>
            Optional materials that help bring out the idea of your future film.
          </div>

          <AppFileUploader
            maxSize={GB5}
            multiple
            label='max 5GB, MP4, MOV or AVI'
            onChange={(videos) => {
              form.setFieldValue(
                'videoIds',
                videos.map((video) => video.id),
              );
            }}
            accept='video/mp4, video/mov, video/avi, video/vnd.avi, video/quicktime'
            maxFiles={5}
            folder={S3Folder.Collection}
            type={FileType.Video}
            isPublic
            id={symbol}
            initialValue={videos}
          />
        </Input.Wrapper>

        <Input.Wrapper
          className={styles.withMargin}
          label='Other images (Optional)'
          {...form.getInputProps('imageIds')}
        >
          <div className={styles.fieldDescription}>
            Optional materials that help bring out the idea of your future film.
          </div>

          <AppFileUploader
            maxSize={MB10}
            multiple
            label='16:9 aspect ratio, max 10MB, GIF, JPEG or PNG'
            onChange={(images) => {
              form.setFieldValue(
                'imageIds',
                images.map((image) => image.id),
              );
            }}
            accept='image/png, image/jpg, image/gif'
            maxFiles={10}
            folder={S3Folder.Collection}
            type={FileType.Image}
            isPublic
            id={symbol}
            initialValue={images}
          />
        </Input.Wrapper>

        <Input.Wrapper
          className={styles.withMargin}
          label='Page Cover Image (Optional)'
          {...form.getInputProps('coverImageId')}
        >
          <div className={styles.fieldDescription}>
            Background image. It will be blurred on the published page.
          </div>

          <AppFileUploader
            className={styles.uploader}
            maxSize={MB10}
            label='16:9 aspect ratio, max 10MB, GIF, JPEG or PNG'
            onChange={(images) => {
              form.setFieldValue('coverImageId', images.length ? images[0].id : '');
            }}
            accept='image/png, image/jpg, image/gif'
            folder={S3Folder.Collection}
            type={FileType.Image}
            isPublic
            id={symbol}
            initialValue={coverImage ? [coverImage] : undefined}
          />
        </Input.Wrapper>
      </AppFormContainer>
    </div>
  );
}
