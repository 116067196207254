import {
  useDropConfigForm,
  useUpdateFundingCollectionDropConfigMutation,
} from '../../modules/collection';

import { useEffect, useState } from 'react';
import { AppFormContainer } from '../index';
import { NumberInput } from '@mantine/core';
import styles from './DropConfigForm.module.scss';
import { formatCurrencyInput, parseCurrencyInput } from '../../utils';
import classNames from 'classnames';
import { DateInput } from '@mantine/dates';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  waitlistSize?: number | null;
  waitlistDiscount?: number | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function DropConfigForm({
  id,
  symbol,
  onSubmit,
  waitlistDiscount,
  waitlistSize,
  onChange,
  step,
}: Props) {
  const [updateDropConfig, { isLoading }] = useUpdateFundingCollectionDropConfigMutation();
  const form = useDropConfigForm();

  const handleSubmit = form.onSubmit(async (values) => {
    await updateDropConfig({ symbol, ...values }).unwrap();
    onSubmit?.();
  });
  //
  // useEffect(() => {
  //   const hasSizeChanged = form.values.waitlistSize !== waitlistSize;
  //   const hasDiscountChanged = form.values.waitlistDiscount !== waitlistDiscount;
  //   const hasDateChanged =
  //     form.values.waitlistSize.toString() !== publicSaleStartDate && publicSaleStartDate;
  //
  //   onChange?.();
  // }, [form.values]);
  //
  useEffect(() => {
    form.setValues({
      waitlistDiscount: waitlistDiscount || 0,
      waitlistSize: waitlistSize || 0,
    });
  }, [waitlistSize, waitlistDiscount]);

  return (
    <div className={styles.dropConfigForm}>
      <AppFormContainer
        description='Public sales will start once the specified number of individuals join the waitlist. Those on the waitlist will benefit from a discount.'
        title='Drop Configuration'
        onSubmit={handleSubmit}
        formId={id}
        className={styles.form}
        step={step}
        disabled={isLoading}
      >
        <NumberInput
          label='Waitlist limit'
          placeholder='Max people in waitlist'
          precision={0}
          hideControls
          mb={20}
          removeTrailingZeros
          {...form.getInputProps('waitlistSize')}
        />
        <NumberInput
          label='Waitlist discount, %'
          placeholder='Discount amount'
          precision={0}
          hideControls
          removeTrailingZeros
          {...form.getInputProps('waitlistDiscount')}
        />
      </AppFormContainer>
    </div>
  );
}
