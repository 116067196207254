import { ContextModalProps } from '@mantine/modals';
import { useAppDispatch } from '../../hooks';
import React from 'react';
import { CollectionType, ContentType, useFindBonusContentQuery } from '../../modules/collection';
import { AppLoader } from '../../components';
import { LinkBonusContent } from './link-bonus-content/LinkBonusContent';
import { TextBonusContent } from './text-bonus-content/TextBonusContent';
import { FileBonusContent } from './file-bonus-content/FileBonusContent';
import styles from './ContentModal.module.scss';

const contentResolver = {
  [ContentType.File]: FileBonusContent,
  [ContentType.Text]: TextBonusContent,
  [ContentType.Link]: LinkBonusContent,
  [ContentType.Download]: FileBonusContent,
  [ContentType.Watch]: () => <></>,
  [ContentType.None]: () => <></>,
};

export const ContentModal = ({
  context,
  id,
  innerProps: { collectionType, contentId, contentDescription, contentType },
}: ContextModalProps<{
  collectionType: CollectionType;
  contentDescription: string;
  contentId: string;
  contentType: ContentType;
}>) => {
  const dispatch = useAppDispatch();
  const {
    data: content,
    isLoading,
    isFetching,
  } = useFindBonusContentQuery({ id: contentId, collectionType });

  return (
    <div className={styles.contentModal}>
      <div className={styles.description}>{contentDescription}</div>
      {isLoading || isFetching || !content ? (
        <AppLoader className={styles.loader} color='white' />
      ) : (
        <>
          {content.map((content) => {
            const Component = contentResolver[contentType];

            return <Component content={content} />;
          })}
        </>
      )}
    </div>
  );
};
