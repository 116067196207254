import { Input, TextInput } from '@mantine/core';
import {
  AppButton,
  AppFormContainer,
  AppImagePreview,
  AppImageUploader,
  AppTextarea,
} from '../index';
import { MB5 } from '../../constants';
import { S3Folder } from '../../enums';
import { useEffect, useRef } from 'react';
import {
  ProfileFormDto,
  setProfile,
  UpdateProfileForm,
  useUpdateProfileForm,
  useUpdateProfileMutation,
} from '../../modules/user';
import { IconAt } from '@tabler/icons-react';
import {
  IconFacebook,
  IconImdb,
  IconInstagram,
  IconSpotify,
  IconTwitter,
  IconWebsite,
} from '../../icons';
import { validateAsync } from '../../utils';
import { getProfileAsyncSchema } from '../../modules/user/userSchema';
import styles from './ProfileForm.module.scss';
import { useAppDispatch } from '../../hooks';
import { UploaderApi } from '../app-file-uploader/types';
import { useSelector } from 'react-redux';
import { selectUser, WalletType } from '../../modules/auth';

type Props = {
  id: string;
  onSubmit: () => void;
  profile?: ProfileFormDto;
  onChange?: (profile: UpdateProfileForm) => void;
};

export function ProfileForm({ id, onSubmit, profile, onChange }: Props) {
  const user = useSelector(selectUser);
  const isMetamask = user?.type === WalletType.METAMASK;
  const avatarApi = useRef<UploaderApi>(null);
  const backgroundApi = useRef<UploaderApi>(null);
  const form = useUpdateProfileForm();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const dispatch = useAppDispatch();

  const handleSubmit = form.onSubmit(async (values) => {
    const result = await validateAsync(getProfileAsyncSchema(dispatch), values, form);

    if (result.success) {
      const profile = await updateProfile({
        ...values,
        avatarId: values.avatarId || null,
        backgroundId: values.backgroundId || null,
      }).unwrap();
      dispatch(setProfile(profile));
      onSubmit();
    }
  });

  useEffect(() => {
    onChange?.(form.values);
  }, [form.values]);

  useEffect(() => {
    if (profile) {
      const {
        username,
        email,
        fullName,
        bio,
        imdb,
        website,
        twitter,
        discord,
        instagram,
        spotify,
        facebook,
        emailForNotifications,
      } = profile;

      form.setValues({
        username: username || '',
        fullName: fullName || '',
        email: email || '',
        bio: bio || '',
        imdb: imdb || '',
        website: website || '',
        spotify: spotify || '',
        facebook: facebook || '',
        twitter: twitter || '',
        instagram: instagram || '',
        discord: discord || '',
        emailForNotifications: emailForNotifications || '',
      });
    }
  }, [profile]);

  return (
    <div className={styles.profileForm}>
      <AppFormContainer
        title='Profile Settings'
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        className={styles.form}
      >
        <AppImagePreview
          className={styles.background}
          height={280}
          width={1440}
          id={form.values.backgroundId}
        />

        <AppButton
          className={styles.backgroundButton}
          variant='white'
          onClick={() => {
            backgroundApi.current?.openFileSystem();
          }}
        >
          Change
        </AppButton>

        <div className={styles.fields}>
          <div className={styles.avatarContainer}>
            <AppImagePreview
              className={styles.avatar}
              height={186}
              width={186}
              style={{ borderRadius: '50%' }}
              id={form.values.avatarId}
            />
            <AppButton
              variant='white'
              onClick={() => {
                avatarApi.current?.openFileSystem();
              }}
            >
              Change
            </AppButton>
          </div>

          <Input.Wrapper className={styles.withMargin} {...form.getInputProps('avatarId')}>
            <AppImageUploader
              isInputDisplayed={false}
              apiRef={avatarApi}
              initialValue={profile?.avatar ? [profile.avatar] : undefined}
              maxSize={MB5}
              label='At least 300x300, max 5MB, GIF, JPEG or PNG'
              onChange={(value) => form.setFieldValue('avatarId', value[0]?.id || '')}
              onUploadStart={() => form.clearFieldError('avatarId')}
              folder={S3Folder.User}
              id={profile?.id}
              isPublic
              withPreview={false}
              fileVisibility='uploading'
            />
          </Input.Wrapper>

          <Input.Wrapper className={styles.withMargin} {...form.getInputProps('backgroundId')}>
            <AppImageUploader
              isInputDisplayed={false}
              apiRef={backgroundApi}
              initialValue={profile?.background ? [profile?.background] : undefined}
              maxSize={MB5}
              label='At least 1440x280, max 5MB, GIF, JPEG or PNG'
              onChange={(value) => form.setFieldValue('backgroundId', value[0]?.id || '')}
              onUploadStart={() => form.clearFieldError('backgroundId')}
              folder={S3Folder.User}
              id={profile?.id}
              isPublic
              withPreview={false}
              fileVisibility='uploading'
            />
          </Input.Wrapper>

          <div className={styles.row}>
            <div>
              <TextInput
                label='Profile Display Name'
                placeholder='Enter profile display name'
                {...form.getInputProps('fullName')}
              />
              <div className={styles.fieldDescription}>Your full name</div>
            </div>

            <div>
              <TextInput
                icon={<IconAt size={20} />}
                label='Username'
                placeholder='Enter your username'
                {...form.getInputProps('username')}
              />
              <div className={styles.fieldDescription}>Your username</div>
            </div>
          </div>

          <AppTextarea
            className={styles.withMargin}
            label='Description'
            placeholder='Enter your description'
            minRows={3}
            maxRows={6}
            maxLength={500}
            {...form.getInputProps('bio')}
          />

          <div className={styles.subTitle}>Social profiles (optional)</div>
          <div className={styles.fieldDescription}>
            Share your creativity via every platform. Acquire audience outside the Indieline
          </div>

          <div className={styles.socialInput}>
            <div className={styles.label}>Twitter</div>

            <TextInput
              className={styles.input}
              icon={<IconTwitter />}
              placeholder='e.g. “www.twitter.com/@user”'
              {...form.getInputProps('twitter')}
            />
          </div>

          {isMetamask && (
            <div className={styles.socialInput}>
              <div className={styles.label}>Email</div>

              <TextInput
                className={styles.input}
                icon={<IconAt />}
                placeholder='e.g. user@gmail.com'
                {...form.getInputProps('emailForNotifications')}
              />
            </div>
          )}

          <div className={styles.socialInput}>
            <div className={styles.label}>IMDB</div>

            <TextInput
              className={styles.input}
              icon={<IconImdb />}
              placeholder='e.g. “www.imdb.com/name/@user”'
              {...form.getInputProps('imdb')}
            />
          </div>

          <div className={styles.socialInput}>
            <div className={styles.label}>Website</div>

            <TextInput
              className={styles.input}
              icon={<IconWebsite />}
              placeholder='e.g. “www.username.com'
              {...form.getInputProps('website')}
            />
          </div>

          <div className={styles.socialInput}>
            <div className={styles.label}>Spotify</div>

            <TextInput
              className={styles.input}
              icon={<IconSpotify />}
              placeholder='e.g. “https://open.spotify.com/artist/userid'
              {...form.getInputProps('spotify')}
            />
          </div>

          <div className={styles.socialInput}>
            <div className={styles.label}>Facebook</div>

            <TextInput
              className={styles.input}
              icon={<IconFacebook />}
              placeholder='e.g. “www.facebook.com/user'
              {...form.getInputProps('facebook')}
            />
          </div>

          <div className={styles.socialInput}>
            <div className={styles.label}>Instagram</div>

            <TextInput
              className={styles.input}
              icon={<IconInstagram />}
              placeholder='e.g. “www.username.com'
              {...form.getInputProps('instagram')}
            />
          </div>
        </div>
      </AppFormContainer>
    </div>
  );
}
