import styles from './ProfileSocialMedia.module.scss';
import { IconComponent } from '../../../modules/collection';
import classNames from 'classnames';

type Props = {
  items: { link: string | null | undefined; Icon: IconComponent }[];
  className?: string;
};

export function ProfileSocialMedia({ items, className }: Props) {

  function openLink(link: string) {
    window.open(link, '_blank');
  }

  return (
    <div className={classNames(styles.profileSocialMedia, className)}>
      {items.map(({ Icon, link }, index) => (
          <Icon
            key={link! + index}
            onClick={() => openLink(link as string)}
            className={styles.icon}
          />
        ))}
    </div>
  );
}
