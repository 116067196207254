import classNames from 'classnames';
import { Progress } from '@mantine/core';
import { AppButton, AppCreatorLabel } from '../../../../components';
import { Collection, CollectionType } from '../../../../modules/collection';
import { useSelector } from 'react-redux';
import { selectUser, WalletType } from '../../../../modules/auth';
import { AppRoute } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import styles from './CollectionMinter.module.scss';
import { useAppDispatch } from '../../../../hooks';
import { useCreateTokenMutation, usePayTokenMutation } from '../../../../modules/token';
import { formatCurrency, getCompactNumber, getPercentage } from '../../../../utils';
import { useAppModals } from '../../../../modals';
import {
  INSUFFICIENT_FUNDS_ERROR_CODE,
  REJECTED_ERROR_CODE,
  SUPPORTED_NETWORK,
} from '../../../../ethers/ethersConstants';
import { mintToken as mintTokenWithMetamask } from '../../../../ethers';
import { ProfileTab } from '../../../../enums';
import { showWarning } from '../../../../notifications';

type Props = {
  className: string;
  collection: Collection;
};

export function CollectionMinter({ className, collection }: Props) {
  const user = useSelector(selectUser);
  const modals = useAppModals();
  const [createToken] = useCreateTokenMutation();
  const [pay, { isLoading }] = usePayTokenMutation();
  const {
    user: creator,
    nftImage,
    name,
    price,
    priceWeb3,
    maxNft,
    symbol,
    address,
    nftCount,
    id,
  } = collection;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quantity = 1;
  const showWeb3Price = user?.type === WalletType.METAMASK;
  const tokenPrice = showWeb3Price
    ? `${priceWeb3.toFixed(2)} ${SUPPORTED_NETWORK.currency}`
    : formatCurrency(price);

  function connect() {
    navigate(AppRoute.Connect, {
      state: { redirectRoute: `${AppRoute.Collection}/${CollectionType.Fan}/${symbol}` },
    });
  }

  function toToken() {
    navigate(`${AppRoute.Success}/${symbol}`);
  }

  function collect() {
    if (!user) return;

    user.type === WalletType.METAMASK
      ? collectWithMetamask(user.address, user.id)
      : collectWithCard();
  }

  function collectWithMetamask(userAddress: string, userId: string) {
    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
    dispatch(
      mintTokenWithMetamask({ to: userAddress, collectionAddress: address, quantity, priceWeb3 }),
    )
      .unwrap()
      .then(async () => {
        await createToken({ collectionId: id, quantity, priceWeb3 });
        toToken();
      })
      .catch((e) => {
        if (e === INSUFFICIENT_FUNDS_ERROR_CODE) {
          showWarning(`You don't have enough funds to complete the transaction`);
        }
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  function collectWithCard() {
    pay({ symbol })
      .unwrap()
      .then((data) => {
        if (data.url) {
          window.open(data.url, '_self');
        }
      });
  }

  return (
    <div className={classNames(styles.collectionMinter, className)}>
      <div className={styles.header}>
        <img className={styles.image} src={nftImage} alt='nft' />
        <div className={styles.titleBlock}>
          <div className={styles.title}>{name}</div>
          <AppCreatorLabel {...creator} isSmall isIconDisplayed={false} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.stageBlock}>
          <div className={styles.stage}>Public Sale</div>
          <div className={styles.status}>Live Now</div>
        </div>

        <div className={styles.stats}>
          <div className={styles.row}>
            <div className={styles.name}>Price</div>
            <div className={styles.value}>{tokenPrice}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.name}>Availability</div>
            <div className={styles.value}>OPEN EDITION</div>
          </div>

          <div className={styles.row}>
            <div className={styles.name}>Collected</div>
            <div className={styles.value}>{`${getCompactNumber(nftCount)}/${getCompactNumber(
              maxNft,
            )}`}</div>
          </div>
        </div>

        <Progress
          radius='xl'
          mb={32}
          size='md'
          value={getPercentage(nftCount, maxNft)}
          styles={{
            root: {
              background: '#292A2E',
            },
          }}
        />

        {user && address ? (
          <AppButton
            isLoading={isLoading}
            className={styles.button}
            variant='white'
            radius='round'
            onClick={collect}
          >
            {`Mint for ${tokenPrice}`}
          </AppButton>
        ) : (
          <AppButton className={styles.button} variant='white' radius='round' onClick={connect}>
            {`Mint for ${tokenPrice}`}
          </AppButton>
        )}
      </div>
    </div>
  );
}
