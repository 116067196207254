import { FileObject } from '../types';

import styles from './FileView.module.scss';
import { Progress } from '@mantine/core';
import classNames from 'classnames';
import { IconCheck, IconFile, IconTrash } from '../../../icons';
import prettyBytes from 'pretty-bytes';

type Props = {
  fileObject: FileObject;
  onDeleteClick?: (fileObject: FileObject) => void;
};

export function FileView({ fileObject, onDeleteClick }: Props) {
  const { isUploaded, size, progress, error, name } = fileObject;
  const Icon = isUploaded ? IconCheck : IconFile;

  return (
    <div
      className={classNames(styles.fileView, { [styles.error]: error, [styles.done]: isUploaded })}
    >
      <div className={styles.iconFileContainer}>
        <Icon className={styles.iconFile} />
      </div>

      <div className={styles.container}>
        <div className={styles.upperBlock}>
          <div className={styles.fileInfo}>
            <div className={styles.status}>
              {error || (isUploaded ? 'Upload Complete' : 'Uploading...')}
            </div>
            <div className={styles.fileName}>{name}</div>
            <div className={styles.fileSize}>{prettyBytes(size)}</div>
          </div>
          {onDeleteClick && (
            <IconTrash className={styles.iconTrash} onClick={() => onDeleteClick(fileObject)} />
          )}
        </div>
        <div className={styles.progress}>
          <Progress className={styles.bar} value={progress || 0} />
          <div>{`${progress || 0}%`}</div>
        </div>
      </div>
    </div>
  );
}
