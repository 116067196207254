import {
  collectionAsyncSchema,
  StageName,
  useCreateFundingCollectionForm,
  useCreateFundingCollectionMutation,
} from '../../modules/collection';
import { Select, TextInput } from '@mantine/core';
import { AppButton, AppFormContainer, AppTabsVertical } from '../../components';
import { validateAsync } from '../../utils';
import { useAppDispatch } from '../../hooks';
import { deployFundingCollection } from '../../ethers';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AppRoute } from '../../routes';
import styles from './CreateFundingCollection.module.scss';
import { useAppModals } from '../../modals';

export function CreateFundingCollection() {
  const modals = useAppModals();
  const form = useCreateFundingCollectionForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCollection, { isLoading }] = useCreateFundingCollectionMutation();

  const onSubmit = form.onSubmit(async (values) => {
    const result = await validateAsync(collectionAsyncSchema, values, form);

    if (result.success) {
      const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
      dispatch(deployFundingCollection(values))
        .unwrap()
        .then(async () => {
          await createCollection(values)
            .unwrap()
            .then(() => {
              navigate(`${AppRoute.Collection}/funding/${values.symbol.toLowerCase()}/form`);
            });
        })
        .finally(() => {
          modals.closeContextModal(modalId);
        });
    }
  });

  const tabs = [
    {
      tabKey: 'Smart-Contract',
      tabLabel: 'Smart Contract',
      tabContent: (
        <AppFormContainer
          className={styles.formContainer}
          formId='create-form'
          onSubmit={onSubmit}
          step={1}
          disabled={isLoading}
          title='Create Smart Contract'
          description='Distribute your movies directly to your audience, bypassing middlemen and excessive commissions. Earn money from your work by offering your fans exclusive bonuses and build the community! '
        >
          <TextInput
            label='Campaign Name (Future Film Name)'
            placeholder='Enter campaign name'
            {...form.getInputProps('name')}
          />
          <div className={classNames(styles.fieldDescription, styles.withMargin)}>
            Funding campaign display name. This field cannot be updated in future, so pay attention.
          </div>

          <TextInput
            label='Film Symbol'
            placeholder='Enter movie symbol'
            {...form.getInputProps('symbol')}
          />
          <div className={classNames(styles.fieldDescription, styles.withMargin)}>
            Funding campaign display name. This field cannot be updated in future, so pay attention.
          </div>

          <Select
            label='Funding stage'
            data={[
              { value: StageName.PRE_PRODUCTION, label: 'Pre-Production' },
              { value: StageName.PRODUCTION, label: 'Production', disabled: true },
              { value: StageName.POST_PRODUCTION, label: 'Post-Production', disabled: true },
            ]}
            {...form.getInputProps('stageName')}
          />
          <div className={classNames(styles.fieldDescription, styles.withMargin)}>
            Choose the stage of the film you wish to fund.
          </div>
        </AppFormContainer>
      ),
    },
    {
      tabKey: 'Script-and-pitchdeck',
      tabLabel: 'Script & Pitchdeck',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Videos-and-photos',
      tabLabel: 'Videos & Photos',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Goal',
      tabLabel: 'Define Goal',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Options',
      tabLabel: 'Add Options',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Story',
      tabLabel: 'Add Story',
      tabContent: <></>,
      isDisabled: true,
    },
  ];

  return (
    <div className={styles.createCollection}>
      <div className={styles.header}>
        <div className={styles.title}>Create Funding Collection</div>
        <div className={styles.buttons}>
          <AppButton disabled={isLoading} onClick={() => navigate(-1)} variant='outline'>
            Cancel
          </AppButton>
          <AppButton disabled={isLoading} form='create-form' type='submit' variant='primary'>
            Deploy contract
          </AppButton>
        </div>
      </div>

      <AppTabsVertical tabs={tabs} activeTab='Smart-Contract' variant='stepper' />
    </div>
  );
}
