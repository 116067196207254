import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import styles from './ImageModal.module.scss';
import { getImageUrlParams } from '../../utils';

export const ImageModal = ({
  context,
  id,
  innerProps: { image, height, width },
}: ContextModalProps<{
  image: HTMLImageElement;
  height?: number;
  width?: number;
}>) => {
  return (
    <div className={styles.imageModal}>
      <img className={styles.image} src={image.src} alt='' />
    </div>
  );
};
