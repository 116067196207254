import { FundingCollectionHero } from './funding-collection-hero/FundingCollectionHero';
import { FundingCollectionTabs } from './funding-collection-tabs/FundingCollectionTabs';
import { CollectionType, FundingCollectionDto } from '../../../modules/collection';
import { AppIconButton } from '../../../components';
import { IconEdit } from '../../../icons';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../routes';
import styles from './FundingCollectionView.module.scss';

type Props = {
  collection: FundingCollectionDto;
};

export function FundingCollectionView({ collection }: Props) {
  const user = useSelector(selectUser);
  const isUserCollection = collection.user.id === user?.id;
  const navigate = useNavigate();

  function editCollection() {
    navigate(
      `${AppRoute.Collection}/${CollectionType.Funding}/${collection.symbol.toLowerCase()}/edit`,
    );
  }

  return (
    <div className={styles.collection}>
      <FundingCollectionHero collection={collection} />

      <div className={styles.content}>
        <div className={styles.collectionDetails}>
          {isUserCollection && (
            <AppIconButton
              radius='round'
              onClick={editCollection}
              className={styles.buttonEdit}
              icon={IconEdit}
            >
              Edit collection
            </AppIconButton>
          )}

          <FundingCollectionTabs collection={collection} />
        </div>
      </div>
    </div>
  );
}
