import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './ImageModal';

export function getImageModalContext(innerProps: {
  image: HTMLImageElement;
  height?: number;
  width?: number;
}): OpenContextModal<{
  image: HTMLImageElement;
  height?: number;
  width?: number;
}> {
  return {
    styles: {
      inner: { padding: 0, height: '100vh', width: '100vw' },
      header: { borderRadius: 12 },
      body: { borderRadius: 12 },
      content: { borderRadius: 12 },
    },
    innerProps,
  };
}
