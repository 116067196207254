import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import { Input } from '@mantine/core';
import { AppImageUploader } from '../../components';
import { MB5 } from '../../constants';
import { S3Folder } from '../../enums';
import { FileObject } from '../../components/app-file-uploader/types';
import styles from './RteImageModal.module.scss';
import { useLazyFindImageQuery } from '../../modules/file';

export const RteImageModal = ({
  context,
  id,
  innerProps: { onSuccess, symbol },
}: ContextModalProps<{
  symbol: string;
  onSuccess: (url: string) => void;
  onReject: () => void;
}>) => {
  const [findImage] = useLazyFindImageQuery();

  function handleImage(images: FileObject[]) {
    if (!images.length) {
      return;
    }

    findImage({ id: images[0].id })
      .unwrap()
      .then(({ url }) => {
        onSuccess(url);
        context.closeContextModal(id);
      });
  }

  return (
    <form className={styles.rteImageModal}>
      <Input.Wrapper className={styles.withMargin} label='Click or drop to upload'>
        <AppImageUploader
          maxSize={MB5}
          label='max 5MB, GIF, JPEG or PNG'
          onChange={handleImage}
          folder={S3Folder.Collection}
          id={symbol.toUpperCase()}
          isPublic
          withPreview={false}
        />
      </Input.Wrapper>
    </form>
  );
};
