import { OpenContextModal } from '@mantine/modals/lib/context';
import { Modal } from '../../enums';

export * from './SwitchNetworkModal.module.scss';

export const switchNetworkModalContext: OpenContextModal = {
  modalId: Modal.SwitchNetwork,
  title: `Switch Network`,
  closeOnClickOutside: false,
  withCloseButton: false,
  closeOnEscape: false,
  innerProps: {},
};
