import {
  CollectionFormTab,
  useFindCollectionFormQuery,
  usePublishMutation,
} from '../../modules/collection';
import { AppButton, AppTabsVertical } from '../../components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { Loading } from '../loading/Loading';
import { ImdbImport } from './imdb-import/ImdbImport';
import { AddDescription } from './add-description/AddDescription';
import { UploadVideos } from './upload-videos/UploadVideos';
import { AddBonuses } from './add-bonuses/AddBonuses';
import { useToggle } from '@mantine/hooks';
import { socket } from '../../socket';
import { useAppSelector } from '../../hooks';
import { selectIsLoading } from '../../api';
import styles from './CollectionForm.module.scss';
import { AppTab } from '../../types';

export function CollectionForm() {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const { data: collectionForm, isError, refetch } = useFindCollectionFormQuery({ symbol });
  const isDeploying = collectionForm && !collectionForm.address;
  const [publish, { isLoading: isPublishing }] = usePublishMutation();
  const navigate = useNavigate();
  const [isBonusCreationForm, toggleBonusCreationForm] = useToggle();
  const isCollectionFinished =
    collectionForm &&
    collectionForm.film &&
    collectionForm.bonuses.length &&
    collectionForm.movieId &&
    collectionForm.trailers.length &&
    collectionForm.description;

  useEffect(() => {
    if (isError || (collectionForm && collectionForm.isPublished)) {
      navigate(AppRoute.NotFound);
    }
    if (collectionForm) {
      if (isCollectionFinished) {
        publish({ symbol })
          .unwrap()
          .then(() => {
            navigate(`${AppRoute.Collection}/fan/${symbol.toLowerCase()}`);
          });
      }

      const defaultTab = tabs.find(isClickableTab)?.tabKey || null;
      setCurrentTab(defaultTab);
    }
  }, [isError, collectionForm]);

  useEffect(() => {
    socket.on('collectionDeployed', (collectionSymbol) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    return () => {
      socket.off('collectionDeployed');
    };
  }, [socket]);

  const tabs: AppTab[] = [
    {
      tabKey: CollectionFormTab.SmartContract,
      tabLabel: 'Smart Contract',
      tabContent: <></>,
      isDone: true,
    },
    {
      tabKey: CollectionFormTab.ImportData,
      tabLabel: 'Import IMDB Data',
      tabContent: (
        <ImdbImport symbol={symbol} onSubmit={refetch} formId={CollectionFormTab.ImportData} />
      ),
      isDone: !!collectionForm?.film,
    },
    {
      tabKey: CollectionFormTab.UploadFilm,
      tabLabel: 'Upload Film',
      tabContent: (
        <UploadVideos onSubmit={refetch} symbol={symbol} id={CollectionFormTab.UploadFilm} />
      ),
      isDone: !!collectionForm?.movieId && !!collectionForm?.trailers.length,
    },
    {
      tabKey: CollectionFormTab.Bonuses,
      tabLabel: 'Create Bonuses',
      tabContent: (
        <AddBonuses
          isCreation={isBonusCreationForm}
          toggleBonusCreation={toggleBonusCreationForm}
          id={CollectionFormTab.Bonuses}
          onSubmit={refetch}
          symbol={symbol}
        />
      ),
      isDone: !!collectionForm?.bonuses.length,
    },
    {
      tabKey: CollectionFormTab.Description,
      tabLabel: 'Add Description',
      tabContent: (
        <AddDescription symbol={symbol} onSubmit={refetch} id={CollectionFormTab.Description} />
      ),
      isDone: !!collectionForm?.description,
    },
  ];

  function findCurrentTabIndex(): number {
    return tabs.findIndex((tab) => tab.tabKey === currentTab);
  }
  function goBack(): void {
    setCurrentTab(prevTabs[prevTabs.length - 1].tabKey);
  }

  function goForward(): void {
    setCurrentTab(nextTabs[0].tabKey);
  }

  const isClickableTab = (tab: AppTab) => !tab.isDisabled && !tab.isDone;

  const [currentTab, setCurrentTab] = useState<string | null>(() => null);
  const prevTabs = tabs.slice(0, findCurrentTabIndex()).filter(isClickableTab);
  const nextTabs = tabs.slice(findCurrentTabIndex() + 1, tabs.length).filter(isClickableTab);
  const isFinalForm = !prevTabs.length && !nextTabs.length;
  const submitButtonLabel =
    isBonusCreationForm && currentTab === CollectionFormTab.Bonuses
      ? 'Save bonus'
      : isFinalForm
      ? 'Publish collection'
      : 'Save';
  const secondaryButtonLabel =
    isBonusCreationForm && currentTab === CollectionFormTab.Bonuses ? 'Cancel' : 'Previous Step';
  const formId =
    isBonusCreationForm && currentTab === CollectionFormTab.Bonuses
      ? 'BonusForm'
      : currentTab || undefined;
  const isSecondaryButtonShown = isBonusCreationForm || (!!prevTabs.length && currentTab);
  const isNextButtonShown = !isBonusCreationForm && !!nextTabs.length && currentTab;
  const areButtonDisabled = useAppSelector(selectIsLoading);

  return collectionForm && !isPublishing && collectionForm.address ? (
    <div className={styles.collectionForm}>
      <div className={styles.header}>
        <div className={styles.title}>
          {isBonusCreationForm ? 'Bonus Form' : collectionForm?.name}
        </div>
        <div className={styles.buttons}>
          {isSecondaryButtonShown && (
            <AppButton
              className={styles.button}
              disabled={areButtonDisabled}
              onClick={isBonusCreationForm ? toggleBonusCreationForm : goBack}
              variant='outline'
            >
              {secondaryButtonLabel}
            </AppButton>
          )}
          {isNextButtonShown && (
            <AppButton
              disabled={areButtonDisabled}
              className={styles.button}
              onClick={goForward}
              variant='outline'
            >
              Next Step
            </AppButton>
          )}
          <AppButton
            className={styles.button}
            disabled={areButtonDisabled}
            form={formId}
            type='submit'
            variant='primary'
          >
            {submitButtonLabel}
          </AppButton>
        </div>
      </div>

      <AppTabsVertical
        activeTab={currentTab}
        onTabChange={setCurrentTab}
        tabs={tabs}
        variant='stepper'
      />
    </div>
  ) : (
    <Loading
      text={isDeploying ? 'Wait a bit, we are writing your smart contract' : undefined}
      withLogo={isDeploying}
    />
  );
}
