import styles from './UserCollections.module.scss';
import { useToggle } from '@mantine/hooks';
import { useState } from 'react';
import { AppButton } from '../../../../components';
import { UserFanCollections } from './user-fan-collections/UserFanCollections';
import { UserFundingCollections } from './user-funding-collections/UserFundingCollections';

enum Tab {
  Funding,
  Fan,
}

type Props = {
  userId: string;
};

export function UserCollections({ userId }: Props) {
  const [tab, setTab] = useState<Tab>(Tab.Funding);

  return (
    <div className={styles.userCollections}>
      <div className={styles.tabs}>
        <AppButton
          radius='round'
          variant='dark'
          onClick={() => setTab(Tab.Funding)}
          inactive={tab === Tab.Fan}
        >
          Funding Collections
        </AppButton>
        <AppButton
          radius='round'
          variant='dark'
          onClick={() => setTab(Tab.Fan)}
          inactive={tab === Tab.Funding}
        >
          Fan Collections
        </AppButton>
      </div>
      {tab === Tab.Fan ? (
        <UserFanCollections userId={userId} />
      ) : (
        <UserFundingCollections userId={userId} />
      )}
    </div>
  );
}
