import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import { TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { AppButton } from '../../components';
import styles from './AddSubtaskModal.module.scss';

export const AddSubtaskModal = ({
  context,
  id,
  innerProps: { onSuccess },
}: ContextModalProps<{
  onSuccess: (subtask: { title: string; description: string }) => void;
  onReject: () => void;
}>) => {
  const form = useForm<{ title: string; description: string }>({
    validate: zodResolver(
      z.object({
        title: z.string().min(3).max(100),
        description: z.string().min(3).max(100),
      }),
    ),
    initialValues: {
      title: '',
      description: '',
    },
  });

  const handleSubmit = form.onSubmit(({ title, description }) => {
    onSuccess({ title, description });
    context.closeContextModal(id);
  });

  return (
    <form className={styles.textInsertModal} onSubmit={handleSubmit}>
      <TextInput placeholder='Add title' maxLength={2500} {...form.getInputProps('title')} />
      <TextInput
        placeholder='Add description'
        maxLength={2500}
        {...form.getInputProps('description')}
      />

      <AppButton className={styles.button} type='submit'>
        Add
      </AppButton>
    </form>
  );
};
