import { useNavigate, useParams } from 'react-router-dom';
import { useFindFundingCollectionQuery, useFindOneQuery } from '../../modules/collection';
import { useEffect } from 'react';
import { AppRoute } from '../../routes';
import { Loading } from '../loading/Loading';
import { socket } from '../../socket';
import { FundingCollectionView } from './funding-collection-view/FundingCollectionView';

export function FundingCollection() {
  const navigate = useNavigate();
  const { symbol = '' } = useParams<{ symbol: string }>();
  const { data: collection, isError, refetch } = useFindFundingCollectionQuery({ symbol });

  useEffect(() => {
    if (isError) {
      navigate(AppRoute.NotFound);
    }
  }, [isError]);

  useEffect(() => {
    socket.on('collectionTokenMinted', ({ symbol: collectionSymbol }) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    return () => {
      socket.off('collectionTokenMinted');
    };
  }, [socket]);

  return collection ? <FundingCollectionView collection={collection} /> : <Loading />;
}
