import { ContextModalProps } from '@mantine/modals';
import { useAppDispatch } from '../../hooks';
import React from 'react';

import styles from './ConfirmationModal.module.scss';
import { AppButton } from '../../components';

export const ConfirmationModal = ({
  context,
  id,
  innerProps: { onReject, message, onSuccess },
}: ContextModalProps<{
  onSuccess: () => void;
  onReject: () => void;
  message: string;
}>) => {
  const dispatch = useAppDispatch();

  function handleSuccess() {
    onSuccess();
    context.closeContextModal(id);
  }

  function handleReject() {
    onReject();
    context.closeContextModal(id);
  }

  return (
    <div className={styles.confirmationModal}>
      <div className={styles.text}>{message}</div>

      <div className={styles.actions}>
        <AppButton variant='white' className={styles.button} onClick={handleReject}>
          Cancel
        </AppButton>
        <AppButton variant='outline' className={styles.button} onClick={handleSuccess}>
          Confirm
        </AppButton>
      </div>
    </div>
  );
};
