import { FilmmakerRequestDto } from '../../modules/user';
import { getTruncatedHash } from '../../utils';
import { IconArrowNarrowRight, IconExternalLink } from '@tabler/icons-react';
import styles from './RequestCard.module.scss';
import { AppButton } from '../app-button/AppButton';

type Props = {
  request: FilmmakerRequestDto;
  processRequestCallback: (userId: string, isApproved: boolean) => void;
};

export function RequestCard({ request, processRequestCallback }: Props) {
  const {
    userId,
    user: { username, fullName, address, email, imdb, twitter, website },
  } = request;

  const isSocialBLockShown = imdb || twitter || website;

  return (
    <div className={styles.request}>
      {fullName && <div className={styles.fullName}>{fullName}</div>}
      {username && <div className={styles.userName}>@{username}</div>}
      {email && <div className={styles.email}>{email}</div>}
      {isSocialBLockShown && (
        <div className={styles.socialLinks}>
          {website && <div className={styles.link}>Web Site</div>}
          {imdb && <div className={styles.link}>IMDB</div>}
          {twitter && <div className={styles.link}>Twitter</div>}
        </div>
      )}
      <div className={styles.address}>
        {getTruncatedHash(address, 12)} <IconExternalLink size={16} />
      </div>
      <div className={styles.profileLink}>
        View full profile <IconArrowNarrowRight size={16} />
      </div>
      <div className={styles.buttons}>
        <AppButton className={styles.button} onClick={() => processRequestCallback(userId, true)}>
          Approve artist
        </AppButton>
        <AppButton className={styles.button} onClick={() => processRequestCallback(userId, false)}>
          Decline
        </AppButton>
      </div>
    </div>
  );
}
