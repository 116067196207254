import { useTrailersForm, useUpdateTrailersMutation } from '../../modules/collection';
import { Input } from '@mantine/core';
import { AppFileUploader, AppFormContainer } from '../index';
import { GB5 } from '../../constants';
import { S3Folder, FileType } from '../../enums';
import { FileDto } from '../../modules/file';
import { useEffect } from 'react';
import styles from './VideosForm.module.scss';

type Props = {
  id: string;
  symbol: string;
  onSubmit: () => void;
  videos: FileDto[];
  onChange?: (trailerIds: string[]) => void;
};

export function VideosForm({ id, symbol, onSubmit, videos, onChange }: Props) {
  const form = useTrailersForm();
  const [updateTrailers, { isLoading }] = useUpdateTrailersMutation();

  const handleSubmit = form.onSubmit(async (values) => {
    await updateTrailers({ ...values, symbol }).unwrap();
    onSubmit();
  });

  useEffect(() => {
    onChange?.(form.values.trailerIds);
  }, [form.values]);

  useEffect(() => {
    form.setValues({ trailerIds: videos.map((video) => video.id) });
  }, [videos]);

  return (
    <div className={styles.videosForm}>
      <AppFormContainer
        title='Edit Trailers'
        description='To form a fan collection, you must upload the movie and trailer'
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        className={styles.form}
      >
        <Input.Wrapper label='Upload Trailer' {...form.getInputProps('trailerIds')}>
          <div className={styles.fieldDescription}>You can upload 2 trailers</div>

          <AppFileUploader
            className={styles.uploader}
            maxSize={GB5}
            multiple
            label='max 5GB, MP4, MOV or AVI'
            onChange={(videos) => {
              form.setFieldValue(
                'trailerIds',
                videos.map((video) => video.id),
              );
            }}
            accept='video/mp4, video/mov, video/avi, video/vnd.avi, video/quicktime'
            maxFiles={2}
            folder={S3Folder.Collection}
            type={FileType.Video}
            isPublic
            initialValue={videos}
          />
        </Input.Wrapper>
      </AppFormContainer>
    </div>
  );
}
