import {
  MAX_DESCRIPTION,
  useDescriptionForm,
  useAddDescriptionMutation,
} from '../../modules/collection';

import { Textarea } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { AppFormContainer, AppTextarea } from '../index';
import styles from './DescriptionForm.module.scss';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  description?: string | null;
  onChange?: (description: string) => void;
};

export function DescriptionForm({ id, symbol, onSubmit, description, onChange }: Props) {
  const [addDescription, { isLoading }] = useAddDescriptionMutation();
  const form = useDescriptionForm();

  const handleSubmit = form.onSubmit(async ({ description }) => {
    await addDescription({ symbol, description }).unwrap();
    onSubmit?.();
  });

  useEffect(() => {
    onChange?.(form.values.description);
  }, [form.values]);

  useEffect(() => {
    form.setValues({ description: description || '' });
  }, [description]);

  return (
    <div className={styles.descriptionForm}>
      <AppFormContainer
        description='Tell people why you created this collection!'
        title='Description'
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        className={styles.form}
      >
        <AppTextarea
          label='Collection Description'
          placeholder='Enter Collection Description'
          minRows={10}
          maxRows={40}
          maxLength={2500}
          {...form.getInputProps('description')}
        />
      </AppFormContainer>
    </div>
  );
}
