import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Links } from '../links/Links';
import { Menu } from '../menu/Menu';
import { selectAccount, selectUser } from '../../modules/auth';
import { AppButton } from '../app-button/AppButton';
import { Brand } from '../app-brand/Brand';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import styles from './Header.module.scss';
import { selectProfile } from '../../modules/user';

type Props = { className: string; withBorder?: boolean };

export function Header({ className, withBorder = true }: Props) {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);

  return (
    <div className={classNames(styles.header, className, { [styles.border]: withBorder })}>
      <Brand color='white' className={styles.brand} />
      <Links className={styles.links} />
      {!profile && (
        <AppButton variant='white' radius='round' onClick={() => navigate(AppRoute.Connect)}>
          Connect wallet
        </AppButton>
      )}
      {
        <div className={classNames({ [styles.menuHidden]: !profile })}>
          <Menu profile={profile} />
        </div>
      }
    </div>
  );
}
