import { AppLoader, Brand } from '../../components';

import { IconLogo } from '../../icons';
import styles from './Loading.module.scss';
import classNames from 'classnames';

type Props = {
  text?: string;
  withLogo?: boolean;
  color?: 'lime' | 'white';
  className?: string;
};

export function Loading({ text, withLogo, color = 'white', className }: Props) {
  return (
    <div className={classNames(styles.loading, className)}>
      {withLogo ? (
        <Brand color='gradient' className={classNames(styles.logo, styles.shimmer)} />
      ) : (
        <AppLoader color={color} />
      )}
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
}
