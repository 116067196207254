import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import { TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { AppButton } from '../../components';
import styles from './TextInsertModal.module.scss';

export const TextInsertModal = ({
  context,
  id,
  innerProps: { onSuccess },
}: ContextModalProps<{
  onSuccess: (text: string) => void;
  onReject: () => void;
}>) => {
  const form = useForm<{ text: string }>({
    validate: zodResolver(
      z.object({
        text: z.string(),
      }),
    ),
    initialValues: {
      text: '',
    },
  });

  const handleSubmit = form.onSubmit(({ text }) => {
    onSuccess(text);
    context.closeContextModal(id);
  });

  return (
    <form className={styles.textInsertModal} onSubmit={handleSubmit}>
      <TextInput placeholder='Add a text' maxLength={2500} {...form.getInputProps('text')} />

      <AppButton className={styles.button} type='submit'>
        Add
      </AppButton>
    </form>
  );
};
