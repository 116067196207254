import { useEffect, useRef } from 'react';
import { OverlappingCards } from '../overlapping-cards/OverlappingCards';
import styles from './CollectionHero.module.scss';
import { AppIconButton } from '../../../../components';
import { IconHeartFilled } from '@tabler/icons-react';
import * as StackBlur from 'stackblur-canvas';
import { getImageFromSrc, resizeImage } from '../../../../utils';
import { Collection } from '../../../../modules/collection';
import { CollectionStatistics } from './collection-statistics/CollectionStatistics';
import { IconDownload } from '../../../../icons';

type Props = {
  collection: Collection;
};

export function CollectionHero({ collection }: Props) {
  const { poster, background, title } = collection.film;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');

    if (ctx) {
      const img = getImageFromSrc(resizeImage(background, 1280, 720));
      img.onload = () => {
        StackBlur.image(img, canvasRef.current!, 100, true);
      };
    }
  }, []);

  return (
    <div className={styles.collectionHero}>
      <canvas ref={canvasRef} id={styles['myFogCanvas']} />
      <div className={styles.gradient} />
      <OverlappingCards nft={collection.nftImage} poster={poster} symbol={collection.symbol} />
      <div className={styles.title}>{title}</div>
      <CollectionStatistics collection={collection} />
      <div className={styles.actions}>
        <AppIconButton variant='outline' radius='round' icon={IconHeartFilled}>
          137
        </AppIconButton>
        <AppIconButton variant='outline' radius='round' icon={IconDownload}>
          Share & Earn 5%
        </AppIconButton>
      </div>
    </div>
  );
}
