import styles from './AppTokenPreview.module.scss';
import { UserTokenPreviewDto } from '../../modules/token';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { AppBadge } from '../app-badge/AppBadge';
import { AppTokenImage } from '../app-token-image/AppTokenImage';
import { AppSkeleton } from '../app-skeleton/AppSkeleton';

type Props = {
  token: UserTokenPreviewDto;
  className?: string;
};

export function AppTokenPreview({ token, className }: Props) {
  const {
    image,
    collectionName,
    collectionId,
    externalId,
    optionName,
    id,
    collectionSymbol,
    collectionType,
    price,
  } = token;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`${AppRoute.Token}/${collectionType}/${id}`);
  }

  return (
    <div onClick={handleClick} className={classNames(styles.tokenPreview, className)}>
      <AppTokenImage src={image} symbol={collectionSymbol} />
      <div className={styles.tokenInfo}>
        <div className={styles.nameContainer}>
          <div className={styles.collectionName}>
            {`${optionName || collectionName}`}
            {externalId && <span className={styles.id}>#{externalId}</span>}
          </div>

          {!externalId && <AppBadge className={styles.badge} type='minting' />}
        </div>

        <div className={styles.price}>
          Last price: <span>${price}</span>
        </div>
      </div>
    </div>
  );
}

export const AppTokenPreviewSkeleton = AppSkeleton;
