import { Carousel, Embla } from '@mantine/carousel';

import styles from './FilmGallery.module.scss';
import { useState } from 'react';
import { UnstyledButton } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { getImageUrlParams } from '../../../../utils';
import classNames from 'classnames';
import { useAppModals } from '../../../../modals';

type Props = {
  images: { id: string; url: string }[];
};

export function FilmGallery({ images }: Props) {
  const modals = useAppModals();
  const [embla, setEmbla] = useState<Embla | null>(null);

  const slides = images.map(({ url, id }) => {
    const thumbnail = `${url}${getImageUrlParams(186, 186)}`;

    return (
      <Carousel.Slide
        onClick={() => modals.openImageModal({ image: url })}
        className={styles.slide}
        key={id}
      >
        <img className={styles.image} src={thumbnail} alt='' />
      </Carousel.Slide>
    );
  });

  return (
    <div className={styles.filmGallery}>
      <UnstyledButton
        className={classNames(styles.control, styles.left)}
        onClick={() => embla!.scrollPrev()}
      >
        <IconChevronLeft />
      </UnstyledButton>
      <UnstyledButton
        className={classNames(styles.control, styles.right)}
        onClick={() => embla!.scrollNext()}
      >
        <IconChevronRight />
      </UnstyledButton>

      <Carousel
        className={styles.carousel}
        slideSize='auto'
        slideGap={20}
        getEmblaApi={setEmbla}
        draggable={false}
        withControls={false}
        align='start'
      >
        {slides}
      </Carousel>
    </div>
  );
}
