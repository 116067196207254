import { useNavigate, useParams } from 'react-router-dom';
import { useFindOneQuery } from '../../modules/collection';
import { useEffect } from 'react';
import { AppRoute } from '../../routes';
import { Loading } from '../loading/Loading';
import { CollectionView } from './collection-view/CollectionView';
import { socket } from '../../socket';

export function Collection() {
  const navigate = useNavigate();
  const { symbol = '' } = useParams<{ symbol: string }>();
  const { data: collection, isError, refetch } = useFindOneQuery({ symbol });

  useEffect(() => {
    if (isError) {
      navigate(AppRoute.NotFound);
    }
  }, [isError]);

  useEffect(() => {
    socket.on('collectionTokenMinted', ({ symbol: collectionSymbol }) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    return () => {
      socket.off('collectionTokenMinted');
    };
  }, [socket]);

  return collection ? <CollectionView collection={collection} /> : <Loading />;
}
