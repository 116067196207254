import { CollectionType, ContentType, OptionPreviewDto } from '../../../../../modules/collection';
import { formatCurrency, getCompactNumber, resizeImage } from '../../../../../utils';
import { AppButton } from '../../../../../components';
import { mintFundingToken, usePayTokenMutation } from '../../../../../modules/token';
import { useAppModals } from '../../../../../modals';
import { useAppDispatch } from '../../../../../hooks';
import { useCreateFundingTokenMutation } from '../../../../../modules/token';
import { AppRoute } from '../../../../../routes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser, WalletType } from '../../../../../modules/auth';
import {
  INSUFFICIENT_FUNDS_ERROR_CODE,
  REJECTED_ERROR_CODE,
  SUPPORTED_NETWORK,
} from '../../../../../ethers/ethersConstants';
import styles from './OptionPreview.module.scss';
import { showWarning } from '../../../../../notifications';

type Props = {
  option: OptionPreviewDto;
  collectionAddress: string;
  symbol: string;
  isUserCollection: boolean;
  isWaitlist: boolean;
};

export function OptionPreview({ option, collectionAddress, symbol, isWaitlist }: Props) {
  const user = useSelector(selectUser);
  const {
    name,
    nftImage,
    description,
    price,
    priceWeb3,
    tokenCount,
    maxNft,
    id,
    externalIndex,
    userTokenCount,
    type,
  } = option;
  const navigate = useNavigate();
  const [createToken] = useCreateFundingTokenMutation();
  const [pay, { isLoading }] = usePayTokenMutation();
  const modals = useAppModals();
  const dispatch = useAppDispatch();
  const quantity = 1;
  const hasContent = type !== ContentType.None;
  const hasAccess = hasContent && !!userTokenCount && user;
  const showWeb3Price = user?.type === WalletType.METAMASK;
  const tokenPrice = showWeb3Price
    ? `${priceWeb3.toFixed(2)} ${SUPPORTED_NETWORK.currency}`
    : formatCurrency(price);
  const isSoldOut = tokenCount >= maxNft;

  function openContentModal() {
    modals.openContentModal({
      contentId: id,
      contentType: type,
      name: name,
      contentDescription: description,
      collectionType: CollectionType.Funding,
    });
  }

  function collect(userAddress: string, userId: string) {
    if (!user) return;

    user.type === WalletType.METAMASK
      ? collectWithMetamask(userAddress, userId)
      : collectWithCard();
  }

  function collectWithCard() {
    pay({ symbol, optionId: id })
      .unwrap()
      .then((data) => {
        if (data.url) {
          window.open(data.url, '_self');
        }
      });
  }

  function collectWithMetamask(userAddress: string, userId: string) {
    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
    dispatch(
      mintFundingToken({
        to: userAddress,
        collectionAddress,
        quantity,
        priceWeb3,
        externalIndex,
      }),
    )
      .unwrap()
      .then(async () => {
        await createToken({ optionId: id, quantity, priceWeb3 });
        toToken(id);
      })
      .catch((e) => {
        if (e === INSUFFICIENT_FUNDS_ERROR_CODE) {
          showWarning(`You don't have enough funds to complete the transaction`);
        }
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  function toToken(optionId: string) {
    navigate(`${AppRoute.Success}/${symbol}/${optionId}`);
  }

  function connect() {
    navigate(AppRoute.Connect, {
      state: { redirectRoute: `${AppRoute.Collection}/${CollectionType.Funding}/${symbol}` },
    });
  }

  return (
    <div className={styles.optionPreview}>
      <img src={resizeImage(nftImage, 300, 300)} alt='Nft Image' />
      <div className={styles.info}>
        <div className={styles.upperContainer}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.lowerContainer}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.name}>Price</div>
              <div className={styles.value}>{tokenPrice}</div>
            </div>

            <div className={styles.stat}>
              <div className={styles.name}>Collected</div>
              <div className={styles.value}>
                {isSoldOut
                  ? 'SOLD OUT'
                  : `${getCompactNumber(tokenCount)}/${getCompactNumber(maxNft)}`}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            {hasAccess && !isWaitlist && (
              <AppButton
                onClick={openContentModal}
                className={styles.button}
                variant='dark2'
                radius='round'
              >
                View Bonus
              </AppButton>
            )}
            {!isWaitlist && !isSoldOut && (
              <>
                {user ? (
                  <AppButton
                    onClick={() => collect(user!.address, user!.id)}
                    variant='white'
                    radius='round'
                    isLoading={isLoading}
                  >{`Mint for ${tokenPrice}`}</AppButton>
                ) : (
                  <AppButton onClick={connect} variant='white' radius='round'>
                    {`Mint for ${tokenPrice}`}
                  </AppButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
