import styles from './LinkBonusContent.module.scss';
import { AppClipboardButton } from '../../../components';
import { BonusContentDto } from '../../../modules/collection';

type Props = { content: BonusContentDto };

export function LinkBonusContent({ content }: Props) {
  return (
    <div className={styles.bonusContent}>
      <div className={styles.value}>{content.content}</div>
      <AppClipboardButton value={content.content} withLabel={false} />
    </div>
  );
}
