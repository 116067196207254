import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import styles from './AppRte.module.scss';
import { StarterKit } from '@tiptap/starter-kit';
import { useEffect, useState } from 'react';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import { IconPhoto, IconLink } from '@tabler/icons-react';
import { useAppModals } from '../../modals';

type Props = {
  content?: string | null;
  symbol: string;
  onChange: (content: string) => void;
  maxLength: number;
};

export function AppRte({ content = '', onChange, symbol, maxLength }: Props) {
  const [length, setLength] = useState(0);
  const modal = useAppModals();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Underline,
      Image.configure({
        HTMLAttributes: {
          width: '100%',
          height: 'auto',
        },
      }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
  });

  useEffect(() => {
    if (editor) {
      setLength(editor.getText().length);

      editor?.on('update', () => {
        setLength(editor.getText().length);
        onChange(editor.getHTML());
      });
    }

    return () => {
      editor?.off('update');
    };
  }, [editor]);

  function addLink() {
    if (!window.getSelection()?.toString()) {
      return;
    }

    modal.openRteLinkModal({
      onSuccess(link) {
        editor?.chain().focus().extendMarkRange('link').setLink({ href: link }).run();
      },
    });
  }

  function addImage() {
    modal.openRteImageModal({
      symbol,
      onSuccess(url) {
        editor?.chain().focus().setImage({ src: url }).run();
      },
    });
  }

  return (
    <div className={styles.rte}>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control onClick={addLink} aria-label='Add link' title='Add link'>
              <IconLink width='1rem' height='1rem' stroke='currentcolor' strokeWidth={1.5} />
            </RichTextEditor.Control>
            <RichTextEditor.Unlink />
            <RichTextEditor.Control onClick={addImage} aria-label='Add image' title='Add image'>
              <IconPhoto width='1rem' height='1rem' stroke='currentcolor' />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
      <div className={styles.description}>
        <div>With preserved line break</div>
        <div>{`${length}/${maxLength}`}</div>
      </div>
    </div>
  );
}
