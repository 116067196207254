import styles from './ContractDetails.module.scss';
import { getTruncatedHash } from '../../utils';
import dayjs from 'dayjs';
import { SUPPORTED_NETWORK } from '../../ethers/ethersConstants';

type Props = {
  collection: { createdAt: string; address: string };
  additionalRows?: { name: string; value: string; id: string }[];
};

export function ContractDetails({ collection, additionalRows = [] }: Props) {
  const { createdAt, address } = collection;
  const dateStr = dayjs(createdAt).format('MMMM DD, YYYY');

  return (
    <div className={styles.contractDetails}>
      <div className={styles.row}>
        <div className={styles.name}>RELEASE DATE</div>
        <div className={styles.value}>{dateStr}</div>
      </div>

      {additionalRows.map(({ name, value, id }) => (
        <div key={id} className={styles.row}>
          <div className={styles.name}>{name}</div>
          <div className={styles.value}>{value}</div>
        </div>
      ))}

      <div className={styles.row}>
        <div className={styles.name}>FILM CONTRACT ADDRESS</div>
        <div className={styles.value}>{getTruncatedHash(address, 10)}</div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>BLOCKCHAIN</div>
        <div className={styles.value}>{SUPPORTED_NETWORK.name.toUpperCase()}</div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>TOKEN STANDARD</div>
        <div className={styles.value}>ERC-721</div>
      </div>
    </div>
  );
}
