import { ReactNode } from "react";
import { Brand } from "../../components";

import styles from "./Form.module.scss";

type Props = {
  title: string;
  children: ReactNode;
};

export function Form({ title, children }: Props) {
  return (
    <div className={styles.form}>
      <div className={styles.formContainer}>
        <Brand />

        <div className={styles.title}>{title}</div>

        <>{children}</>
      </div>
    </div>
  );
}
