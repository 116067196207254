import { useImage } from '../../hooks';
import { resizeImage } from '../../utils';
import styles from './OptimizedImage.module.scss';
import classNames from 'classnames';

type Props = {
  className?: string;
  src: string | undefined;
  alt: string | undefined;
  dimensions: [number, number];
  lowDimensions?: [number, number];
  onClick?: () => void;
};

export function OptimizedImage({ className, dimensions, lowDimensions, src, alt, onClick }: Props) {
  const [width, height] = dimensions;
  const [lowWidth, lowHeight] = lowDimensions || [width / 50, height / 50];

  const [optimizedSrc, { isLoading }] = useImage(
    resizeImage(src || '', lowWidth, lowHeight),
    resizeImage(src || '', width, height),
  );
  return (
    <div className={classNames(styles.optimizedImage, className)}>
      <img
        className={className}
        onClick={onClick}
        src={src ? optimizedSrc : src}
        alt={alt}
        style={{
          filter: isLoading ? 'blur(40px)' : 'none',
          transition: isLoading ? 'none' : 'filter 0.1s ease-out',
        }}
      />
    </div>
  );
}
