import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Role, selectAccount, selectUser, WalletType } from '../../modules/auth';
import { hasAccess } from '../../utils';
import { AppRoute } from '../../routes';
import isEmpty from 'is-empty-typed';

type Props = {
  Component: React.ComponentType;
  role?: Role | Role[];
  restrictedRole?: Role | Role[];
};

export function Private({ Component, role = Role.User, restrictedRole = [] }: Props) {
  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);

  if (!user || (user.type === WalletType.METAMASK && !account)) {
    return <Navigate to={AppRoute.Connect} />;
  } else {
    return hasAccess(user, role) &&
      (isEmpty(restrictedRole) || !hasAccess(user, restrictedRole)) ? (
      <Component />
    ) : (
      <Navigate to={AppRoute.Wildcard} />
    );
  }
}
