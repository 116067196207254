import classNames from 'classnames';
import { Skeleton } from '@mantine/core';
import styles from './AppSkeleton.module.scss';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
  enabled?: boolean;
  animate?: boolean;
};

export function AppSkeleton({ className, enabled = true, children, animate }: Props) {
  return enabled ? (
    <Skeleton animate={animate} className={classNames(styles.appSkeleton, className)} />
  ) : (
    <>{children}</>
  );
}
