import { FC } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Modal } from '../enums';
import { DropdownModal } from './dropdown-modal';
import { AccountModal } from './account-modal';
import { ConfirmationModal } from './confirmation-modal';
import { SwitchNetworkModal } from './switch-network-modal/SwitchNetworkModal';
import { ContentModal } from './content-modal';
import { RteLinkModal } from './rte-link-modal';
import { RteImageModal } from './rte-image-modal';
import { ImageModal } from './image-modal';
import { SpinnerModal } from './spinner-modal';
import { PdfModal } from './pdf-modal';
import { GetNotifiedModal } from './get-notified-modal';
import { TextInsertModal } from './text-insert-modal';
import { AddSubtaskModal } from './add-subtask-modal';

export * from './useAppModals';

export const modals: Record<Modal, FC<ContextModalProps<any>>> = {
  [Modal.Spinner]: SpinnerModal,
  [Modal.Confirmation]: ConfirmationModal,
  [Modal.Dropdown]: DropdownModal,
  [Modal.Account]: AccountModal,
  [Modal.Image]: ImageModal,
  [Modal.Pdf]: PdfModal,
  [Modal.SwitchNetwork]: SwitchNetworkModal,
  [Modal.BonusContent]: ContentModal,
  [Modal.RteLink]: RteLinkModal,
  [Modal.RteImage]: RteImageModal,
  [Modal.GetNotified]: GetNotifiedModal,
  [Modal.Text]: TextInsertModal,
  [Modal.Subtask]: AddSubtaskModal,
};
