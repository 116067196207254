import { AppTabs } from '../../../../components';
import { FilmDetails } from '../film-details/FilmDetails';
import { CollectionDetails } from '../collection-details/CollectionDetails';
import { Collection } from '../../../../modules/collection';
import { useState } from 'react';

type Props = {
  collection: Collection;
};

export function CollectionTabs({ collection }: Props) {
  const [currentTab, setCurrentTab] = useState<string | null>(() => 'collection-details');

  const tabs = [
    {
      tabKey: 'collection-details',
      tabLabel: 'Collection Details',
      tabContent: <CollectionDetails collection={collection} />,
    },
    {
      tabKey: 'film-details',
      tabLabel: 'Film Details',
      tabContent: <FilmDetails film={collection.film} trailers={collection.trailers} />,
    },
    // {
    //   tabKey: 'activity',
    //   tabLabel: 'Activity',
    //   tabContent: <>Activity</>,
    // },
  ];

  return <AppTabs tabs={tabs} activeTab={currentTab} onTabChange={setCurrentTab} />;
}
