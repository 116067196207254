import { BonusDto, CollectionType, ContentType } from '../../../../../../modules/collection';
import styles from './BonusPreview.module.scss';
import { IconPreview } from '../../../../../collection-form/add-bonuses/icon-preview/IconPreview';
import { useAppModals } from '../../../../../../modals';
import { AppButton } from '../../../../../../components';

type Props = {
  bonus: BonusDto;
  hasAccess: boolean;
};

export function BonusPreview({ bonus, hasAccess }: Props) {
  const { id, type, background, icon, name, description } = bonus;
  const modals = useAppModals();
  const hasContent = type !== ContentType.None;

  function openContentModal() {
    modals.openContentModal({
      contentId: id,
      contentType: type,
      name: name,
      contentDescription: description,
      collectionType: CollectionType.Fan,
    });
  }

  return (
    <div className={styles.bonusPreview}>
      <div className={styles.upperContainer}>
        <IconPreview icon={icon} background={background} />
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
      {hasContent && hasAccess && (
        <AppButton
          onClick={openContentModal}
          className={styles.button}
          variant='dark2'
          radius='round'
        >
          View Bonus
        </AppButton>
      )}
    </div>
  );
}
