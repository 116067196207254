import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ColorSchemeProvider, Global, MantineProvider } from '@mantine/core';

import { Notifications } from '@mantine/notifications';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './store';
import { ModalsProvider } from '@mantine/modals';
import { modals } from './modals';
import { useThemeConfig } from './theme';
import { listenWeb3Events, provider } from './ethers';
import { init } from './modules/config';

import './App.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';

const { dispatch } = store;

dispatch(init());

function Root() {
  const { globalStyles, theme, colorScheme, toggleColorScheme } = useThemeConfig();
  return (
    <GoogleOAuthProvider clientId='77807195751-rtsoomkgfvrpk3btreais1ls60k3kqbg.apps.googleusercontent.com'>
      <Provider store={store}>
        <BrowserRouter>
          <MantineProvider withGlobalStyles withNormalizeCSS withCSSVariables theme={theme}>
            <Global styles={globalStyles} />
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
              <Notifications />
              <ModalsProvider modals={modals}>
                <App />
              </ModalsProvider>
            </ColorSchemeProvider>
          </MantineProvider>
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
}

// TODO: Return   "eslintConfig": {
//     "extends": "react-app"
//   },

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<Root />);
serviceWorker.unregister();
