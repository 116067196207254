import { AppIconButton, AppImagePreview } from '../../index';
import { IconPlus } from '@tabler/icons-react';
import { MAX_OPTIONS, OptionDto, StageName } from '../../../modules/collection';
import { ReactComponent as NoOptions } from './no-options.svg';
import { Switch, Table, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '../../../icons';
import { AppContentTypeLabel } from '../../app-content-type-label/AppContentTypeLabel';
import { isNewOption } from '../../../utils';
import classNames from 'classnames';
import styles from './OptionList.module.scss';

type Props = {
  onOptionCreate: () => void;
  options: OptionDto[];
  onOptionEdit: (id: string) => void;
  onOptionDelete: (id: string) => void;
  onOptionToggle: (id: string) => void;
  canEditOption: boolean;
  canCreateOption: boolean;
  currentStage: StageName;
};

export function OptionList({
  onOptionCreate,
  options,
  onOptionEdit,
  onOptionDelete,
  onOptionToggle,
  canEditOption,
  canCreateOption,
  currentStage,
}: Props) {
  const signCreateTooltip = 'You will be able to update option after you save created options';
  const signUpdateTooltip = 'You will be able to create option after you save updated options';

  const rows = options.map(
    ({ id, type, stageName, name, isDisabled, nftImage, maxNft, price, _count }) => {
      const isMinted = _count.tokens !== 0;

      return (
        <tr key={id}>
          <td className={classNames({ [styles.disabled]: isDisabled })}>
            <div className={styles.nameBlock}>
              <AppImagePreview id={nftImage.id} className={styles.image} height={186} width={186} />
              <div>
                <div>{name}</div>
                {(isMinted || isDisabled) && (
                  <div className={styles.status}>
                    {isMinted ? 'Minted option' : 'Disabled Option'}
                  </div>
                )}
              </div>
            </div>
          </td>
          <td>{stageName}</td>
          <td>{maxNft}</td>
          <td>{price}</td>
          <td>
            <div className={styles.type}>
              <AppContentTypeLabel type={type} />
            </div>
          </td>
          <td>
            {!isMinted && currentStage === stageName && (
              <div className={styles.buttons}>
                {isNewOption(id) ? (
                  <IconTrash className={styles.icon} onClick={() => onOptionDelete(id)} />
                ) : (
                  <Tooltip disabled={canEditOption} label={signCreateTooltip}>
                    <div>
                      <Switch
                        disabled={!canEditOption}
                        checked={!isDisabled}
                        onChange={() => onOptionToggle(id)}
                      />
                    </div>
                  </Tooltip>
                )}
                <Tooltip disabled={canEditOption || isNewOption(id)} label={signCreateTooltip}>
                  <div>
                    <AppIconButton
                      icon={IconEdit}
                      disabled={(!isNewOption(id) && !canEditOption) || isDisabled}
                      variant='icon'
                      className={styles.editButton}
                      onClick={() => onOptionEdit(id)}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </td>
        </tr>
      );
    },
  );

  return (
    <div className={styles.optionList}>
      <div className={styles.header}>
        <div style={{ flex: 1 }}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Options</div>
            <div className={styles.optionCount}>
              {options.length} {'Option' + (options.length === 1 ? '' : 's')}
            </div>
          </div>
          <div className={styles.description}>
            You can create 1 type of NFT under each funding stage with a total of 50,000 tokens. You
            can delete options until they are signed and you can deactivate an option until it is
            minted.
          </div>
        </div>
        <Tooltip disabled={canCreateOption} label={signUpdateTooltip}>
          <div>
            <AppIconButton
              disabled={
                options.filter((option) => option.stageName === currentStage).length >=
                  MAX_OPTIONS || !canCreateOption
              }
              variant='white'
              onClick={onOptionCreate}
              icon={IconPlus}
            >
              Create option
            </AppIconButton>
          </div>
        </Tooltip>
      </div>

      {options.length ? (
        <Table>
          <thead>
            <tr>
              <th>Option</th>
              <th>Stage</th>
              <th>Tokens Amount</th>
              <th>Token Price ($)</th>
              <th>Option content</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <div className={styles.noItems}>
          <NoOptions />
        </div>
      )}
    </div>
  );
}
