import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { IconLogo } from '../../icons';
import styles from './Brand.module.scss';

type Props = {
  color?: 'primary' | 'white' | 'black' | 'gradient';
  className?: string;
};

export function Brand({ color = 'primary', className }: Props) {
  const navigate = useNavigate();

  return (
    <IconLogo
      onClick={() => navigate(AppRoute.Home)}
      className={classNames(styles.brand, styles[color], className)}
    />
  );
}
