import {
  MAX_DESCRIPTION,
  useDescriptionForm,
  useAddDescriptionMutation,
} from '../../../modules/collection';

import { Textarea } from '@mantine/core';
import { useRef } from 'react';
import { AppFormContainer } from '../../../components';
import styles from './AddDescription.module.scss';

type Props = {
  id: string;
  symbol: string;
  onSubmit: () => void;
};

export function AddDescription({ id, symbol, onSubmit }: Props) {
  const [addDescription, { isLoading }] = useAddDescriptionMutation();
  const form = useDescriptionForm();
  const ref = useRef<HTMLTextAreaElement>(null);
  const descriptionLength = ref.current?.value.length;

  const handleSubmit = form.onSubmit(async ({ description }) => {
    await addDescription({ symbol, description }).unwrap();
    onSubmit();
  });

  return (
    <div className={styles.addDescription}>
      <AppFormContainer
        step={5}
        description='Yes, we finally got to the last step - the description of the collection. Tell people why
        you created this collection!'
        title='Add Description'
        onSubmit={handleSubmit}
        formId={id}
        disabled={isLoading}
        className={styles.form}
      >
        <Textarea
          ref={ref}
          label='Collection Description'
          autosize
          placeholder='Enter Collection Description'
          minRows={10}
          maxRows={40}
          maxLength={2500}
          {...form.getInputProps('description')}
        />
        <div className={styles.fieldDescription}>
          <div>With preserved line break</div>
          <div>{`${descriptionLength || 0}/${MAX_DESCRIPTION}`}</div>
        </div>
      </AppFormContainer>
    </div>
  );
}
