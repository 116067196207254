import { AppSpoiler } from '../../../../components';
import { Film } from '../../../../modules/collection';
import dayjs from 'dayjs';
import styles from './FilmOverview.module.scss';
import { resizeImage } from '../../../../utils';

type Props = {
  film: Film;
};

export function FilmOverview({ film }: Props) {
  const {
    title,
    year,
    countries,
    plot,
    genres,
    keywordList,
    poster,
    languages,
    runtimeStr,
    releaseDate,
    directors,
    writers,
    budget,
    stars,
  } = film;

  const dateStr = dayjs(releaseDate).format('MMMM DD, YYYY');

  return (
    <AppSpoiler className={styles.filmOverview}>
      <div className={styles.topContainer}>
        <div className={styles.filmName}>{title}</div>
        <div className={styles.yearAndCountry}>{`${year}, ${countries}`}</div>
        <div className={styles.plot}>{plot}</div>

        <div className={styles.keywords}>
          {keywordList.map((keyword) => (
            <div className={styles.keyword} key={keyword}>
              {keyword}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <img
          loading='lazy'
          className={styles.poster}
          src={resizeImage(poster, 320, 480)}
          alt='poster'
        />
        <div className={styles.fields}>
          <div className={styles.field}>
            <div className={styles.fieldName}>Audio TRACKS</div>
            <div className={styles.fieldValue}>{languages}</div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldName}>RUNTIME</div>
            <div className={styles.fieldValue}>{runtimeStr}</div>
          </div>

          {releaseDate && (
            <div className={styles.field}>
              <div className={styles.fieldName}>Release date</div>
              <div className={styles.fieldValue}>{dateStr}</div>
            </div>
          )}

          <div className={styles.field}>
            <div className={styles.fieldName}>country</div>
            <div className={styles.fieldValue}>{countries}</div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldName}>Genre</div>
            <div className={styles.fieldValue}>{genres}</div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldName}>Director</div>
            <div className={styles.fieldValue}>{directors}</div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldName}>Writer</div>
            <div className={styles.fieldValue}>{writers}</div>
          </div>

          {budget && (
            <div className={styles.field}>
              <div className={styles.fieldName}>Budget</div>
              <div className={styles.fieldValue}>{budget}</div>
            </div>
          )}

          <div className={styles.field}>
            <div className={styles.fieldName}>top cast</div>
            <div className={styles.fieldValue}>{stars}</div>
          </div>
        </div>
      </div>
    </AppSpoiler>
  );
}
