import {
  GRADIENTS,
  IconBackground,
  useLazyFindStreamsQuery,
} from '../../../../../../modules/collection';
import classNames from 'classnames';
import { IconPlay } from '../../../../../../icons';
import { AppPlayerWithPreview } from '../../../../../../components';
import styles from './WatchBonus.module.scss';
import { VideoDto } from '../../../../../../types';

type Props = {
  className?: string;
  video: VideoDto;
  information: {
    title: string;
    author: string;
    brief: string;
  };
};

export function WatchBonus({ className, video, information }: Props) {
  return (
    <div className={classNames(styles.bonus, className)}>
      <div className={styles.header}>
        <div className={styles.iconPlay} style={{ background: GRADIENTS[IconBackground.Lime] }}>
          <IconPlay />
        </div>
        <div className={styles.name}>Watch Movie</div>
      </div>

      <AppPlayerWithPreview video={video} information={information} />
    </div>
  );
}
