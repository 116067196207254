import {
  BonusDto,
  ContentType,
  Icon,
  IconBackground,
  useUploadBonusesMutation,
} from '../../../modules/collection';
import { AppFormContainer } from '../../../components';
import { BonusList } from './bonus-list/BonusList';
import { useListState } from '@mantine/hooks';
import { v4 as uuid } from 'uuid';
import { BonusForm } from './bonus-form/BonusForm';
import { FormEvent, useState } from 'react';
import styles from './AddBonuses.module.scss';

type Props = {
  id: string;
  toggleBonusCreation: () => void;
  isCreation: boolean;
  onSubmit: () => void;
  symbol: string;
};

export function AddBonuses({ id, toggleBonusCreation, isCreation, onSubmit, symbol }: Props) {
  const [bonusToUpdate, setBonusToUpdate] = useState<BonusDto | undefined>();
  const [createBonuses, { isLoading }] = useUploadBonusesMutation();
  const [bonuses, bonusHandlers] = useListState<BonusDto>([
    {
      id: uuid(),
      name: 'Watch Movie',
      description: '',
      icon: Icon.Camera,
      type: ContentType.Watch,
      content: [],
      background: IconBackground.Lime,
      isDefault: true,
      isOptional: false,
      files: [],
    },
    {
      id: uuid(),
      name: 'Download Movie',
      description: '',
      icon: Icon.Download,
      type: ContentType.Download,
      content: [],
      background: IconBackground.LightBlue,
      isDefault: true,
      isOptional: true,
      files: [],
    },
  ]);

  async function handleSubmit(event?: FormEvent) {
    event && event.preventDefault();
    await createBonuses({ bonuses, symbol }).unwrap();
    onSubmit();
  }

  function handleBonusFormSubmit(bonus: Omit<BonusDto, 'id'> & { id?: string }) {
    if (bonus.id) {
      bonusHandlers.applyWhere(
        (item) => item.id === bonus.id,
        (item) => ({ ...item, ...bonus }),
      );
    } else {
      bonusHandlers.append({ id: uuid(), ...bonus });
    }
    toggleBonusCreation();
  }

  function deleteBonus(id: string) {
    bonusHandlers.filter((bonus) => bonus.id !== id);
  }

  function createBonus() {
    setBonusToUpdate(undefined);
    toggleBonusCreation();
  }

  function editBonus(id: string) {
    const bonus = bonuses.find((bonus) => bonus.id === id);
    if (!bonus) return;
    setBonusToUpdate(bonus);
    toggleBonusCreation();
  }

  function toggleBonus(id: string) {
    bonusHandlers.applyWhere(
      (bonus) => bonus.id === id,
      (bonus) => ({ ...bonus, isDisabled: !bonus.isDisabled }),
    );
  }

  return (
    <div className={styles.addBonuses}>
      {isCreation ? (
        <BonusForm bonus={bonusToUpdate} onSubmit={handleBonusFormSubmit} />
      ) : (
        <AppFormContainer
          disabled={isLoading}
          step={4}
          description='Bonuses can be anything, such as playlists, access to social networks, unique props! Remember that the owner of the token gets access to all bonuses at once!'
          title='Create Bonuses'
          onSubmit={handleSubmit}
          formId={id}
          className={styles.bonusesForm}
        >
          <BonusList
            onBonusEdit={editBonus}
            onBonusDelete={deleteBonus}
            onBonusToggle={toggleBonus}
            onBonusCreate={createBonus}
            bonuses={bonuses}
          />
        </AppFormContainer>
      )}
    </div>
  );
}
