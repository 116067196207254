import { CollectionPreview } from '../../modules/collection';
import { AppCollectionStatistics } from '../app-collection-statistics/AppCollectionStatistics';
import { AppCreatorLabel } from '../app-creator-label/AppCreatorLabel';
import { AppBadge } from '../app-badge/AppBadge';
import { formatCurrency, getCompactNumber } from '../../utils';
import classNames from 'classnames';
import draft from '../../images/draft.png';

import { AppRoute } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '../app-button/AppButton';
import styles from './AppCollectionPreview.module.scss';
import { IconImage2 } from '../../icons';
import { OptimizedImage } from '../optimized-image/OptimizedImage';
import { selectUser, WalletType } from '../../modules/auth';
import { SUPPORTED_NETWORK } from '../../ethers/ethersConstants';
import { useSelector } from 'react-redux';

type Props = {
  collection: CollectionPreview;
  type?: 'card' | 'hero';
  className?: string;
  posterAsBackground?: boolean;
};

export function AppCollectionPreview({
  collection,
  type = 'card',
  className,
  posterAsBackground = false,
}: Props) {
  const user = useSelector(selectUser);
  const {
    name,
    background,
    nftImage,
    bonusesCount,
    nftCount,
    maxNft,
    symbol,
    type: collectionType,
    isPublished,
    priceWeb3,
    price,
    user: { username, address, id, avatar },
  } = collection;
  const navigate = useNavigate();
  const backgroundDimensions: [number, number] = posterAsBackground ? [320, 480] : [1280, 720];

  const showWeb3Price = user?.type === WalletType.METAMASK;
  const tokenPrice = showWeb3Price
    ? `${priceWeb3.toFixed(2)} ${SUPPORTED_NETWORK.currency}`
    : formatCurrency(price);

  function handleClick() {
    navigate(
      `${AppRoute.Collection}/${collectionType}/${symbol.toLowerCase()}${
        isPublished ? '' : '/form'
      }`,
    );
  }

  const stats = [
    { name: 'Collected', value: `${nftCount}/${getCompactNumber(maxNft)}` },
    { name: collectionType === 'fan' ? 'Bonuses' : 'Options', value: bonusesCount },
    { name: 'Price', value: tokenPrice },
  ];

  return (
    <div
      className={classNames(
        styles.appCollectionPreview,
        {
          [styles.hero]: type === 'hero',
          [styles.draft]: !isPublished,
          [styles.noBackground]: !background,
        },
        className,
      )}
      onClick={handleClick}
    >
      {background ? (
        <OptimizedImage
          className={styles.image}
          src={background}
          alt={name}
          dimensions={backgroundDimensions}
        />
      ) : (
        <img className={styles.image} src={draft} alt={name} />
      )}

      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.nftContainer}>
            <div className={styles.nftImageContainer}>
              {nftImage ? <img src={nftImage} alt='Nft Image' /> : <IconImage2 />}
            </div>
            <AppBadge className={styles.badge} type={isPublished ? collectionType : 'draft'} />
          </div>
          <div className={styles.name}>{name}</div>
          <AppCreatorLabel className={styles.creator} username={username} address={address} avatar={avatar} id={id} />
        </div>
        <div className={styles.bottom}>
          {isPublished ? (
            <AppCollectionStatistics
              className={styles.stats}
              items={stats}
              withSeparator={type === 'hero'}
            />
          ) : (
            <AppButton variant='white' radius='round' className={styles.finishButton}>
              Finish Publishing
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
}
