import { AppTabs } from '../../../../components';
import { FundingCollectionDetails } from '../funding-collection-details/FundingCollectionDetails';
import { FundingCollectionDto } from '../../../../modules/collection';
import { useState } from 'react';
import { FundingCollectionRoadmap } from '../funding-collection-roadmap/FundingCollectionRoadmap';

type Props = {
  collection: FundingCollectionDto;
};

export function FundingCollectionTabs({ collection }: Props) {
  const [currentTab, setCurrentTab] = useState<string | null>(() => 'collection-details');

  const tabs = [
    {
      tabKey: 'collection-details',
      tabLabel: 'Collection Details',
      tabContent: <FundingCollectionDetails collection={collection} />,
    },
  ];

  if (collection.activeStage?.tasks.length) {
    tabs.push({
      tabKey: 'roadmap',
      tabLabel: 'Roadmap',
      tabContent: <FundingCollectionRoadmap collection={collection} />,
    });
  }

  return <AppTabs tabs={tabs} activeTab={currentTab} onTabChange={setCurrentTab} />;
}
