import styles from './OverlappingCards.module.scss';
import { AppTokenImage } from '../../../../components';
import classNames from 'classnames';
import { OptimizedImage } from '../../../../components/optimized-image/OptimizedImage';

type Props = {
  poster: string;
  nft: string;
  symbol: string;
  className?: string;
};

export function OverlappingCards({ poster, nft, symbol, className }: Props) {
  return (
    <div className={classNames(styles.overlappingCards, className)}>
      <input type='radio' name='cards' id={styles['poster']} defaultChecked />
      <input type='radio' name='cards' id={styles['nft']} />

      <div className={styles.selector}>
        <label htmlFor={styles['poster']} className={styles.poster}>
          <OptimizedImage src={poster} alt='poster' dimensions={[320, 480]} />
        </label>

        <label htmlFor={styles['nft']} className={styles.nft}>
          <AppTokenImage src={nft} symbol={symbol} />
        </label>
      </div>
    </div>
  );
}
