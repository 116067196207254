import { ReactNode } from 'react';
import { UnstyledButton, Loader } from '@mantine/core';

import styles from './AppButton.module.scss';
import classNames from 'classnames';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  variant?: 'primary' | 'white' | 'outline' | 'dark' | 'dark2';
  type?: 'button' | 'submit' | 'reset';
  radius?: 'default' | 'round';
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  form?: string;
  inactive?: boolean;
};

export function AppButton({
  inactive,
  onClick,
  children,
  variant = 'primary',
  radius = 'default',
  isLoading,
  className,
  disabled,
  ...props
}: Props) {
  return (
    <UnstyledButton
      className={classNames(
        styles.appButton,
        { [styles.disabled]: disabled },
        { [styles.inactive]: inactive },
        styles[variant],
        className,
        styles[radius],
      )}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {isLoading ? <Loader color={variant == 'white' ? 'black' : 'white'} size='xs' /> : children}
    </UnstyledButton>
  );
}
