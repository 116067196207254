import { IconPicker } from '../icon-picker/IconPicker';
import { Input, Select, TextInput } from '@mantine/core';
import { AppFileUploader, AppFormContainer, AppTextarea } from '../../../../components';
import { GB2 } from '../../../../constants';
import { BonusDto, ContentType, useBonusForm } from '../../../../modules/collection';
import { BonusLinks } from './bonus-links/BonusLinks';
import styles from './BonusForm.module.scss';
import { S3Folder, FileType } from '../../../../enums';
import { mapFileObj } from '../../../../utils';

type SelectOptions = ContentType.Link | ContentType.Text | ContentType.File | ContentType.None;

type Props = {
  bonus?: BonusDto;
  onSubmit: (bonus: Omit<BonusDto, 'id'> & { id?: string }) => void;
};

export function BonusForm({ onSubmit, bonus }: Props) {
  const form = useBonusForm(bonus);
  const input = form.getInputProps(
    form.getInputProps('type').value === ContentType.File ? 'files' : 'content',
  );

  const handleSubmit = form.onSubmit((values) => {
    onSubmit(values);
  });

  const selectData = [
    { value: ContentType.Text, label: 'Text' },
    { value: ContentType.Link, label: 'Links' },
    { value: ContentType.File, label: 'Files' },
    { value: ContentType.None, label: 'None' },
  ];

  return (
    <AppFormContainer formId='BonusForm' onSubmit={handleSubmit} className={styles.bonusForm}>
      <Input.Wrapper className={styles.withMargin} label='Bonus Icon'>
        <IconPicker
          icon={form.values.icon}
          background={form.values.background}
          onChange={(values) => form.setValues({ ...values })}
        />
      </Input.Wrapper>

      <TextInput
        className={styles.withMargin}
        label='Name'
        placeholder='E.g. "Music Playlist"'
        {...form.getInputProps('name')}
      />

      <AppTextarea
        className={styles.withMargin}
        label='Description'
        placeholder='Enter bonus description'
        minRows={3}
        maxRows={6}
        maxLength={500}
        {...form.getInputProps('description')}
      />

      <Input.Wrapper className={styles.withMargin} label='Bonus Content' {...input}>
        <div className={styles.contentDescription}>
          Visible for token holders. Select what type of content you want to share for holders —
          files, links or text.
        </div>
        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            data={selectData}
            value={form.values.type}
            onChange={(value: SelectOptions) => {
              form.clearFieldError('content');
              form.clearFieldError('files');
              form.values.content.map((value, index) => form.clearFieldError(`content.${index}`));
              form.setFieldValue('type', value);
              form.setFieldValue('content', ['']);
              form.setFieldValue('files', []);
            }}
          />
          {
            {
              [ContentType.File]: (
                <AppFileUploader
                  maxSize={GB2}
                  onChange={(files) => form.setFieldValue('files', files.map(mapFileObj))}
                  label='Up to 3 files of any extension (up to 2Gb each)'
                  accept='*'
                  maxFiles={3}
                  multiple
                  folder={S3Folder.Collection}
                  type={FileType.File}
                  initialValue={bonus?.files}
                />
              ),
              [ContentType.Link]: <BonusLinks form={form} />,
              [ContentType.Text]: (
                <AppTextarea
                  maxLength={500}
                  minRows={3}
                  maxRows={6}
                  placeholder='Enter text'
                  {...form.getInputProps(`content.${0}`)}
                />
              ),
              [ContentType.None]: <div className={styles.noneDescription}>No additional content</div>,
              [ContentType.Watch]: <></>,
              [ContentType.Download]: <></>,
            }[form.values.type]
          }
        </div>
      </Input.Wrapper>
    </AppFormContainer>
  );
}
