import {
  FundingCollectionFormTab,
  StageName,
  useFindFundingCollectionFormQuery,
  usePublishFundingCollectionMutation,
} from '../../modules/collection';
import {
  AppButton,
  AppTabsVertical,
  DropConfigForm,
  GoalForm,
  OptionsForm,
  StoryForm,
  VideosAndPhotosForm,
} from '../../components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { Loading } from '../loading/Loading';
import { useToggle } from '@mantine/hooks';
import { socket } from '../../socket';
import { useAppSelector } from '../../hooks';
import { selectIsLoading } from '../../api';
import styles from './FundingCollectionPreProductionForm.module.scss';
import { AppTab } from '../../types';
import { RoadmapForm } from '../../components/roadmap-form/RoadmapForm';

export function FundingCollectionPreProductionForm() {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const { data: collectionForm, isError, refetch } = useFindFundingCollectionFormQuery({ symbol });
  const isDeploying = collectionForm && !collectionForm.address;
  const [publish, { isLoading: isPublishing }] = usePublishFundingCollectionMutation();
  const navigate = useNavigate();
  const [isOptionCreationForm, toggleOptionCreationForm] = useToggle();
  const isCollectionFinished =
    collectionForm &&
    collectionForm.pitchVideo &&
    collectionForm.activeStage?.goal &&
    collectionForm.activeStage?.percentage &&
    collectionForm.activeStage?.tasks.length &&
    collectionForm.areOptionsSigned &&
    collectionForm.story;

  useEffect(() => {
    if (isError || (collectionForm && collectionForm.isPublished)) {
      navigate(AppRoute.NotFound);
    }
    if (collectionForm) {
      if (isCollectionFinished) {
        publish({ symbol })
          .unwrap()
          .then(() => {
            navigate(`${AppRoute.Collection}/funding/${symbol.toLowerCase()}`);
          });
      }

      const defaultTab = tabs.find(isClickableTab)?.tabKey || null;
      setCurrentTab(defaultTab);
    }
  }, [isError, collectionForm]);

  useEffect(() => {
    socket.on('collectionDeployed', (collectionSymbol) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    socket.on('optionsSet', (collectionSymbol) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    return () => {
      socket.off('optionsSet');
      socket.off('collectionDeployed');
    };
  }, [socket]);

  const tabs: AppTab[] = [
    {
      tabKey: FundingCollectionFormTab.SmartContract,
      tabLabel: 'Smart Contract',
      tabContent: <></>,
      isDone: true,
    },
    {
      tabKey: FundingCollectionFormTab.Story,
      tabLabel: 'Add Story',
      tabContent: (
        <StoryForm
          id={FundingCollectionFormTab.Story}
          symbol={symbol}
          story={collectionForm?.story}
          onSubmit={refetch}
          step={2}
        />
      ),
      isDone: !!collectionForm?.story,
    },
    {
      tabKey: FundingCollectionFormTab.VideosPhotos,
      tabLabel: 'Videos & Photos',
      tabContent: (
        <VideosAndPhotosForm
          id={FundingCollectionFormTab.VideosPhotos}
          onSubmit={refetch}
          symbol={symbol.toUpperCase()}
          videos={collectionForm?.videos}
          images={collectionForm?.images}
          pitchVideo={collectionForm?.pitchVideo}
          coverImage={collectionForm?.coverImage}
          step={3}
        />
      ),
      isDone: !!collectionForm?.pitchVideo,
    },
    {
      tabKey: FundingCollectionFormTab.Goal,
      tabLabel: 'Money & Rights',
      tabContent: (
        <GoalForm
          id={FundingCollectionFormTab.Goal}
          symbol={symbol}
          goal={collectionForm?.goal}
          onSubmit={refetch}
          step={4}
        />
      ),
      isDone: !!(collectionForm?.activeStage?.goal && collectionForm.activeStage?.percentage),
    },
    {
      tabKey: FundingCollectionFormTab.Roadmap,
      tabLabel: 'Roadmap',
      tabContent: (
        <RoadmapForm
          id={FundingCollectionFormTab.Roadmap}
          symbol={symbol}
          onSubmit={refetch}
          step={5}
        />
      ),
      isDone: !!collectionForm?.activeStage?.tasks?.length,
    },
    {
      tabKey: FundingCollectionFormTab.DropConfiguration,
      tabLabel: 'Drop Configuration',
      tabContent: (
        <DropConfigForm
          id={FundingCollectionFormTab.DropConfiguration}
          symbol={symbol}
          waitlistDiscount={collectionForm?.waitlistDiscount}
          waitlistSize={collectionForm?.waitlistSize}
          onSubmit={refetch}
          step={5}
        />
      ),
      isDone: !!collectionForm?.activeStage?.waitlistSize,
    },
    {
      tabKey: FundingCollectionFormTab.Options,
      tabLabel: 'Add Options',
      tabContent: (
        <OptionsForm
          id={FundingCollectionFormTab.Options}
          symbol={symbol.toUpperCase()}
          options={collectionForm?.options}
          onSubmit={refetch}
          step={6}
          currentStage={StageName.PRE_PRODUCTION}
          collectionAddress={collectionForm?.address}
          toggleOptionCreation={toggleOptionCreationForm}
          isCreation={isOptionCreationForm}
          areOptionsSigned={
            !!collectionForm?.areOptionsSigned ||
            !!(collectionForm && !collectionForm.options.length)
          }
          goal={collectionForm?.activeStage?.goal || 0}
        />
      ),
      isDisabled: !collectionForm?.activeStage?.goal || !collectionForm?.activeStage?.waitlistSize,
      isDone:
        collectionForm && !!collectionForm.options.length && !!collectionForm.areOptionsSigned,
    },
  ];

  function findCurrentTabIndex(): number {
    return tabs.findIndex((tab) => tab.tabKey === currentTab);
  }
  function goBack(): void {
    setCurrentTab(prevTabs[prevTabs.length - 1].tabKey);
  }

  function goForward(): void {
    setCurrentTab(nextTabs[0].tabKey);
  }

  const isClickableTab = (tab: AppTab) => !tab.isDisabled && !tab.isDone;

  const [currentTab, setCurrentTab] = useState<string | null>(() => null);
  const prevTabs = tabs.slice(0, findCurrentTabIndex()).filter(isClickableTab);
  const nextTabs = tabs.slice(findCurrentTabIndex() + 1, tabs.length).filter(isClickableTab);
  const optionsTab = tabs.find((tab) => tab.tabKey === FundingCollectionFormTab.Options);

  const isFinalForm = !prevTabs.length && !nextTabs.length && optionsTab && !optionsTab.isDisabled;
  const submitButtonLabel =
    isOptionCreationForm && currentTab === FundingCollectionFormTab.Options
      ? 'Save option'
      : isFinalForm
      ? 'Publish collection'
      : 'Save';
  const secondaryButtonLabel =
    isOptionCreationForm && currentTab === FundingCollectionFormTab.Options
      ? 'Cancel'
      : 'Previous Step';
  const formId =
    isOptionCreationForm && currentTab === FundingCollectionFormTab.Options
      ? 'OptionForm'
      : currentTab || undefined;
  const isSecondaryButtonShown = isOptionCreationForm || (!!prevTabs.length && currentTab);
  const isNextButtonShown = !isOptionCreationForm && !!nextTabs.length && currentTab;
  const areButtonsDisabled = useAppSelector(selectIsLoading);
  const isSubmitDisabled =
    areButtonsDisabled ||
    (currentTab === FundingCollectionFormTab.Options &&
      !isOptionCreationForm &&
      !!collectionForm?.options.length &&
      !collectionForm?.areOptionsSigned);

  return collectionForm && !isPublishing && collectionForm.address ? (
    <div className={styles.collectionForm}>
      <div className={styles.header}>
        <div className={styles.title}>
          {isOptionCreationForm ? 'Option Form' : collectionForm?.name}
        </div>
        <div className={styles.buttons}>
          {isSecondaryButtonShown && (
            <AppButton
              className={styles.button}
              disabled={areButtonsDisabled}
              onClick={isOptionCreationForm ? toggleOptionCreationForm : goBack}
              variant='outline'
            >
              {secondaryButtonLabel}
            </AppButton>
          )}
          {isNextButtonShown && (
            <AppButton
              disabled={areButtonsDisabled}
              className={styles.button}
              onClick={goForward}
              variant='outline'
            >
              Next Step
            </AppButton>
          )}
          <AppButton
            className={styles.button}
            disabled={isSubmitDisabled}
            form={formId}
            type='submit'
            variant='primary'
          >
            {submitButtonLabel}
          </AppButton>
        </div>
      </div>

      <AppTabsVertical
        activeTab={currentTab}
        onTabChange={setCurrentTab}
        tabs={tabs}
        variant='stepper'
      />
    </div>
  ) : (
    <Loading
      text={isDeploying ? 'Wait a bit, we are writing your smart contract' : undefined}
      withLogo={isDeploying}
    />
  );
}
