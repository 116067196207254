import styles from './GoalCalculator.module.scss';
import { OptionDto } from '../../../modules/collection';
import classNames from 'classnames';
import { formatCurrency } from '../../../utils';

type Props = {
  goal: number;
  options: OptionDto[];
  className?: string;
};

export function GoalCalculator({ goal, options, className }: Props) {
  const potentialgoal = options.reduce<number>((acc, curr) => {
    return acc + curr.price * curr.maxNft;
  }, 0);
  const isGoalAchieved = potentialgoal >= goal;

  const emptyInfo = 'Add at least 1 option to calculate your potential earnings.';
  const insufficientInfo = (
    <div>
      <div>Hmm, so far your goal is unattainable. You can do one of the following:</div>

      <div>— Try to add more options;</div>
      <div>— Increase tokens amount;</div>
      <div>— Or increase option price.</div>
    </div>
  );
  const successInfo = 'Great! You can achieve the goal!';

  return (
    <div className={classNames(styles.goalCalculator, className)}>
      <div className={styles.upperBlock}>
        <div className={styles.title}>Defined Goal</div>
        <div className={styles.value}>{formatCurrency(goal)}</div>
      </div>
      <div className={styles.lowerBlock}>
        <div className={styles.title}>Potential Earning</div>
        <div
          className={classNames(styles.value, {
            [styles.green]: isGoalAchieved,
            [styles.red]: options.length && !isGoalAchieved,
          })}
        >
          {formatCurrency(potentialgoal)}
        </div>
        <div className={styles.info}>
          {options.length ? (isGoalAchieved ? successInfo : insufficientInfo) : emptyInfo}
        </div>
      </div>
    </div>
  );
}
