import { ContextModalProps } from '@mantine/modals';
import { Loading } from '../../pages';

export function SpinnerModal({
  context,
  id,
  innerProps: { text, withLogo },
}: ContextModalProps<{
  text?: string;
  withLogo?: boolean;
}>) {
  return <Loading color='white' text={text} withLogo={withLogo} />;
}
