import { createAsyncThunk, isRejected } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { WalletType } from '../auth';
import {
  mintToken as mintTokenWithMetamask,
  mintFundingToken as mintFundingTokenWithMetamask,
} from '../../ethers';
import { tokenApi } from './tokenApi';

export const mintToken = createAsyncThunk<
  void,
  {
    to: string;
    quantity: number;
    collectionAddress: string;
    priceWeb3: number;
  },
  { state: RootState }
>('token/mintToken', async (params, { rejectWithValue, getState, dispatch }) => {
  const user = getState().auth.user;

  if (!user) {
    return rejectWithValue('User not found');
  }

  if (user.type === WalletType.METAMASK) {
    const result = await dispatch(mintTokenWithMetamask(params));
    if (isRejected(result)) {
      return rejectWithValue(result.payload);
    }
  } else {
    // const data = await dispatch(
    //   tokenApi.endpoints.payToken.initiate({ symbol: params.symbol }),
    // ).unwrap();
  }
});

export const mintFundingToken = createAsyncThunk<
  void,
  {
    to: string;
    quantity: number;
    collectionAddress: string;
    priceWeb3: number;
    externalIndex: number;
  },
  { state: RootState }
>('token/mintFundingToken', async (params, { rejectWithValue, getState, dispatch }) => {
  const user = getState().auth.user;

  if (!user) {
    return rejectWithValue('User not found');
  }

  if (user.type === WalletType.METAMASK) {
    const result = await dispatch(mintFundingTokenWithMetamask(params));
    if (isRejected(result)) {
      return rejectWithValue(result.payload);
    }
  } else {
    return rejectWithValue('Fiat prices will be available soon');
  }
});
