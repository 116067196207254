import { api } from '../../api';
import {
  CreateFundingTokenDto,
  CreateTokenDto,
  TokenDto,
  TokenProductDto,
  UserTokenPreviewDto,
} from './tokenTypes';
import { CollectionType } from '../collection';

export const tokenApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findToken: builder.query<TokenDto, { id: string; type: CollectionType }>({
      query: ({ id, type }) => ({ url: `token/${type}/${id}` }),
    }),
    findUserTokens: builder.query<UserTokenPreviewDto[], { userId: string }>({
      query: ({ userId }) => ({ url: `token/user/${userId}` }),
    }),
    findFanTokenProduct: builder.query<TokenProductDto, { symbol: string; optionId?: string }>({
      query: ({ symbol, optionId }) => ({
        url: `token/product/${symbol}${optionId ? `/${optionId}` : ''}`,
      }),
    }),
    payToken: builder.mutation<{ url: string | null }, { symbol: string; optionId?: string }>({
      query: (body) => ({
        url: `token/pay`,
        method: 'POST',
        body,
      }),
    }),
    createToken: builder.mutation<void, CreateTokenDto>({
      query: (form) => ({ url: 'token', method: 'POST', body: form }),
    }),
    createFundingToken: builder.mutation<void, CreateFundingTokenDto>({
      query: (form) => ({ url: 'funding-token', method: 'POST', body: form }),
    }),
  }),
});

export const {
  useCreateTokenMutation,
  useCreateFundingTokenMutation,
  useFindUserTokensQuery,
  useFindFanTokenProductQuery,
  usePayTokenMutation,
  useFindTokenQuery,
} = tokenApi;
