import { ReactNode } from 'react';
import { UnstyledButton, Loader } from '@mantine/core';
import classNames from 'classnames';
import { Icon } from '@tabler/icons-react';
import { IconComponent } from '../../modules/collection';
import styles from './AppIconButton.module.scss';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  variant?: 'secondary' | 'white' | 'outline' | 'icon';
  icon: Icon | IconComponent;
  type?: 'button' | 'submit' | 'reset';
  radius?: 'default' | 'round';
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  reversedOrder?: boolean;
  withBorder?: boolean;
};

export function AppIconButton({
  onClick,
  children,
  variant = 'secondary',
  radius = 'default',
  isLoading,
  className,
  icon: Icon,
  reversedOrder,
  withBorder,
  disabled,
  ...props
}: Props) {
  return (
    <UnstyledButton
      className={classNames(
        styles.appIconButton,
        styles[variant],
        styles[radius],
        {
          [styles.disabled]: disabled,
          [styles.reversed]: reversedOrder,
          [styles.border]: withBorder,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {isLoading ? (
        <Loader color='white' size='xs' />
      ) : (
        <>
          <Icon className={styles.icon} />
          {children}
        </>
      )}
    </UnstyledButton>
  );
}
