import { HLSSource } from './hls-source/HlsSource';
import styles from './Player.module.scss';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useToggle } from '@mantine/hooks';
import classNames from 'classnames';
import { IconPlayerPlay, IconPlayerExpand, IconPlayerCollapse } from '../../icons';
import { IconPlayerPause } from '@tabler/icons-react';
import { Volume } from './volume/Volume';
import { getBarPercentage } from './volume';
import { getPercentage } from '../../utils';
import { AppLoader } from '../index';

type Props = {
  src: string;
  information?: {
    title: string;
    author: string;
    brief: string;
  };
  className?: string;
};

let timeout: NodeJS.Timeout;

export function Player({ src, information, className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, toggleIsPlaying] = useToggle();
  const [isFullscreen, toggleIsFullscreen] = useToggle();
  const [videoTime, setVideoTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [areControlsShown, setAreControlsShown] = useState(true);
  const [progress, setProgress] = useState(0);
  const IconToggle = isPlaying ? IconPlayerPause : IconPlayerPlay;
  const IconFullscreen = isFullscreen ? IconPlayerCollapse : IconPlayerExpand;
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.oncanplay = () => {
        setCanPlay(true);
        play();
      };
    }

    document.onfullscreenchange = () => {
      toggleIsFullscreen(!!document.fullscreenElement);
    };

    return () => {
      document.onfullscreenchange = null;
      document.onmousemove = null;
    };
  }, []);

  function formatTime(time: number): string {
    return `${Math.floor(time / 60)}:${('0' + Math.floor(time % 60)).slice(-2)}`;
  }

  async function handleFullscreenClick() {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      await containerRef.current!.requestFullscreen();
    }
  }

  function fastForward() {
    videoRef.current!.currentTime += 5;
  }

  function revert() {
    videoRef.current!.currentTime -= 5;
  }

  function handleProgressClick(e: MouseEvent<HTMLDivElement>) {
    videoRef.current!.currentTime = (videoTime / 100) * getBarPercentage(e);
  }

  function handleVolumeChange(value: number) {
    videoRef.current!.volume = value;
  }

  function handleMouseMove(e: MouseEvent<HTMLDivElement>) {
    clearTimeout(timeout);
    if (!areControlsShown) {
      setAreControlsShown(true);
    }
    timeout = setTimeout(() => setAreControlsShown(false), 2000);
  }

  async function togglePlay() {
    if (canPlay) {
      isPlaying ? pause() : play();
    }
  }

  function pause() {
    videoRef.current!.pause();
    toggleIsPlaying(false);
  }

  function play() {
    videoRef.current!.play().then(() => {
      toggleIsPlaying(true);
      setVideoTime(videoRef.current!.duration);
    });
  }

  return (
    <div
      onMouseMove={handleMouseMove}
      ref={containerRef}
      className={classNames(
        styles.player,
        {
          [styles.hideControls]: !areControlsShown,
          [styles.fullscreen]: isFullscreen,
        },
        className,
      )}
    >
      {information && (
        <div className={styles.information}>
          <div className={styles.title}>{information.title}</div>
          <div className={styles.details}>
            <div className={styles.author}>{information.author}</div>
            <div className={styles.brief}>{information.brief}</div>
          </div>
        </div>
      )}

      <div className={styles.controls}>
        <div className={classNames(styles.button, styles.buttonPlay)} onClick={togglePlay}>
          <IconToggle className={styles.iconToggle} />
        </div>
        <div className={styles.rightBlock}>
          <Volume volume={volume} onChange={handleVolumeChange} />

          {/*<div className={styles.time}>{formatTime(currentTime)}</div>*/}

          <div className={styles.progressContainer}>
            <div style={{ width: `${progress}%` }} className={styles.progress} />
            <div
              onClick={handleProgressClick}
              className={classNames(styles.button, styles.buttonProgress)}
            />
          </div>

          <div className={styles.time}>{formatTime(videoTime)}</div>

          <IconFullscreen
            className={classNames(styles.button, styles.buttonFullScreen)}
            onClick={handleFullscreenClick}
          />
        </div>
      </div>

      <HLSSource videoRef={videoRef} src={src} />
      <video
        onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        onClick={togglePlay}
        className={styles.video}
        ref={videoRef}
        onTimeUpdate={(e) => {
          setCurrentTime(videoRef.current!.currentTime);
          setProgress(getPercentage(videoRef.current!.currentTime, videoTime));
        }}
      />
      {!canPlay && <AppLoader className={styles.loader} />}
    </div>
  );
}
