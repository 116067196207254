import { ReactNode } from 'react';
import { Spoiler } from '@mantine/core';
import styles from './AppSpoiler.module.scss';
import classNames from 'classnames';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
};

export function AppSpoiler({ children, className }: Props) {
  return (
    <Spoiler
      className={classNames(styles.appSpoiler, className)}
      styles={(theme) => ({
        control: {
          position: 'absolute',
          left: '50%',
          top: '100%',
          transform: 'translate(-50%, -50%)',
          background: theme.colors.dark[6],
          padding: '10px 12px 10px 10px',
          borderRadius: 24,
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '24px',
          color: theme.white,
        },
      })}
      maxHeight={200}
      showLabel='Show more'
      hideLabel='Show less'
    >
      {children}
    </Spoiler>
  );
}
