import { useModals } from '@mantine/modals';
import { useMantineTheme } from '@mantine/core';
import { Modal } from '../enums';
import { getBaseSettings } from './getBaseSettings';
import { dropdownModalContext } from './dropdown-modal';
import { accountModalContext } from './account-modal';
import { getConfirmationModalContext } from './confirmation-modal';
import { switchNetworkModalContext } from './switch-network-modal';
import { getContentModalContext } from './content-modal';
import { BonusDto, CollectionType, ContentType } from '../modules/collection';
import { getRteLinkModalContext } from './rte-link-modal';
import { getRteImageModalContext } from './rte-image-modal';
import { getImageModalContext } from './image-modal';
import { getImageUrlParams } from '../utils';
import { getSpinnerModalContext } from './spinner-modal';
import { getPdfModalContext } from './pdf-modal';
import { getGetNotifiedContext } from './get-notified-modal';
import { getTextModalContext } from './text-insert-modal';
import { getAddSubtaskContext } from './add-subtask-modal';

export const useAppModals = () => {
  const modals = useModals();
  const theme = useMantineTheme();

  const openDropdown = () => {
    modals.openContextModal(Modal.Dropdown, {
      ...getBaseSettings(theme),
      ...dropdownModalContext,
    });
  };

  const openSpinner = (innerProps: { text?: string; withLogo?: boolean } = {}) =>
    modals.openContextModal(Modal.Spinner, {
      ...getSpinnerModalContext(innerProps),
    });

  const openAccount = () => {
    modals.openContextModal(Modal.Account, {
      ...getBaseSettings(theme),
      ...accountModalContext,
    });
  };

  const switchNetwork = () =>
    modals.openContextModal(Modal.SwitchNetwork, {
      ...getBaseSettings(theme),
      ...switchNetworkModalContext,
    });

  const openConfirmationModal = ({
    onSuccess,
    title = 'Confirmation',
    message,
    onReject = () => {},
  }: {
    onSuccess: () => void;
    onReject?: () => void;
    title?: string;
    message: string;
  }) => {
    modals.openContextModal(Modal.Confirmation, {
      ...getBaseSettings(theme),
      ...getConfirmationModalContext(onSuccess, onReject, message, title),
    });
  };

  const openContentModal = (innerProps: {
    collectionType: CollectionType;
    contentDescription: string;
    contentId: string;
    contentType: ContentType;
    name: string;
  }) => {
    modals.openContextModal(Modal.BonusContent, {
      ...getBaseSettings(theme),
      ...getContentModalContext(innerProps),
    });
  };

  const openGetNotifiedModal = ({
    onSuccess,
    onReject = () => {},
  }: {
    onSuccess: (link: string) => void;
    onReject?: () => void;
  }) => {
    modals.openContextModal(Modal.GetNotified, {
      ...getBaseSettings(theme),
      ...getGetNotifiedContext(onSuccess, onReject),
    });
  };

  const openTextModal = ({
    onSuccess,
    onReject = () => {},
    title,
  }: {
    onSuccess: (text: string) => void;
    onReject?: () => void;
    title: string;
  }) => {
    modals.openContextModal(Modal.Text, {
      ...getBaseSettings(theme),
      ...getTextModalContext(onSuccess, onReject, title),
    });
  };

  const openAddSubtask = ({
    onSuccess,
    onReject = () => {},
  }: {
    onSuccess: (subtask: { title: string; description: string }) => void;
    onReject?: () => void;
  }) => {
    modals.openContextModal(Modal.Subtask, {
      ...getBaseSettings(theme),
      ...getAddSubtaskContext(onSuccess, onReject),
    });
  };

  const openRteLinkModal = ({
    onSuccess,
    onReject = () => {},
  }: {
    onSuccess: (link: string) => void;
    onReject?: () => void;
  }) => {
    modals.openContextModal(Modal.RteLink, {
      ...getBaseSettings(theme),
      ...getRteLinkModalContext(onSuccess, onReject),
    });
  };

  const openPdfModal = (pdfId: string) => {
    modals.openContextModal(Modal.Pdf, {
      ...getBaseSettings(theme),
      ...getPdfModalContext({ pdfId }),
    });
  };

  const openImageModal = ({
    image,
    height,
    width,
  }: {
    image: string;
    height?: number;
    width?: number;
  }) => {
    const src = `${image}${getImageUrlParams(width || 1920, height)}`;
    const img: HTMLImageElement = new Image();
    img.src = src;
    const id = openSpinner();

    img.onload = function () {
      modals.closeContextModal(id);
      modals.openContextModal(Modal.Image, {
        ...getBaseSettings(theme),
        ...getImageModalContext({ image: img }),
      });
    };
  };

  const openRteImageModal = ({
    onSuccess,
    onReject = () => {},
    symbol,
  }: {
    symbol: string;
    onSuccess: (url: string) => void;
    onReject?: () => void;
  }) => {
    modals.openContextModal(Modal.RteImage, {
      ...getBaseSettings(theme),
      ...getRteImageModalContext(symbol, onSuccess, onReject),
    });
  };

  return {
    closeContextModal: modals.closeContextModal,
    closeModal: modals.closeModal,
    closeAll: modals.closeAll,
    openContentModal,
    openRteLinkModal,
    openRteImageModal,
    openImageModal,
    openSpinner,
    openPdfModal,
    switchNetwork,
    openGetNotifiedModal,
    openTextModal,
    openAddSubtask,
    //

    openDropdown,
    openAccount,
    openConfirmationModal,
  };
};
