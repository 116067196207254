import {
  OptionDto,
  StageName,
  UpdateOptionDto,
  useUpdateFundingCollectionOptionsMutation,
} from '../../modules/collection';
import { AppFormContainer } from '../index';
import { OptionList } from './option-list/OptionList';
import { useListState } from '@mantine/hooks';
import { v4 as uuid } from 'uuid';
import { OptionForm } from './option-form/OptionForm';
import { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { Loading } from '../../pages';
import { setOptions, updateOptions as updateOptionsThunk } from '../../ethers';
import { GoalCalculator } from './goal-calculator/GoalCalculator';
import { useAppModals } from '../../modals';
import _ from 'lodash';
import styles from './OptionsForm.module.scss';

type Props = {
  id: string;
  toggleOptionCreation: () => void;
  isCreation: boolean;
  onSubmit?: () => void;
  symbol: string;
  collectionAddress?: string | null;
  options?: OptionDto[];
  step?: number;
  onChange?: (hasChanges: boolean) => void;
  areOptionsSigned: boolean;
  goal: number;
  currentStage: StageName;
};

export function OptionsForm({
  id,
  toggleOptionCreation,
  isCreation,
  onSubmit,
  onChange,
  symbol,
  collectionAddress,
  areOptionsSigned,
  options = [],
  step,
  goal,
  currentStage,
}: Props) {
  const dispatch = useAppDispatch();
  const modals = useAppModals();
  const [optionToUpdate, setOptionToUpdate] = useState<OptionDto | undefined>();
  const [updateOptions, { isLoading }] = useUpdateFundingCollectionOptionsMutation();
  const [updatedOptions, optionHandlers] = useListState<OptionDto>([]);
  const hasCreatedOptions = updatedOptions.length !== options.length;
  const hasUpdatedOptions =
    !hasCreatedOptions &&
    !updatedOptions.every((updatedOption, index) => {
      const option = options[index];

      return _.isEqual(
        { ...updatedOption, nftImage: updatedOption.nftImage.id },
        { ...option, nftImage: option.nftImage.id },
      );
    });
  const [hasError, setHasError] = useState(false);
  const hasOptionForStage = updatedOptions.some((option) => option.stageName === currentStage);

  useEffect(() => {
    optionHandlers.setState(options);
  }, [options]);

  useEffect(() => {
    onChange?.(hasUpdatedOptions || hasCreatedOptions);
  }, [updatedOptions]);

  async function handleSubmit(event?: FormEvent) {
    event && event.preventDefault();
    setHasError(!hasOptionForStage);

    if (!collectionAddress || hasError || !hasOptionForStage) {
      return;
    }

    const mappedOptions = updatedOptions.map((option) => ({
      ...option,
      id: option.id.length === 24 ? option.id : undefined,
    }));

    const action = hasCreatedOptions
      ? setOptions({ collectionAddress, options: mappedOptions.filter((option) => !option.id) })
      : updateOptionsThunk({ collectionAddress, options: mappedOptions });

    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
    dispatch(action)
      .unwrap()
      .then(() => {
        updateOptions({ options: mappedOptions, symbol })
          .unwrap()
          .then(() => {
            onSubmit?.();
          });
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  function handleOptionFormSubmit(option: UpdateOptionDto) {
    if (option.id) {
      optionHandlers.applyWhere(
        (item) => item.id === option.id,
        (item) => ({ ...item, ...option }),
      );
    } else {
      optionHandlers.append({ id: uuid(), ...option, externalIndex: 0 });
    }
    toggleOptionCreation();
    setHasError(false);
  }

  function deleteOption(id: string) {
    optionHandlers.filter((bonus) => bonus.id !== id);
  }

  function toggleOption(id: string) {
    optionHandlers.applyWhere(
      (option) => option.id === id,
      (option) => ({ ...option, isDisabled: !option.isDisabled }),
    );
  }

  function createOption() {
    setOptionToUpdate(undefined);
    toggleOptionCreation();
  }

  function editOption(id: string) {
    const option = updatedOptions.find((bonus) => bonus.id === id);
    if (!option) return;
    setOptionToUpdate(option);
    toggleOptionCreation();
  }

  return areOptionsSigned ? (
    <div className={styles.optionsForm}>
      <div className={styles.container}>
        {isCreation ? (
          <OptionForm
            symbol={symbol}
            currentStage={currentStage}
            option={optionToUpdate}
            onSubmit={handleOptionFormSubmit}
          />
        ) : (
          <div>
            <AppFormContainer
              disabled={isLoading}
              step={step}
              description='Craft an NFT tailored to this financing stage. Detail the unique advantages for holders and specify the maximum quantity of these tokens available'
              title='Create NFT'
              onSubmit={handleSubmit}
              formId={id}
              className={styles.optionsForm}
            >
              <OptionList
                currentStage={currentStage}
                onOptionEdit={editOption}
                onOptionDelete={deleteOption}
                onOptionToggle={toggleOption}
                onOptionCreate={createOption}
                options={updatedOptions}
                canEditOption={!hasCreatedOptions}
                canCreateOption={!hasUpdatedOptions}
              />
            </AppFormContainer>
            {hasError && <div style={{ color: '#e03131' }}>Create an option for current stage</div>}
          </div>
        )}

        {!isCreation && (
          <GoalCalculator className={styles.goalBlock} goal={goal} options={updatedOptions} />
        )}
      </div>
    </div>
  ) : (
    <Loading
      className={styles.loader}
      text='Wait a bit, we are writing your options to blockchain'
      withLogo
    />
  );
}
