import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import { AppFileInput } from '../app-file-input/AppFileInput';
import { v4 as uuid } from 'uuid';

import { FileObject, UploaderApi } from './types';
import { FileView } from './file-view/FileView';
import {
  FileDto,
  useCompleteMultiUploadMutation,
  useGeneratePresignedUrlsPartsMutation,
  useInitiateMultipartUploadMutation,
} from '../../modules/file';
import Axios from 'axios';
import isEmpty from 'is-empty-typed';
import { S3Folder, FileType } from '../../enums';
import styles from './AppFileUploader.module.scss';

const FILE_CHUNK_SIZE = 6_000_000;

type Props = {
  className?: string;
  maxSize: number;
  onChange: (fileObjects: FileObject[]) => void;
  onError?: (message: string) => void;
  onUploadStart?: () => void;
  label: string;
  accept: string;
  isInputDisplayed?: boolean;
  multiple?: boolean;
  maxFiles?: number;
  folder: S3Folder;
  type: FileType;
  id?: string;
  disabled?: boolean;
  isPublic?: boolean;
  initialValue?: FileDto[];
  fileVisibility?: 'always' | 'uploading' | 'never';
};

export const AppFileUploader = forwardRef<UploaderApi, Props>(
  (
    {
      className,
      maxSize,
      label,
      accept,
      multiple = false,
      onUploadStart,
      onChange,
      maxFiles,
      folder,
      type,
      onError,
      disabled,
      isInputDisplayed = true,
      fileVisibility = 'always',
      id,
      isPublic = false,
      initialValue = [],
    }: Props,
    ref,
  ) => {
    const inputRef = useRef<{ openFileSystem: () => void }>(null);
    useImperativeHandle(ref, () => ({
      deleteById,
      openFileSystem: () => {
        if (inputRef.current) {
          inputRef.current.openFileSystem();
        }
      },
    }));

    const [initiateMultipartUploadMutation] = useInitiateMultipartUploadMutation();
    const [generatePresignedUrlsPartsMutation] = useGeneratePresignedUrlsPartsMutation();
    const [completeMultiUploadMutation] = useCompleteMultiUploadMutation();

    const [fileObjects, setFileObjects] = useState<FileObject[]>(
      initialValue.map(({ id, size, name, mimeType }) => ({
        id,
        isUploaded: true,
        size,
        progress: 100,
        fileId: id,
        name,
        mimeType,
        isPublic,
        type,
      })),
    );

    const showInput =
      isEmpty(fileObjects) ||
      (multiple && !maxFiles) ||
      (maxFiles && maxFiles > fileObjects.length);

    useEffect(() => {
      const isComplete = fileObjects.every(({ isUploaded, error }) => isUploaded || error);

      if (isComplete) {
        const uploadedFileObjects = fileObjects.reduce<FileObject[]>((acc, curr) => {
          if (!curr.error) {
            acc.push({ ...curr });
          }

          return acc;
        }, []);

        onChange(uploadedFileObjects);
      }
    }, [fileObjects]);

    function uploadFile(fileObject: FileObject) {
      const { file } = fileObject;

      if (!file) return;

      const { size, name, type: mimeType } = file;

      if (size > maxSize) {
        setFileObjectData(fileObject.id, { error: 'File is too big' });
        return;
      }

      const parts: number = Math.ceil(file.size / FILE_CHUNK_SIZE);

      initiateMultipartUploadMutation({
        originalName: name,
        mimeType,
        folder,
        type,
        id,
      })
        .unwrap()
        .then(({ uploadId, key, bucket }) => {
          if (!uploadId || !key) return;

          generatePresignedUrlsPartsMutation({ parts, uploadId, key, bucket })
            .unwrap()
            .then((urls) => {
              uploadParts(fileObject, urls).then((parts) => {
                if (parts) {
                  completeMultiUploadMutation({
                    uploadId,
                    parts,
                    key,
                    bucket,
                    size,
                    name,
                    mimeType,
                    type,
                    isPublic,
                  })
                    .unwrap()
                    .then(({ id }) => {
                      completeUpload(fileObject.id, id);
                    });
                }
              });
            });
        });
    }

    async function uploadParts(
      fileObject: FileObject,
      urls: Record<number, string>,
    ): Promise<{ ETag: any; PartNumber: number }[] | undefined> {
      const { file, controller, id } = fileObject;

      if (!file) return;

      const axios = Axios.create();
      delete axios.defaults.headers.put['Content-Type'];

      const keys = Object.keys(urls);
      const promises = [];

      for (const indexStr of keys) {
        const index = parseInt(indexStr);
        const start = index * FILE_CHUNK_SIZE;
        const end = (index + 1) * FILE_CHUNK_SIZE;
        const blob = index < keys.length ? file.slice(start, end) : file.slice(start);
        const progress = Math.trunc(((index + 1) / keys.length) * 100);

        promises.push(await axios.put(urls[index], blob, { signal: controller?.signal }));
        updateFileProgress(id, progress);
      }

      return promises.map((part, index) => ({
        ETag: (part as any).headers.etag,
        PartNumber: index + 1,
      }));
    }

    function updateFileProgress(fileObjectId: string, progress: number) {
      setFileObjectData(fileObjectId, { progress });
    }

    function completeUpload(fileObjectId: string, fileId: string) {
      setFileObjectData(fileObjectId, { id: fileId, isUploaded: true });
    }

    function setFileObjectData(fileObjectId: string, data: Partial<FileObject>) {
      setFileObjects((prev) =>
        prev.map((fileObject) => {
          if (fileObject.id === fileObjectId) {
            return { ...fileObject, ...data };
          }
          return fileObject;
        }),
      );
    }

    function handleInputChange(files: File[]) {
      const mappedFiles: FileObject[] = files.map((file) => ({
        file,
        id: uuid(),
        size: file.size,
        isUploaded: false,
        progress: null,
        name: file.name,
        mimeType: file.type,
        controller: new AbortController(),
        isPublic,
        type,
      }));

      setFileObjects((prev) =>
        multiple ? [...prev, ...mappedFiles].slice(0, maxFiles) : mappedFiles,
      );
      onUploadStart?.();
      mappedFiles.forEach(uploadFile);
    }

    function deleteById(id: string) {
      const file = fileObjects.find((obj) => obj.id === id);

      if (file) {
        deleteFile(file);
      }
    }

    function deleteFile(fileObject: FileObject) {
      setFileObjects((prev) => prev.filter((value) => value.id !== fileObject.id));
      fileObject.controller?.abort();
    }

    return (
      <div className={classNames(styles.appFileUploader, className)}>
        {!isEmpty(fileObjects) && (fileVisibility === 'always' || fileVisibility === 'uploading') && (
          <div className={styles.files}>
            {fileObjects
              .filter((file) => (fileVisibility === 'uploading' ? file.progress !== 100 : true))
              .map((fileObject) => (
                <FileView key={fileObject.id} fileObject={fileObject} onDeleteClick={deleteFile} />
              ))}
          </div>
        )}

        <AppFileInput
          ref={inputRef}
          disabled={disabled}
          className={classNames(styles.input, {
            [styles.inputHidden]: !isInputDisplayed || !showInput,
          })}
          accept={accept}
          label={label}
          multiple={multiple}
          onChange={handleInputChange}
        />
      </div>
    );
  },
);
