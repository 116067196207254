import styles from './IconPicker.module.scss';
import { AppButton } from '../../../../components';
import { Popover } from '@mantine/core';
import { useState } from 'react';
import classNames from 'classnames';
import { GRADIENTS, Icon, IconBackground, ICONS } from '../../../../modules/collection';
import { IconPreview } from '../icon-preview/IconPreview';
import { IconCheck } from '../../../../icons';

type Props = {
  icon: Icon;
  background: IconBackground;
  onChange: (value: { icon: Icon; background: IconBackground }) => void;
};

export function IconPicker({ icon, background, onChange }: Props) {
  const [opened, setOpened] = useState(false);
  const [tempIcon, setTempIcon] = useState(icon);
  const [tempBackground, setTempBackground] = useState(background);

  function apply() {
    onChange({ icon: tempIcon, background: tempBackground });
    setOpened(false);
  }

  return (
    <div className={styles.iconPicker}>
      <IconPreview icon={icon} background={background} />
      <Popover position='right' shadow='md' opened={opened} onChange={setOpened}>
        <Popover.Target>
          <div>
            <AppButton variant='dark' onClick={() => setOpened((o) => !o)}>
              Change
            </AppButton>
          </div>
        </Popover.Target>
        <Popover.Dropdown className={styles.dropdown}>
          <div className={styles.header}>
            <div className={styles.label}>Select Icon</div>
          </div>
          <div className={styles.icons}>
            {Object.entries(ICONS).map(([key, Icon]) => (
              <div
                key={key}
                className={classNames(styles.iconContainer, {
                  [styles.activeIcon]: key === tempIcon,
                })}
                onClick={() => setTempIcon(key as Icon)}
              >
                <Icon className={styles.icon} />
              </div>
            ))}
          </div>
          <div className={styles.colorsLabel}>Color</div>
          <div className={styles.colors}>
            {Object.entries(GRADIENTS).map(([key, gradient]) => (
              <div
                key={key}
                className={styles.color}
                style={{ background: gradient }}
                onClick={() => setTempBackground(key as IconBackground)}
              >
                {key === tempBackground && (
                  <>
                    <div className={styles.colorBorder} />
                    <IconCheck className={styles.iconCheck} />
                  </>
                )}
              </div>
            ))}
          </div>
          <AppButton onClick={apply} variant='white'>
            Apply
          </AppButton>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
