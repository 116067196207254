import React, { RefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { HandlingEventType } from './types';
import styles from './OutsideClickHandler.module.scss';

type Props = {
  children: any;
  onOutsideClick: Function;
  handlingEventType?: HandlingEventType;
  disabled?: boolean;
  useCapture?: boolean;
  className?: string;
};

const OutsideClickHandler = ({
  children,
  onOutsideClick,
  handlingEventType = HandlingEventType.mouseUp,
  disabled = false,
  useCapture = true,
  className,
}: Props) => {
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (!disabled) {
      document.addEventListener(handlingEventType, onMouseUp, { capture: useCapture });

      return () =>
        document.removeEventListener(handlingEventType, onMouseUp, { capture: useCapture });
    }
  }, [disabled]);

  const onMouseUp = (e: MouseEvent) => {
    const isDescendantOfRoot = containerRef.current?.contains(e.target as Node);

    if (!isDescendantOfRoot) {
      onOutsideClick(e);
    }
  };

  return (
    <div className={classnames(styles.outsideClickHandler, className)} ref={containerRef}>
      {children}
    </div>
  );
};

export default OutsideClickHandler;
