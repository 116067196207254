import classNames from 'classnames';
import { IconPlayerVolumeOn } from '../../../icons';
import styles from './Volume.module.scss';
import { useToggle } from '@mantine/hooks';
import { MouseEvent, useState } from 'react';
import { getBarPercentage } from './index';
import OutsideClickHandler from '../../outside-click-handler/OutsideClickHandler';

type Props = {
  volume: number;
  onChange: (value: number) => void;
};

export function Volume({ volume, onChange }: Props) {
  const IconVolume = IconPlayerVolumeOn;
  const [isExpanded, toggleExpanded] = useToggle();
  const barHeight = `${volume * 100}%`;

  function handleBarClick(e: MouseEvent<HTMLDivElement>) {
    const newVolume = getBarPercentage(e, true) / 100;
    onChange(newVolume);
  }

  return (
    <OutsideClickHandler
      className={classNames(styles.volume, { [styles.expanded]: isExpanded })}
      onOutsideClick={() => toggleExpanded(false)}
    >
      {isExpanded && (
        <div className={styles.barContainer}>
          <div style={{ minHeight: barHeight, height: barHeight }} className={styles.bar} />

          <div
            onClick={handleBarClick}
            className={classNames(styles.button, styles.buttonVolume)}
          />
        </div>
      )}
      <IconVolume className={styles.icon} onClick={() => toggleExpanded()} />
    </OutsideClickHandler>
  );
}
