import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './RteLinkModal';

export function getRteLinkModalContext(
  onSuccess: (link: string) => void,
  onReject: () => void,
): OpenContextModal<{
  onSuccess: (link: string) => void;
  onReject: () => void;
}> {
  return {
    title: 'Add link',
    innerProps: {
      onSuccess,
      onReject,
    },
  };
}
