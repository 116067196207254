import {
  AppButton,
  AppTabsVertical,
  BonusesForm,
  DescriptionForm,
  VideosForm,
} from '../../components';
import {
  AddBonusForm,
  CollectionEditTab,
  useFindCollectionFormQuery,
} from '../../modules/collection';
import { Loading } from '../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { selectIsLoading } from '../../api';
import styles from './CollectionEdit.module.scss';
import { AppTab } from '../../types';

export function CollectionEdit() {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const navigate = useNavigate();
  const { data: collectionForm, isError, refetch } = useFindCollectionFormQuery({ symbol });
  const [isBonusCreationForm, toggleBonusCreationForm] = useToggle();
  const areButtonDisabled = useAppSelector(selectIsLoading);
  const [currentTab, setCurrentTab] = useState<string | null>(() => CollectionEditTab.Description);
  const formId =
    isBonusCreationForm && currentTab === CollectionEditTab.Bonuses
      ? 'BonusForm'
      : currentTab || undefined;
  const [hasFormChanges, setHasFormChanges] = useState<Record<string, boolean>>({
    [CollectionEditTab.Bonuses]: false,
    [CollectionEditTab.Description]: false,
    BonusForm: true,
  });

  const tabs: AppTab[] = [
    {
      tabKey: CollectionEditTab.Description,
      tabLabel: 'Description',
      tabContent: (
        <DescriptionForm
          symbol={symbol}
          id={CollectionEditTab.Description}
          description={collectionForm?.description}
          onSubmit={refetch}
          onChange={handleDescriptionChange}
        />
      ),
    },
    {
      tabKey: CollectionEditTab.Bonuses,
      tabLabel: 'Bonuses',
      tabContent: (
        <BonusesForm
          isCreation={isBonusCreationForm}
          toggleBonusCreation={toggleBonusCreationForm}
          id={CollectionEditTab.Bonuses}
          symbol={symbol}
          onSubmit={refetch}
          bonuses={collectionForm?.bonuses || []}
          onChange={handleBonusesChange}
        />
      ),
    },
    {
      tabKey: CollectionEditTab.Videos,
      tabLabel: 'Trailers',
      tabContent: (
        <VideosForm
          id={CollectionEditTab.Videos}
          symbol={symbol}
          onSubmit={refetch}
          videos={collectionForm?.trailers || []}
          onChange={handleTrailersChange}
        />
      ),
    },
  ];

  function handleBonusesChange(bonuses: AddBonusForm[]) {
    setHasFormChanges((prevState) => ({
      ...prevState,
      [CollectionEditTab.Bonuses]:
        JSON.stringify(collectionForm?.bonuses) !== JSON.stringify(bonuses),
    }));
  }

  function handleTrailersChange(trailerIds: string[]) {
    setHasFormChanges((prevState) => ({
      ...prevState,
      [CollectionEditTab.Videos]:
        JSON.stringify(collectionForm?.trailers.map((trailer) => trailer.id)) !==
        JSON.stringify(trailerIds),
    }));
  }

  function handleDescriptionChange(description: string) {
    setHasFormChanges((prevState) => ({
      ...prevState,
      [CollectionEditTab.Description]: description !== collectionForm?.description,
    }));
  }

  function goBack(): void {
    navigate(-1);
  }

  return collectionForm ? (
    <div className={styles.collectionEdit}>
      <div className={styles.header}>
        <div className={styles.title}>{collectionForm?.name}</div>
        <div className={styles.buttons}>
          <AppButton
            className={styles.button}
            onClick={isBonusCreationForm ? toggleBonusCreationForm : goBack}
            variant='outline'
            disabled={areButtonDisabled}
          >
            Cancel
          </AppButton>
          <AppButton
            className={styles.button}
            disabled={areButtonDisabled || (!!formId && !hasFormChanges[formId])}
            form={formId}
            type='submit'
            variant='primary'
          >
            Save
          </AppButton>
        </div>
      </div>

      <AppTabsVertical activeTab={currentTab} onTabChange={setCurrentTab} tabs={tabs} />
    </div>
  ) : (
    <Loading />
  );
}
