import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import { TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { AppButton } from '../../components';
import styles from './RteLinkModal.module.scss';

export const RteLinkModal = ({
  context,
  id,
  innerProps: { onSuccess },
}: ContextModalProps<{
  onSuccess: (link: string) => void;
  onReject: () => void;
}>) => {
  const form = useForm<{ link: string }>({
    validate: zodResolver(
      z.object({
        link: z.string().url(),
      }),
    ),
    initialValues: {
      link: '',
    },
  });

  const handleSubmit = form.onSubmit(({ link }) => {
    onSuccess(link);
    context.closeContextModal(id);
  });

  return (
    <form className={styles.rteLinkModal} onSubmit={handleSubmit}>
      <TextInput
        placeholder='https://indieline.io'
        maxLength={2500}
        {...form.getInputProps('link')}
      />

      <AppButton className={styles.button} type='submit'>
        Insert
      </AppButton>
    </form>
  );
};
