import { useEffect, useRef } from 'react';
import * as StackBlur from 'stackblur-canvas';
import { getImageFromSrc, resizeImage } from '../../../../utils';
import { Collection, FundingCollectionDto } from '../../../../modules/collection';
import styles from './FundingCollectionHero.module.scss';
import { MediaGallery } from '../../../../components/media-gallery/MediaGallery';
import { FundingCollectionMinter } from '../funding-collection-minter/FundingCollectionMinter';

type Props = {
  collection: FundingCollectionDto;
};

export function FundingCollectionHero({ collection }: Props) {
  const { coverImage, name, videos, images, pitchVideo } = collection;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const galleryVideos = [pitchVideo, ...videos];

  useEffect(() => {
    if (coverImage) {
      const ctx = canvasRef.current?.getContext('2d');

      if (ctx) {
        const img = getImageFromSrc(resizeImage(coverImage, 1280, 720));
        img.onload = () => {
          StackBlur.image(img, canvasRef.current!, 100, true);
        };
      }
    }
  }, []);

  return (
    <div className={styles.collectionHero}>
      <canvas ref={canvasRef} id={styles['myFogCanvas']} />
      <div className={styles.gradient} />

      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
        <div className={styles.galleryAndMinter}>
          <MediaGallery className={styles.gallery} videos={galleryVideos} images={images} />
          <FundingCollectionMinter className={styles.minter} collection={collection} />
        </div>
      </div>
    </div>
  );
}
