//@ts-ignore
import Hls from 'hls.js';
import { RefObject, useEffect, useState } from 'react';

type Props = {
  src: any;
  isVideoChild?: boolean;
  videoRef: RefObject<HTMLVideoElement>;
};

export function HLSSource({ src, videoRef }: Props) {
  const [hls] = useState<Hls>(new Hls());

  useEffect(() => {
    if (Hls.isSupported() && videoRef.current) {
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    }

    return () => {
      hls.destroy();
    };
  }, [src]);

  return <source src={src} type='application/x-mpegURL' />;
}
