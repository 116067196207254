import { Textarea } from '@mantine/core';
import { ChangeEvent, useRef } from 'react';
import classNames from 'classnames';
import styles from './AppTextarea.module.scss';

type Props = {
  maxLength: number;
  minRows: number;
  maxRows: number;
  placeholder: string;
  label?: string;
  className?: string;
};

export function AppTextarea({
  maxLength,
  label,
  maxRows,
  minRows,
  placeholder,
  className,
  ...props
}: Props) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const length = ref.current?.value.length || 0;

  return (
    <div className={classNames(styles.appTextarea, className)}>
      <Textarea
        {...props}
        ref={ref}
        label={label}
        autosize
        placeholder={placeholder}
        maxLength={maxLength}
        minRows={minRows}
        maxRows={maxRows}
      />
      <div className={styles.description}>
        <div>With preserved line break</div>
        <div>{`${length}/${maxLength}`}</div>
      </div>
    </div>
  );
}
