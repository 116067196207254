import { useClipboard } from '@mantine/hooks';
import { AppIconButton } from '../app-icon-button/AppIconButton';
import { IconCopy } from '../../icons';

import styles from './AppClipboardButton.module.scss';
import classNames from 'classnames';

type Props = {
  withLabel?: boolean;
  value: string;
  className?: string;
};

export function AppClipboardButton({ value, withLabel = true, className }: Props) {
  const clipboard = useClipboard({ timeout: 1000 });

  return (
    <AppIconButton
      radius='round'
      variant='outline'
      onClick={() => clipboard.copy(value)}
      icon={IconCopy}
      className={classNames(clipboard.copied ? styles.copied : undefined, className)}
    >
      {withLabel && (clipboard.copied ? 'Text Copied' : 'Copy Text')}
    </AppIconButton>
  );
}
