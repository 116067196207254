import { useImportImdbForm, useUpdateImdbIdMutation } from '../../../modules/collection';

import { TextInput } from '@mantine/core';
import { AppButton, AppFormContainer } from '../../../components';
import styles from './ImdbImport.module.scss';
import { ImdbMoviePreviewDto, useLazySearchTitleQuery } from '../../../modules/film';
import { useEffect, useState } from 'react';

type Props = {
  formId: string;
  onSubmit: () => void;
  symbol: string;
};

export function ImdbImport({ formId, onSubmit, symbol }: Props) {
  const form = useImportImdbForm();
  const [searchTitle, result] = useLazySearchTitleQuery();
  const [updateImdbId, { isLoading }] = useUpdateImdbIdMutation();
  const [filmPreview, setFilmPreview] = useState<ImdbMoviePreviewDto | null>();

  const handleSubmit = form.onSubmit(async ({ url }) => {
    if (!filmPreview) {
      form.setFieldError('url', 'Import film data');
    } else if (!url.includes(filmPreview.id)) {
      form.setFieldError('url', 'Url and imported data do not match');
    } else {
      await updateImdbId({ imdbId: filmPreview.id, symbol }).unwrap();
      onSubmit();
    }
  });

  useEffect(() => {
    if (result) {
      if (result.data) {
        setFilmPreview(result.data);
      }
      if (result.isError) {
        form.setFieldError('url', 'Invalid IMDB url');
      }
    }
  }, [result]);

  function handleCheck() {
    const url = form.values.url;
    form.clearFieldError('url');

    const result = form.validate();

    if (!result.hasErrors) {
      searchTitle({ url });
    }
  }

  return (
    <div className={styles.importImdb}>
      <AppFormContainer
        className={styles.form}
        onSubmit={handleSubmit}
        disabled={isLoading}
        formId={formId}
        step={2}
        title='Import film data from IMBD'
        description='In order to import data paste an IMDB film url into the input below'
      >
        <div className={styles.row}>
          <TextInput
            label='Paste IMDB link'
            placeholder='e.g. “www.imdb.com/title/tt7131622”'
            {...form.getInputProps('url')}
            rightSection={
              <AppButton
                disabled={isLoading}
                onClick={handleCheck}
                className={styles.button}
                variant='white'
              >
                Check
              </AppButton>
            }
          />
        </div>
      </AppFormContainer>

      <div className={styles.previewContainer}>
        <div className={styles.previewLabel}>Film preview</div>

        {filmPreview ? (
          <img className={styles.image} src={filmPreview.image} alt='preview' />
        ) : (
          <div className={styles.imagePlaceholder}>
            <div className={styles.previewPlaceholder}>Paste IMDB link to preview your film</div>
          </div>
        )}
        {filmPreview ? (
          <div className={styles.name}>{filmPreview.title}</div>
        ) : (
          <div className={styles.namePlaceholder} />
        )}
      </div>
    </div>
  );
}
