import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppRoute } from '../../routes';
import { useFindFanTokenProductQuery, usePayTokenMutation } from '../../modules/token';
import { Loading } from '../loading/Loading';
import { AppButton, AppCreatorLabel, AppTokenImage, AppTokenPreview } from '../../components';
import { formatCurrency } from '../../utils';
import styles from './Success.module.scss';
import { Loader, Stepper } from '@mantine/core';
import { ProfileTab } from '../../enums';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../modules/auth';
import { socket } from '../../socket';

export function Success() {
  const user = useAppSelector(selectUser);
  const { symbol = '', optionId } = useParams<{ symbol: string; optionId?: string }>();
  const navigate = useNavigate();
  const { data: token, isError, refetch } = useFindFanTokenProductQuery({ symbol, optionId });
  const [pay, { isLoading }] = usePayTokenMutation();
  const [activeStep, setActiveStep] = useState(1);
  const isComplete = activeStep === 3;

  useEffect(() => {
    socket.on('tokenMinted', ({ symbol: collectionSymbol, userId, optionId: socketOptionId }) => {
      if (
        symbol.toUpperCase() === collectionSymbol.toUpperCase() &&
        userId === user?.id &&
        optionId === socketOptionId
      ) {
        setActiveStep(3);
      }
    });

    return () => {
      socket.off('tokenMinted');
    };
  }, [socket]);

  useEffect(() => {
    if (isError) {
      navigate(AppRoute.NotFound);
    }
  }, [isError]);

  function toToken(userId: string) {
    navigate(`${AppRoute.User}/${userId}`, {
      state: { tab: ProfileTab.NFTs },
    });
  }

  return token ? (
    <div className={styles.success}>
      <div className={styles.tokenInfo}>
        <div className={styles.by}>
          <AppCreatorLabel isSmall {...token.creator} />
        </div>
        <AppTokenImage
          className={styles.tokenImage}
          src={token.image}
          symbol={token.collectionSymbol}
        />
        <div className={styles.collectionName}>{token.collectionName}</div>
        <div className={styles.title}>This token unlocks these benefits:</div>
        <div className={styles.benefits}>
          {token.benefits.map((benefit) => (
            <div className={styles.benefit} key={benefit.id}>
              <div className={styles.name}>{benefit.name}</div>
              {/*<div className={styles.description}>{benefit.description}</div>*/}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.status}>
        <Stepper
          active={activeStep}
          color='gray'
          onStepClick={setActiveStep}
          size='xs'
          orientation='vertical'
          progressIcon={<Loader color='white' size='xs' />}
        >
          <Stepper.Step
            allowStepSelect={false}
            label='Payment'
            description='Your payment completed'
          />
          <Stepper.Step
            allowStepSelect={false}
            label='NFT Creation'
            description='We are minting your NFT to blockchain and sending it to your wallet'
          />
          {activeStep === 3 && (
            <Stepper.Step
              allowStepSelect={false}
              label='Success'
              description='Your NFT is ready in your wallet. You can find it in the profile'
            />
          )}
        </Stepper>

        {isComplete && (
          <AppButton radius='round' variant='white' onClick={() => toToken(user!.id)}>
            View token
          </AppButton>
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
}
