import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './RteImageModal';

export function getRteImageModalContext(
  symbol: string,
  onSuccess: (url: string) => void,
  onReject: () => void,
): OpenContextModal<{
  symbol: string;
  onSuccess: (url: string) => void;
  onReject: () => void;
}> {
  return {
    title: 'Add image',
    innerProps: {
      symbol,
      onSuccess,
      onReject,
    },
  };
}
