import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './TextInsertModal';

export function getTextModalContext(
  onSuccess: (text: string) => void,
  onReject: () => void,
  title: string,
): OpenContextModal<{
  onSuccess: (text: string) => void;
  onReject: () => void;
}> {
  return {
    title,
    innerProps: {
      onSuccess,
      onReject,
    },
  };
}
