import classNames from 'classnames';
import styles from './NavItems.module.scss';
import { useEffect, useState } from 'react';
import { NavItem } from '../index';

type Props = {
  items: NavItem[];
  className?: string;
};

export function NavItems({ items, className }: Props) {
  const [currentSection, setCurrentSection] = useState<string>(items[0].id);

  useEffect(() => {
    ioInit();
  }, []);

  function ioInit() {
    const options = { threshold: 0.5 };
    const selectors: string = items.map(({ id }) => `#${id}`).join(', ');
    const sections = document.querySelectorAll(selectors);

    const observer = new IntersectionObserver(onIntersection, options);

    function onIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    }

    sections.forEach((section) => {
      observer.observe(section);
    });
  }

  return (
    <div className={classNames(styles.navItems, className)}>
      {items.map(({ id, label }) => (
        <a
          key={id}
          href={`#${id}`}
          onClick={() => setCurrentSection(id)}
          className={classNames(styles.navItem, { [styles.active]: currentSection === id })}
        >
          {label}
        </a>
      ))}
    </div>
  );
}
