import { useNavigate } from 'react-router-dom';
import { CollectionTypeCard } from './collection-type-card/CollectionTypeCard';
import { AppRoute } from '../../routes';
import FunImage from '../../images/fun-image.svg';
import FanImage from '../../images/fan-image.svg';
import styles from './CreateCollection.module.scss';

export function CreateCollection() {
  const navigate = useNavigate();

  return (
    <div className={styles.createCollection}>
      <div className={styles.header}>
        <div className={styles.title}>Create a Collection</div>
      </div>
      <div className={styles.options}>
        <CollectionTypeCard
          title='Film Production'
          image={FunImage}
          description='Secure funding from the community at any stage of filmmaking by offering future film rights and selling exclusive digital collectibles with special benefits for each pass holders.'
          onClick={() => navigate(`${AppRoute.CreateCollection}/funding`)}
          buttonText='Get funding for my film'
        />

        <CollectionTypeCard
          title='Film Distribution'
          image={FanImage}
          description='Distribute your movies directly to your audience, bypassing middlemen and excessive commissions. Earn money from your work by offering your fans exclusive bonuses and build the community! '
          onClick={() => navigate(`${AppRoute.CreateCollection}/fan`)}
          buttonText='Distribute my film'
        />
      </div>
    </div>
  );
}
