import styles from './AppTokenImage.module.scss';
import classNames from 'classnames';
import { OptimizedImage } from '../optimized-image/OptimizedImage';

type Props = {
  className?: string;
  symbol?: string;
  src: string;
};

export function AppTokenImage({ className, src, symbol }: Props) {
  return (
    <div className={classNames(styles.tokenImage, className)}>
      <OptimizedImage className={styles.image} src={src} alt={symbol} dimensions={[320, 320]} />
      {symbol && <div className={styles.symbol}>{symbol}</div>}
    </div>
  );
}
