import { useSelector } from "react-redux";
import { selectUser } from "../../modules/auth";
import { ContextModalProps } from "@mantine/modals";

export function DropdownModal({ context, id }: ContextModalProps) {
  const user = useSelector(selectUser);

  function closeModal() {
    context.closeContextModal(id);
  }

  return <div>asdas</div>;
}
