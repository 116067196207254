import noFilms from '../../images/no-films.png';
import noCollectors from '../../images/no-collectors.png';
import noFunding from '../../images/fun-image.svg';
import noFan from '../../images/fan-image.svg';
import classNames from 'classnames';
import { CollectionType } from '../../modules/collection';
import styles from './AppNoItems.module.scss';

type Props = {
  title: string;
  description?: string;
  type: 'user' | 'film' | CollectionType | 'token';
  className?: string;
};

export function AppNoItems({ title, description, type, className }: Props) {
  const typeToImage = {
    user: noCollectors,
    film: noFilms,
    [CollectionType.Funding]: noFilms,
    [CollectionType.Fan]: noFilms,
    token: noFilms,
  };

  return (
    <div className={classNames(styles.appNoItems, className)}>
      <img src={typeToImage[type]} alt='no items image' />
      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
}
