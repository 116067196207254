import styles from './BonusList.module.scss';
import { AppIconButton } from '../../../../components';
import { IconPlus } from '@tabler/icons-react';
import { BonusDto, MAX_BONUSES } from '../../../../modules/collection';
import { BonusPreview } from '../bonus-preview/BonusPreview';

type Props = {
  onBonusCreate: () => void;
  bonuses: BonusDto[];
  onBonusEdit: (id: string) => void;
  onBonusDelete: (id: string) => void;
  onBonusToggle: (id: string) => void;
};

export function BonusList({
  onBonusCreate,
  bonuses,
  onBonusEdit,
  onBonusToggle,
  onBonusDelete,
}: Props) {
  return (
    <div className={styles.bonusList}>
      <div className={styles.header}>
        <div>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Bonuses</div>
            <div className={styles.bonusCount}>{bonuses.length} Bonuses</div>
          </div>
          <div className={styles.description}>
            You can create up to 15 bonuses. Please note that
            <span style={{ color: 'white' }}> Watch Movie</span> is a must bonus and cannot be
            removed. But you can enable or disable the
            <span style={{ color: 'white' }}> Download Movie</span> bonus.
          </div>
        </div>

        <AppIconButton
          disabled={bonuses.length >= MAX_BONUSES}
          variant='white'
          onClick={onBonusCreate}
          icon={IconPlus}
        >
          Create bonus
        </AppIconButton>
      </div>

      <div className={styles.tableHeader}>
        <div className={styles.bonus}>Bonus</div>
        <div className={styles.bonusContent}>Bonus content</div>
        <div className={styles.action}>Action</div>
      </div>
      <div className={styles.bonuses}>
        {bonuses.map((bonus) => (
          <BonusPreview
            onBonusDelete={onBonusDelete}
            onBonusEdit={onBonusEdit}
            onBonusToggle={onBonusToggle}
            bonus={bonus}
            key={bonus.id}
          />
        ))}
      </div>
    </div>
  );
}
