import { Accordion, ActionIcon, Box, Checkbox } from '@mantine/core';
import classNames from 'classnames';
import styles from './AppRoadmap.module.scss';
import { IconX } from '@tabler/icons-react';
import { AppButton } from '../app-button/AppButton';
import React from 'react';
import { Task } from '../../modules/collection';

type Props = {
  tasks: Task[];
  checkClicked?: (subtaskId: string) => void;
  addSubtaskClicked?: (taskId: string) => void;
  deleteSubtask?: (id: string) => void;
  deleteTask?: (id: string) => void;
  className?: string;
};
export function AppRoadmap({
  tasks,
  checkClicked,
  deleteTask,
  deleteSubtask,
  addSubtaskClicked,
  className,
}: Props) {
  return (
    <div className={classNames(styles.appRoadmap, className)}>
      <Accordion
        variant='separated'
        disableChevronRotation
        chevronPosition='left'
        radius='md'
        mx='auto'
      >
        {tasks.map(({ id, subtasks, title }) => {
          const isDone = subtasks.every((s) => s.isDone);

          return (
            <Accordion.Item key={id} value={id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Accordion.Control
                  chevron={
                    isDone ? <Checkbox onChange={() => {}} checked={true} radius='xl' /> : undefined
                  }
                >
                  <div className={classNames(styles.title, { [styles.done]: isDone })}>{title}</div>
                </Accordion.Control>
                {tasks.length > 1 && deleteTask && (
                  <ActionIcon onClick={() => deleteTask(id)} size='lg'>
                    <IconX size={15} />
                  </ActionIcon>
                )}
              </Box>
              <Accordion.Panel pl={4}>
                {subtasks.map((subtask) => (
                  <div className={styles.subtask} key={subtask.id}>
                    <Checkbox
                      onChange={() => checkClicked?.(subtask.id)}
                      radius='xl'
                      checked={subtask.isDone}
                    />
                    <div className={styles.content}>
                      <div className={styles.subtitle}>{subtask.title}</div>
                      <div className={styles.description}>{subtask.description}</div>
                    </div>
                    {subtasks.length > 1 && deleteSubtask && (
                      <IconX size={15} onClick={() => deleteSubtask(subtask.id)} />
                    )}
                  </div>
                ))}

                {addSubtaskClicked && (
                  <AppButton
                    onClick={() => addSubtaskClicked(id)}
                    className={styles.addSubtaskButton}
                    variant='outline'
                  >
                    Add new
                  </AppButton>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}
