import { showNotification } from '@mantine/notifications';
import { findAllByKey } from '../utils';
import React, { ReactNode } from 'react';
import { IconCheck, IconError, IconInfo, IconSuccess, IconWarning } from '../icons';

type Type = 'warning' | 'info' | 'success' | 'error';

const colorByType: Record<Type, string> = {
  warning: '#F79009',
  info: '#DDFB6E',
  success: '#DDFB6E',
  error: '#F15050',
};

function getStyles(type: Type, hasMessage: boolean) {
  return {
    root: {
      alignItems: hasMessage ? 'flex-start' : 'center',

      '&::before': {
        backgroundColor: colorByType[type],
      },
    },
  };
}

export function showErrorBody(error: any) {
  const [message] = findAllByKey(error, 'message').concat(findAllByKey(error, 'reason'));

  // showErrorMessage(message);
}

export function showSuccess(title: string, message?: ReactNode) {
  showNotification({
    title,
    message,
    icon: <IconSuccess />,
    styles: getStyles('success', !!message),
  });
}

export function showError(title?: string, message?: ReactNode) {
  showNotification({
    title: title || 'Oops... Something went wrong',
    message: message,
    icon: <IconError />,
    autoClose: false,
    styles: getStyles('error', !!message),
  });
}

export function showInfo(title: string, message?: ReactNode) {
  showNotification({
    title,
    message,
    icon: <IconInfo />,
    styles: getStyles('info', !!message),
  });
}

export function showWarning(title: string, message?: ReactNode) {
  showNotification({
    title,
    message,
    icon: <IconWarning />,
    styles: getStyles('warning', !!message),
  });
}
