import React from 'react';
import { Title, Text, Button, Container, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './useStyles';
import { AppRoute } from '../../routes';
import { AppButton } from '../../components';

export function NotFound() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>You have found a secret place.</Title>
      <Text color='dimmed' size='lg' align='center' className={classes.description}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
        been moved to another URL.
      </Text>
      <Group position='center'>
        <AppButton className={classes.button} radius='round' onClick={() => navigate(AppRoute.Main)}>
          Take me back to home page
        </AppButton>
      </Group>
    </Container>
  );
}
