import { useGoalForm, useUpdateFundingCollectionGoalMutation } from '../../modules/collection';

import { useEffect, useState } from 'react';
import { AppFormContainer } from '../index';
import { NumberInput } from '@mantine/core';
import styles from './GoalForm.module.scss';
import { formatCurrencyInput, parseCurrencyInput } from '../../utils';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  goal?: number | null;
  percentage?: number | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function GoalForm({ id, symbol, onSubmit, goal, percentage, onChange, step }: Props) {
  const [updateGoal, { isLoading }] = useUpdateFundingCollectionGoalMutation();
  const form = useGoalForm();

  const handleSubmit = form.onSubmit(async ({ goal, percentage }) => {
    await updateGoal({ symbol, goal, percentage }).unwrap();
    onSubmit?.();
  });

  useEffect(() => {
    onChange?.(form.values.goal !== goal || form.values.percentage !== percentage);
  }, [form.values]);

  useEffect(() => {
    form.setValues({ goal: goal || 0 });
    form.setValues({ percentage: percentage || 0 });
  }, [goal]);

  return (
    <div className={styles.goalForm}>
      <AppFormContainer
        description="Specify the funding target for this production phase and decide the percentage of film’s rights you'll allocate to the community at this juncture."
        title='Money & Rights'
        onSubmit={handleSubmit}
        formId={id}
        className={styles.form}
        step={step}
        disabled={isLoading}
      >
        <NumberInput
          label='Funding Goal, $'
          placeholder='Enter funding goal'
          precision={10}
          hideControls
          removeTrailingZeros
          parser={parseCurrencyInput}
          formatter={formatCurrencyInput}
          {...form.getInputProps('goal')}
        />
        <div style={{ marginBottom: 10 }} className={styles.fieldDescription}>
          Establish a realistic fundraising target for your campaign.
        </div>

        <NumberInput
          label='Rights to be allocated, %'
          placeholder='Enter funding goal'
          precision={0}
          hideControls
          removeTrailingZeros
          {...form.getInputProps('percentage')}
        />
        <div className={styles.fieldDescription}>
          Percentage of film rights to be allocated during this stage.
        </div>
      </AppFormContainer>
    </div>
  );
}
