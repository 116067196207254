import { FormEvent, ReactNode } from 'react';
import styles from './AppFormContainer.module.scss';
import classNames from 'classnames';

type Props = {
  formId: string;
  onSubmit: (event?: FormEvent<HTMLFormElement> | undefined) => void;
  step?: number;
  children: ReactNode;
  className?: string;
  title?: string;
  description?: string;
  disabled?: boolean;
};

export function AppFormContainer({
  formId,
  onSubmit,
  children,
  step,
  className,
  title,
  description,
  disabled,
}: Props) {
  return (
    <div className={classNames(styles.appFormContainer, className)}>
      {step && <div className={styles.step}>Step {step}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}

      <form className={styles.form} onSubmit={onSubmit} id={formId}>
        <fieldset disabled={disabled}>{children}</fieldset>
      </form>
    </div>
  );
}
