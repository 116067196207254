import { Carousel, Embla } from '@mantine/carousel';

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { VideoDto } from '../../types';
import { AppPlayerWithPreview } from '../index';
import { resizeImage } from '../../utils';
import isEmpty from 'is-empty-typed';
import { IconPlay3 } from '../../icons';
import { UnstyledButton } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useAppModals } from '../../modals';
import styles from './MediaGallery.module.scss';
import { OptimizedImage } from '../optimized-image/OptimizedImage';

type Props = {
  videos: VideoDto[];
  images: string[];
  className?: string;
};

type SelectedItem = {
  id: string;
  type: 'video' | 'image';
};

export function MediaGallery({ videos, images, className }: Props) {
  const modals = useAppModals();
  const [embla, setEmbla] = useState<Embla | null>(null);
  const initialSelectedItem: SelectedItem = !isEmpty(videos)
    ? { id: videos[0].id, type: 'video' }
    : { id: images[0], type: 'image' };
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(initialSelectedItem);
  const [isNextShown, setNextShown] = useState(false);
  const [isPrevShown, setPrevShown] = useState(false);
  const videoThumbnails = [];
  const imageThumbnails = [];

  useEffect(() => {
    setNextShown(!!getNext());
    setPrevShown(!!getPrev());
  }, [selectedItem]);

  function getCurrentIndex() {
    return items.findIndex((item) => item.item.id === selectedItem.id);
  }

  function getNext() {
    const currentIndex = getCurrentIndex();
    return items[currentIndex + 1];
  }

  function getPrev() {
    const currentIndex = getCurrentIndex();
    return items[currentIndex - 1];
  }

  function handlePrevClick() {
    const currentIndex = getCurrentIndex();
    const prevItem = getPrev();

    if (prevItem) {
      const visibleSlides = embla!.slidesInView();

      if (!visibleSlides.includes(currentIndex - 1)) {
        embla!.scrollTo(currentIndex - 1);
      }
      setSelectedItem(prevItem.item);
    }
  }

  function handleNextClick() {
    const currentIndex = getCurrentIndex();
    const nextItem = getNext();

    if (nextItem) {
      const visibleSlides = embla!.slidesInView();

      if (!visibleSlides.includes(currentIndex + 1)) {
        embla!.scrollTo(currentIndex + 1);
      }
      setSelectedItem(nextItem.item);
    }
  }

  function handleSlideClick(item: SelectedItem) {
    setSelectedItem(item);
  }

  const videoItems = videos.map((video) => {
    const item: SelectedItem = { id: video.id, type: 'video' };

    return {
      item,
      component: (
        <Carousel.Slide
          className={classNames(styles.slide, {
            [styles.selected]: video.id === selectedItem.id,
          })}
          key={video.id}
          onClick={() => handleSlideClick(item)}
        >
          <img className={styles.thumbnail} src={video.thumbnail} alt='Video thumbnail' />
          <IconPlay3 className={styles.playIcon} />
        </Carousel.Slide>
      ),
    };
  });

  const imageItems = images.map((image) => {
    const thumbnail = resizeImage(image, 160, 90);
    const item: SelectedItem = { id: image, type: 'image' };

    return {
      item,
      component: (
        <Carousel.Slide
          className={classNames(styles.slide, {
            [styles.selected]: image === selectedItem.id,
          })}
          key={image}
        >
          <img
            className={styles.thumbnail}
            src={thumbnail}
            alt='Carousel Image'
            onClick={() => handleSlideClick(item)}
          />
        </Carousel.Slide>
      ),
    };
  });

  const items = [...videoItems, ...imageItems];
  const slides = items.map((item) => item.component);

  return (
    <div className={classNames(styles.mediaGallery, className)}>
      {selectedItem && (
        <div className={styles.viewContainer}>
          <div className={styles.mediaContainer}>
            {selectedItem.type === 'video' ? (
              <AppPlayerWithPreview video={videos.find((item) => item.id === selectedItem.id)!} />
            ) : (
              <div className={styles.imageContainer}>
                <OptimizedImage
                  className={styles.image}
                  src={selectedItem.id}
                  alt='Gallery Image'
                  dimensions={[1280, 720]}
                  onClick={() => modals.openImageModal({ image: selectedItem.id })}
                />
              </div>
            )}
          </div>

          {isPrevShown && (
            <UnstyledButton
              className={classNames(styles.control, styles.left)}
              onClick={handlePrevClick}
            >
              <IconChevronLeft />
            </UnstyledButton>
          )}
          {isNextShown && (
            <UnstyledButton
              className={classNames(styles.control, styles.right)}
              onClick={handleNextClick}
            >
              <IconChevronRight />
            </UnstyledButton>
          )}
        </div>
      )}

      <Carousel
        className={styles.carousel}
        slideSize='auto'
        slideGap={20}
        getEmblaApi={setEmbla}
        withControls={false}
        align='start'
      >
        {slides}
      </Carousel>
    </div>
  );
}
