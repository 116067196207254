import {
  baseRoadmap,
  OptionDto,
  Task,
  UpdateOptionDto,
  useGoalForm,
  useUpdateFundingCollectionGoalMutation,
  useUpdateFundingCollectionRoadmapMutation,
} from '../../modules/collection';

import React, { useEffect, useState } from 'react';
import { AppButton, AppFormContainer } from '../index';
import {
  Accordion,
  AccordionControlProps,
  ActionIcon,
  Box,
  Checkbox,
  NumberInput,
} from '@mantine/core';
import styles from './RoadmapForm.module.scss';
import { IconDots, IconX } from '@tabler/icons-react';
import { useListState } from '@mantine/hooks';
import { IconInfo, IconSuccess } from '../../icons';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useAppModals } from '../../modals';
import { DateInput } from '@mantine/dates';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  tasks?: Task[];
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function RoadmapForm({ id, symbol, onSubmit, tasks = [], onChange, step }: Props) {
  const [updatedTasks, taskHandlers] = useListState<Task>();
  const modals = useAppModals();

  const [updateRoadmap, { isLoading }] = useUpdateFundingCollectionRoadmapMutation();
  const hasUpdatedTasks = JSON.stringify(tasks) !== JSON.stringify(updatedTasks);

  function handleSubmit(e: any) {
    e.preventDefault();

    updateRoadmap({ tasks: updatedTasks, symbol })
      .unwrap()
      .then(() => {
        onSubmit?.();
      });
  }

  useEffect(() => {
    taskHandlers.setState(
      tasks.length ? tasks.map((task) => ({ ...task, subtasks: [...task.subtasks] })) : baseRoadmap,
    );
  }, []);

  useEffect(() => {
    onChange?.(hasUpdatedTasks);
  }, [updatedTasks]);

  function addTaskClicked() {
    modals.openTextModal({
      onSuccess: (text) => {
        const taskName = text;

        modals.openAddSubtask({
          onSuccess: (subtask) => {
            taskHandlers.append({
              id: uuid(),
              title: text,
              date: new Date().toISOString(),
              subtasks: [{ ...subtask, id: uuid(), isDone: false }],
            });
          },
        });
      },
      title: 'Enter Task Title',
    });
  }

  function addSubtaskClicked(taskId: string) {
    modals.openAddSubtask({
      onSuccess: (subtask) => {
        taskHandlers.applyWhere(
          (item) => item.id === taskId,
          (item) => {
            item.subtasks.push({ ...subtask, id: uuid(), isDone: false });

            return item;
          },
        );
      },
    });
  }

  function deleteTask(id: string) {
    taskHandlers.filter((task) => task.id !== id);
  }

  function setDate(id: string, value: Date | null) {
    taskHandlers.applyWhere(
      (item) => item.id === id,
      (item) => {
        if (value) {
          item.date = value.toISOString();
        }

        return item;
      },
    );
  }

  function checkClicked(subtaskId: string) {
    taskHandlers.applyWhere(
      (item) => item.subtasks.some((subtask) => subtask.id === subtaskId),
      (item) => {
        const subtaskIndex = item.subtasks.findIndex((subtask) => subtask.id === subtaskId);

        if (subtaskIndex >= 0) {
          item.subtasks[subtaskIndex] = {
            ...item.subtasks[subtaskIndex],
            isDone: !item.subtasks[subtaskIndex].isDone,
          };
        }

        return item;
      },
    );
  }

  function deleteSubtask(id: string) {
    taskHandlers.applyWhere(
      (item) => item.subtasks.some((subtask) => subtask.id === id),
      (item) => {
        const subtaskIndex = item.subtasks.findIndex((subtask) => subtask.id === id);

        if (subtaskIndex >= 0) {
          item.subtasks.splice(subtaskIndex, 1);
        }

        return item;
      },
    );
  }

  return (
    <div className={styles.roadMap}>
      <AppFormContainer
        description='This list outlines the tasks the filmmaker will undertake during this production phase, utilizing the gathered funds. You have the unique opportunity to both oversee and actively engage in the entire process.'
        title='Roadmap'
        onSubmit={handleSubmit}
        formId={id}
        className={styles.form}
        step={step}
      >
        <Accordion
          variant='separated'
          disableChevronRotation
          chevronPosition='left'
          radius='md'
          mx='auto'
        >
          {updatedTasks.map(({ id, subtasks, title, date }) => {
            const isDone = subtasks.every((s) => s.isDone);

            return (
              <Accordion.Item key={id} value={id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Accordion.Control
                    chevron={
                      isDone ? (
                        <Checkbox onChange={() => {}} checked={true} radius='xl' />
                      ) : undefined
                    }
                  >
                    <div className={classNames(styles.title, { [styles.done]: isDone })}>
                      {title}
                    </div>
                  </Accordion.Control>
                  <DateInput
                    valueFormat='DD/MM/YY'
                    value={new Date(date)}
                    onChange={(value) => setDate(id, value)}
                  />
                  {updatedTasks.length > 1 && (
                    <ActionIcon onClick={() => deleteTask(id)} size='lg'>
                      <IconX size={15} />
                    </ActionIcon>
                  )}
                </Box>
                <Accordion.Panel pl={4}>
                  {subtasks.map((subtask) => (
                    <div className={styles.subtask} key={subtask.id}>
                      <Checkbox
                        onChange={() => checkClicked(subtask.id)}
                        radius='xl'
                        checked={subtask.isDone}
                      />
                      <div className={styles.content}>
                        <div className={styles.subtitle}>{subtask.title}</div>
                        <div className={styles.description}>{subtask.description}</div>
                      </div>
                      {subtasks.length > 1 && (
                        <IconX size={15} onClick={() => deleteSubtask(subtask.id)} />
                      )}
                    </div>
                  ))}

                  <AppButton
                    onClick={() => addSubtaskClicked(id)}
                    className={styles.addSubtaskButton}
                    variant='outline'
                  >
                    Add new
                  </AppButton>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>

        <AppButton onClick={addTaskClicked} className={styles.addTaskButton} variant='white'>
          Add new
        </AppButton>
      </AppFormContainer>
    </div>
  );
}
