import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stepper, Textarea, TextInput } from "@mantine/core";
import { AppButton } from "../../components";
import {
  ProfileDto,
  useBecomeFilmmakerMutation,
  useCreatorNameForm,
  useCreatorBioForm,
  useCreatorSocialForm,
  useFindProfileQuery,
} from "../../modules/user";

import { Form } from "../form/Form";
import styles from "./BecomeCreator.module.scss";

export function BecomeCreator() {
  const navigate = useNavigate();
  const [becomeFilmmaker, { isLoading }] = useBecomeFilmmakerMutation();
  const { data: profile } = useFindProfileQuery();
  const creatorNameForm = useCreatorNameForm();
  const bioForm = useCreatorBioForm();
  const socialForm = useCreatorSocialForm();
  const forms = [creatorNameForm, bioForm, socialForm];
  const [activeStep, setActiveStep] = useState(0);

  function populateForm(profile: ProfileDto) {
    const { username, email, fullName, bio, imdb, website, twitter } = profile;

    creatorNameForm.setValues({
      username: username || "",
      fullName: fullName || "",
      email: email || "",
    });

    bioForm.setFieldValue("bio", bio || "");

    socialForm.setValues({
      website: website || "",
      imdb: imdb || "",
      twitter: twitter || "",
    });
  }

  useEffect(() => {
    if (profile) {
      populateForm(profile);
    }
  }, [profile]);

  function goBack() {
    navigate(-1);
  }

  function nextStep() {
    setActiveStep((current) => (current < 2 ? current + 1 : current));
    activeStep < 2 ? setActiveStep(activeStep + 1) : validateAllForms();
  }
  function prevStep() {
    activeStep > 0 ? setActiveStep(activeStep - 1) : goBack();
  }

  function showCompleted() {
    setActiveStep(3);
  }

  function validateStep() {
    const { hasErrors } = forms[activeStep].validate();

    if (!hasErrors) nextStep();
  }

  function validateAllForms() {
    const isResultValid = forms.every((form, index) => {
      const { hasErrors } = form.validate();

      if (hasErrors) {
        setActiveStep(index);
        return false;
      } else {
        return true;
      }
    });

    if (isResultValid) {
      becomeFilmmaker({
        ...creatorNameForm.values,
        ...bioForm.values,
        ...socialForm.values,
      })
        .unwrap()
        .then(showCompleted);
    }
  }

  return (
    <Form title="Create a FilmMaker account">
      <Stepper
        size="xs"
        active={activeStep}
        onStepClick={activeStep !== 3 ? setActiveStep : undefined}
        breakpoint="sm"
      >
        <Stepper.Step>
          <form className={styles.stepContent}>
            <div className={styles.description}>
              This account type gives you an opportunity to sell NFTs. If you
              are an filmmaker and want to create your NFTs.
            </div>
            <TextInput
              className={styles.input}
              label="Username"
              placeholder="john123"
              {...creatorNameForm.getInputProps("username")}
            />
            <TextInput
              className={styles.input}
              label="Email"
              placeholder="example@mail.com"
              {...creatorNameForm.getInputProps("email")}
            />
            <TextInput
              className={styles.input}
              label="Full name"
              placeholder="John Doe"
              {...creatorNameForm.getInputProps("fullName")}
            />
          </form>
        </Stepper.Step>

        <Stepper.Step>
          <form className={styles.stepContent}>
            <div className={styles.description}>
              Fill out some information about yourself and what you do and fill
              your social media links
            </div>
            <Textarea
              className={styles.input}
              label="Description"
              autosize
              minRows={5}
              placeholder="Description"
              {...bioForm.getInputProps("bio")}
            />
          </form>
        </Stepper.Step>

        <Stepper.Step>
          <form className={styles.stepContent}>
            <div className={styles.description}>
              Fill out some information about yourself and what you do and fill
              your social media links
            </div>
            <TextInput
              className={styles.input}
              label="Website"
              placeholder="https://"
              {...socialForm.getInputProps("website")}
            />
            <TextInput
              className={styles.input}
              label="IMDB"
              placeholder="https://"
              {...socialForm.getInputProps("imdb")}
            />
            <TextInput
              className={styles.input}
              label="Twitter"
              placeholder="https://"
              {...socialForm.getInputProps("twitter")}
            />
          </form>
        </Stepper.Step>

        <Stepper.Completed>
          <div className={styles.description}>
            You submitted your application successfully, it will be confirmed
            shortly
          </div>
        </Stepper.Completed>
      </Stepper>

      <div className={styles.buttons}>
        {activeStep === 3 ? (
          <>
            <AppButton className={styles.button} onClick={goBack}>
              Done
            </AppButton>
          </>
        ) : (
          <>
            <AppButton
              className={styles.button}
              
              onClick={prevStep}
            >
              Back
            </AppButton>
            <AppButton
              className={styles.button}
              isLoading={isLoading}
              onClick={validateStep}
            >
              {activeStep === 2 ? "Finish" : "Next"}
            </AppButton>
          </>
        )}
      </div>
    </Form>
  );
}
