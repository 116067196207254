import styles from './NavSection.module.scss';
import { ReactNode } from 'react';
import isEmpty from 'is-empty-typed';

type Props = {
  id: string;
  buttons?: ReactNode[];
  name: string;
  description?: string;
  children: ReactNode;
};

export function NavSection({ id, name, description, buttons, children }: Props) {
  return (
    <div id={id} className={styles.section}>
      <div className={styles.sectionName}>
        <div>{name}</div>
        {!isEmpty(buttons) && (
          <div className={styles.buttons}>
            {buttons.map((button, index) => (
              <div key={index}>{button}</div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.sectionDescription}>{description}</div>

      {children}
    </div>
  );
}
