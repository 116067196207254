import { AppButton, AppTabsVertical, ProfileForm } from '../../components';
import { ProfileFormTab } from '../../modules/collection';
import { Loading } from '../loading/Loading';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { selectIsLoading } from '../../api';
import { UpdateProfileForm, useFindProfileFormQuery } from '../../modules/user';
import styles from './ProfileEdit.module.scss';
import _ from 'lodash';
import { AppTab } from '../../types';

export function ProfileEdit() {
  const navigate = useNavigate();
  const { data: profileForm, isLoading, refetch } = useFindProfileFormQuery();
  const areButtonDisabled = useAppSelector(selectIsLoading);
  const [currentTab, setCurrentTab] = useState<string | null>(() => ProfileFormTab.Profile);
  const formId = currentTab || undefined;
  const [hasFormChanges, setHasFormChanges] = useState<Record<string, boolean>>({
    [ProfileFormTab.Profile]: false,
    [ProfileFormTab.Account]: false,
    [ProfileFormTab.Wallet]: false,
  });

  const tabs: AppTab[] = [
    {
      tabKey: ProfileFormTab.Profile,
      tabLabel: 'Profile Settings',
      tabContent: (
        <ProfileForm
          profile={profileForm}
          id={ProfileFormTab.Profile}
          onChange={handleProfileChange}
          onSubmit={refetch}
        />
      ),
    },
    {
      tabKey: ProfileFormTab.Account,
      tabLabel: 'Account Settings',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: ProfileFormTab.Wallet,
      tabLabel: 'Wallets',
      tabContent: <></>,
      isDisabled: true,
    },
  ];

  function handleProfileChange(form: UpdateProfileForm) {
    const mappedOldData = {
      ...profileForm,
      avatarId: profileForm?.avatar ? profileForm.avatar.id : null,
      backgroundId: profileForm?.background ? profileForm.background.id : null,
    };
    delete mappedOldData.avatar;
    delete mappedOldData.background;
    delete mappedOldData.id;
    delete mappedOldData.address;

    const mappedNewData = {
      ...form,
      avatarId: form.avatarId || null,
      backgroundId: form.backgroundId || null,
    };

    setHasFormChanges((prevState) => ({
      ...prevState,
      [ProfileFormTab.Profile]: !_.isEqual(mappedNewData, mappedOldData),
    }));
  }

  function goBack(): void {
    navigate(-1);
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.profileEdit}>
      <div className={styles.header}>
        <div className={styles.title}>Settings</div>
        <div className={styles.buttons}>
          <AppButton
            className={styles.button}
            onClick={goBack}
            variant='outline'
            disabled={areButtonDisabled}
          >
            Cancel
          </AppButton>
          <AppButton
            className={styles.button}
            disabled={areButtonDisabled || (!!formId && !hasFormChanges[formId])}
            form={formId}
            type='submit'
            variant='primary'
          >
            Save
          </AppButton>
        </div>
      </div>

      <AppTabsVertical activeTab={currentTab} onTabChange={setCurrentTab} tabs={tabs} />
    </div>
  );
}
